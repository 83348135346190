import {
  Box,
  Flex,
  CircularProgress,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  useDisclosure,
  Checkbox,
  Badge,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { format, set } from "date-fns";
import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Paginator } from "primereact/paginator";
import { FiTrash2 } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { Form, Link, json, useNavigate, useParams } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  EDIT_MODULE,
  GET_LIST_MODULE,
  GET_MODULE_DETAILS,
  DELETE_MODULE,
} from "../../api/api";

export function ModuleList() {
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const { initialRef: initialRefAdd, finalRef: finalRefAdd } = useRef(null);
  const { initialRef: initialRefDelete, finalRef: finalRefDelete } =
    useRef(null);
  const [ModuleData, setModuleData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [loadForm, setloadForm] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  useEffect(() => {
    if (!loadForm) {
      const token = localStorage.getItem("token");
      let request = `page=${currentPage}`;
      if (token) {
        const fetchData = async () => {
          const { isSuccess, res } = await GET_LIST_MODULE(token, request);
          if (isSuccess) {
            const response = await res.json();
            setModuleData(response.data.listData);
            setTotalRecords(response.data.totalRecords);
            setTotalPage(response.data.totalPage);
            setloadForm(true);
          }
        };
        fetchData();
      }
    }
  }, [loadForm]);

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setloadForm(false);
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <div>
        <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
          <Heading>Module</Heading>
          <Flex>
            <Button
              onClick={onOpenDelete}
              size="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight="21vh"
              color={"white"}
              bgColor={"red.400"}
              _hover={{ bgColor: "red.500" }}
              isDisabled={countChecked() === 0}
            >
              <Icon fontSize="20px" as={FiTrash2} marginRight={"10px"} /> Delete
            </Button>
            {loadForm ? (
              <div>
                {/* <AddNewProgram isOpenAdd={isOpenAdd} onOpenAdd={onOpenAdd} onCloseAdd={onCloseAdd} initialRefAdd={initialRefAdd} finalRefAdd={finalRefAdd} setloadForm={setloadForm} />
                                <DeleteTrainingProgram isOpenDelete={isOpenDelete} onOpenDelete={onOpenDelete} onCloseDelete={onCloseDelete} initialRefDelete={initialRefDelete} finalRefDelete={finalRefDelete} data={checkedItems} setloadForm={setloadForm} setCheckedItems={setCheckedItems}/> */}
                <DeleteModule
                  isOpenDelete={isOpenDelete}
                  onOpenDelete={onOpenDelete}
                  onCloseDelete={onCloseDelete}
                  initialRefDelete={initialRefDelete}
                  finalRefDelete={finalRefDelete}
                  data={checkedItems}
                  setloadForm={setloadForm}
                  setCheckedItems={setCheckedItems}
                />
              </div>
            ) : null}
            {/* {localStorage.role === "SUAdmin" ? (
              <div>
                <Button
                  onClick={onOpenAdd}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="10vh"
                >
                  <Icon fontSize="20px" as={MdAdd} /> Add new module
                </Button>
              </div>
            ) : null} */}
          </Flex>
        </Flex>
        {loadForm ? (
          <Box>
            <Flex marginTop={"5vh"}>
              <TableContainer minWidth={"90%"}>
                <Table variant="simple" className="Table">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>Name</Th>
                      <Th>Code</Th>
                      <Th>Created by</Th>
                      <Th>Created at</Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {ModuleData &&
                      ModuleData.map((item, index) => (
                        <Tr key={item?.id}>
                          <Td>
                            <Checkbox
                              value={item?.id}
                              isChecked={checkedItems[item?.id]}
                              onChange={handleCheck(item?.id)}
                            >
                              {/* Checkbox label */}
                            </Checkbox>
                          </Td>
                          <Td>
                            <Link to={`/module/id/${item?.id}`}>
                              <Text
                                color={"rgb(88, 168, 168)"}
                                _hover={{
                                  color: "rgb(88, 135, 168)",
                                }}
                              >
                                {item?.name}
                              </Text>
                            </Link>
                          </Td>
                          <Td>{item?.code}</Td>
                          <Td>
                            <Link to={`/user/id/${item?.createdBy?.id}`}>
                              <Text
                                color={"rgb(88, 168, 168)"}
                                _hover={{
                                  color: "rgb(88, 135, 168)",
                                }}
                              >
                                {item?.createdBy?.name}
                              </Text>
                            </Link>
                          </Td>
                          <Td>
                            {format(new Date(item?.createdAt), "dd/MM/yyyy")}
                          </Td>
                          <Td>
                            {item?.status === "Active" ? (
                              <Badge variant="solid" colorScheme="green">
                                {item?.status}
                              </Badge>
                            ) : (
                              <Badge variant="solid" colorScheme="red">
                                {item?.status}
                              </Badge>
                            )}
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
            <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
              <Box></Box>
              <Flex marginTop={"2vh"}>
                <Paginator
                  first={currentPage}
                  rows={10}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  template={{ layout: "PrevPageLink PageLinks NextPageLink" }}
                  className="p-mt-4"
                />
              </Flex>
              <Flex>
                <Box
                  marginTop={"2vh"}
                  marginRight={"15vh"}
                  bg="rgb(225, 232, 237)"
                  w="100px"
                  h={"50px"}
                  display={"flex"}
                  borderRadius={20}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text>
                    {currentPage} of {totalPage}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon as={LoadingIcon} w={100} h={100} />
          </Box>
        )}
      </div>
      {/* </div> */}
    </Box>
  );
}

export function EditModule({
  isOpenEdit,
  onOpenEdit,
  onCloseEdit,
  initialRefEdit,
  finalRefEdit,
  data,
  setloadForm,
}) {
  const toast = useToast();
  const token = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: data.name,
      code: data.code,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Code is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const { isSuccess, res } = await EDIT_MODULE(token, data.id, values);
        if (isSuccess) {
          toast({
            title: "Edit module successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseEdit();
          setloadForm(false);
        } else {
          const response = await res.json();
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  return (
    <Modal
      initialFocusRef={initialRefEdit}
      finalFocusRef={finalRefEdit}
      isOpen={isOpenEdit}
      onClose={onCloseEdit}
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent>
          <ModalHeader>Edit Module</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  type="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <Text fontSize="sm" color="red">
                    {formik.errors.name}
                  </Text>
                ) : null}
              </FormControl>
              <FormControl>
                <FormLabel>Code</FormLabel>
                <Input
                  id="code"
                  name="code"
                  type="code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                />
                {formik.touched.code && formik.errors.code ? (
                  <Text fontSize="sm" color="red">
                    {formik.errors.code}
                  </Text>
                ) : null}
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseEdit} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}

export function ModuleDetail() {
  const [loadForm, setloadForm] = useState(false);
  const [dataModule, setDataModule] = useState({});
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { initialRef: initialRefEdit, finalRef: finalRefEdit } = useRef(null);
  const token = localStorage.getItem("token");
  const id = useParams().id;
  useEffect(() => {
    if (loadForm === false) {
      const fetchData = async () => {
        try {
          const [moduleResponse] = await Promise.all([
            GET_MODULE_DETAILS(token, id),
          ]);

          const moduleData = await moduleResponse.res.json();
          if (moduleResponse.isSuccess) {
            setDataModule(moduleData.data);
            setloadForm(true);
          }
        } catch (error) {
          // Xử lý lỗi ở đây
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [loadForm]);

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <Flex marginBottom={"3vh"} color={"rgb(36, 112, 166)"}>
        <Link to={`/module`}>{"<<"} Go back</Link>
      </Flex>
      <div>
        <Box>
          {/* <EditProfile isOpenEdit={isOpenEdit} onOpenEdit={onOpenEdit} onCloseEdit={onCloseEdit} initialRefEdit={initialRefEdit} finalRefEdit={finalRefEdit} data={data} /> */}
          {/* <ChangePassword isOpenChangePassword={isOpenChangePassword} onOpenChangePassword={onOpenChangePassword} onCloseChangePassword={onCloseChangePassword} initialRefChangePassword={initialRefChangePassword} finalRefChangePassword={finalRefChangePassword} /> */}
          <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
            <Heading>Module Detail</Heading>
            <Flex>
              {localStorage.role === "SUAdmin" ||
              localStorage.role === "Admin" ? (
                <div>
                  {loadForm ? (
                    <EditModule
                      isOpenEdit={isOpenEdit}
                      onOpenEdit={onOpenEdit}
                      onCloseEdit={onCloseEdit}
                      initialRef={initialRefEdit}
                      finalRef={finalRefEdit}
                      data={dataModule}
                      setloadForm={setloadForm}
                    />
                  ) : null}
                  <Button
                    onClick={onOpenEdit}
                    size="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="1vh"
                    isDisabled={!loadForm}
                  >
                    <Icon fontSize="20px" as={FaRegEdit} />
                  </Button>
                </div>
              ) : null}
              {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                  Change password
                              </Button> */}
            </Flex>
          </Flex>
          {loadForm ? (
            <Flex flexWrap="wrap">
              <Stack className="col-6" marginTop="10" spacing={4}>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Name:</b>
                  </Text>
                  <Text as="span">{dataModule.name}</Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Code:</b>
                  </Text>
                  <Text as="span">{dataModule.code}</Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Created At:</b>
                  </Text>
                  <Text as="span">
                    {format(new Date(dataModule.createdAt), "dd/MM/yyyy")}
                  </Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Created by:</b>
                  </Text>
                  <Link to={`/user/id/${dataModule.createdBy.id}`}>
                    <Text as="span">{dataModule.createdBy.name}</Text>
                  </Link>
                </Flex>
              </Stack>
              <Stack className="col-6" marginTop="10" spacing={4}>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Updated by:</b>
                  </Text>
                  {dataModule.updatedBy === null ? (
                    <Text as="span">N/a</Text>
                  ) : (
                    <Link to={`/user/id/${dataModule.updatedBy.id}`}>
                      <Text as="span">{dataModule.updatedBy.name}</Text>
                    </Link>
                  )}
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Updated at:</b>
                  </Text>
                  {dataModule.updatedAt === null ? (
                    <Text as="span">N/a</Text>
                  ) : (
                    <Text as="span">
                      {format(new Date(dataModule.updatedAt), "dd/MM/yyyy")}
                    </Text>
                  )}
                </Flex>
              </Stack>
            </Flex>
          ) : (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              display="flex"
              marginTop={"3vh"}
            >
              <Icon as={LoadingIcon} w={50} h={50} />
            </Flex>
          )}
        </Box>
      </div>
    </Box>
  );
}

function DeleteModule({
  isOpenDelete,
  onOpenDelete,
  onCloseDelete,
  initialRefDelete,
  finalRefDelete,
  data,
  setloadForm,
  setCheckedItems,
}) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const jsonData = [];
  for (const key in data) {
    if (data[key]) {
      const id = key.split(":")[0].trim();
      jsonData.push(id);
    }
  }

  const handleDelete = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await DELETE_MODULE(token, jsonData);
        if (response.isSuccess) {
          onCloseDelete();
          toast({
            title: `Delete ${jsonData.length} Module(s) successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCheckedItems({});
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRefDelete}
      finalFocusRef={finalRefDelete}
      isOpen={isOpenDelete}
      onClose={onCloseDelete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Modules</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Are you sure to delete these Modules?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={handleDelete}
            isDisabled={isSubmitting}
          >
            {isSubmitting ? (
              <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
            ) : (
              "Delete"
            )}
          </Button>
          <Button onClick={onCloseDelete} isDisabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
