import {
    Box,
    Flex,
    Container,
    Stack,
    Heading,
    Text,
    Image,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    Select,
    Card,
    CardBody,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    useToast,
    useDisclosure,
    Center,
    HStack,
} from "@chakra-ui/react";

import pattern from 'patternomaly';
import { useEffect, useState, useRef } from "react";
import { format } from 'date-fns';
import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaRegEdit  } from 'react-icons/fa';
import { ReactComponent as LoadingIcon } from '../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, VStack } from '@chakra-ui/react';
import "../../css/Background.css";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { GET_USER_INFO, CHANGE_PASSWORD, GET_ACCOUNT_DETAIL, UPDATE_USER_PROFILE } from "../../api/api";

function EditProfile({ isOpenEdit, onOpenEdit, onCloseEdit, initialRef, finalRef, data, setloadForm }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();

    function formatDateForInput(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formik = useFormik({
        initialValues: {
            fullName: data.fullName,
            email: data.email,
            address: data.address,
            dob: formatDateForInput(new Date(data.dob)),
            gender: data.gender,
            role: data.role,
            phone: data.phone,
            status: data.status
        },
        validationSchema: Yup.object({
            fullName: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            address: Yup.string().required('Required'),
            dob: Yup.date().required('Required'),
            gender: Yup.string().required('Required'),
            role: Yup.string().required("Required"),
            phone: Yup.string().matches("^(0|\\+84)(\\d{8,9})$", "Wrong phone format").required('Required'),
            status: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            console.log(values);
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            try {
                const response = await UPDATE_USER_PROFILE(token, values, data.id);
                if (response.isSuccess) {
                    toast({
                        title: "Update profile successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    formik.resetForm();
                    setloadForm(false);
                    onCloseEdit();
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }   
        }
    });

    return (
        <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpenEdit}
            onClose={onCloseEdit}
            size={"xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit user profile</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <Flex flexWrap={"wrap"}>
                            <Stack className="col-6" spacing={4}>
                                <FormControl mt={4}>
                                    <FormLabel>Fullname</FormLabel>
                                    <Input ref={initialRef} name="fullName" value={formik.values.fullName} onChange={(e) => formik.setFieldValue('fullName', e.target.value)}/>
                                </FormControl>
                                {formik.errors.fullName && formik.touched.fullName && (
                                    <p style={{ color: "red" }}>{formik.errors.fullName}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Email</FormLabel>
                                    <Input name="email" value={formik.values.email} onChange={(e) => formik.setFieldValue('email', e.target.value)}/>
                                </FormControl>
                                {formik.errors.email && formik.touched.email && (
                                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Address</FormLabel>
                                    <Input name="address" value={formik.values.address} onChange={(e) => formik.setFieldValue('address', e.target.value)}/>
                                </FormControl>
                                {formik.errors.address && formik.touched.address && (
                                    <p style={{ color: "red" }}>{formik.errors.address}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Date of birth</FormLabel>
                                    <Input name="dob" type="date" value={formik.values.dob} onChange={(e) => formik.setFieldValue('dob', e.target.value)} />
                                </FormControl>
                                {formik.errors.dob && formik.touched.dob && (
                                    <p style={{ color: "red" }}>{formik.errors.dob}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Gender</FormLabel>
                                    <Select name="gender" value={formik.values.gender} onChange={(e) => formik.setFieldValue('gender', e.target.value)}>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </Select>
                                </FormControl>
                                {formik.errors.gender && formik.touched.gender && (
                                    <p style={{ color: "red" }}>{formik.errors.gender}</p>
                                )}
                            </Stack>
                            <Stack className="col-6" spacing={4}>
                                <FormControl mt={4}>
                                    <FormLabel>Role</FormLabel>
                                    {data.role === "Student" ? (
                                        <Input name="role" value={formik.values.role} onChange={(e) => formik.setFieldValue('role', e.target.value)} isDisabled={true}/>
                                    ) : (
                                        <Select name="role" value={formik.values.role} onChange={(e) => formik.setFieldValue('role', e.target.value)}>
                                            <option value='Admin'>Admin</option>
                                            <option value='Mentor'>Mentor</option>
                                        </Select>
                                    )}
                                </FormControl>
                                {formik.errors.role && formik.touched.role && (
                                    <p style={{ color: "red" }}>{formik.errors.role}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Phone</FormLabel>
                                    <Input name="phone" value={formik.values.phone} onChange={(e) => formik.setFieldValue('phone', e.target.value)}/>
                                </FormControl>
                                {formik.errors.phone && formik.touched.phone && (
                                    <p style={{ color: "red" }}>{formik.errors.phone}</p>
                                )}
                                <FormControl mt={4}>
                                    <FormLabel>Status</FormLabel>
                                    <Select name="status" value={formik.values.status} onChange={(e) => formik.setFieldValue('status', e.target.value)}>
                                        <option value='Active'>Active</option>
                                        <option value='Inactive'>Inactive</option>
                                    </Select>
                                </FormControl>
                                {formik.errors.status && formik.touched.status && (
                                    <p style={{ color: "red" }}>{formik.errors.status}</p>
                                )}
                            </Stack>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} type="submit" isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Save"}</Button>
                        <Button onClick={onCloseEdit}>Cancel</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}

function ChangePassword({ isOpenChangePassword, onOpenChangePassword, onCloseChangePassword, initialRefChangePassword, finalRefChangePassword }) {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useToast();
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required('Required'),
            newPassword: Yup.string().min(5, "Minimum 5 characters").required('Required'),
            confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match").required('Required')
        }),
        onSubmit: async (values) => {
            setSubmitted(true);
            try {
                const sendData = {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword
                }
                const token = localStorage.getItem("token");
                const response = await CHANGE_PASSWORD(sendData, token);
                if (response.isSuccess) {
                    toast({
                        title: "Change password success",
                        description: "Your password has been changed",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setShowConfirmPassword(false);
                    setShowNewPassword(false);
                    setShowOldPassword(false);
                    onCloseChangePassword();
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Change password failed",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                setSubmitted(false);
            }
        }
    });
    return (
        <Modal
            initialFocusRef={initialRefChangePassword}
            isOpen={isOpenChangePassword}
            onClose={onCloseChangePassword}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ChangePassword</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <FormControl isRequired>
                            <FormLabel>Old password</FormLabel>
                            <InputGroup>
                                <Input ref={initialRefChangePassword} name="oldPassword" type={showOldPassword ? "text" : "password"} placeholder='Old password' onChange={formik.handleChange} />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowOldPassword((showOldPassword) => !showOldPassword)
                                        }
                                    >
                                        {showOldPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.oldPassword && formik.touched.oldPassword && (
                            <p style={{ color: "red" }}>{formik.errors.oldPassword}</p>
                        )}
                        <FormControl mt={4} isRequired>
                            <FormLabel>New password</FormLabel>
                            <InputGroup>
                                <Input type={showNewPassword ? "text" : "password"} name="newPassword" placeholder='New password' onChange={formik.handleChange}/>
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowNewPassword((showNewPassword) => !showNewPassword)
                                        }
                                    >
                                        {showNewPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.newPassword && formik.touched.newPassword && (
                            <p style={{ color: "red" }}>{formik.errors.newPassword}</p>
                        )}
                        <FormControl mt={4} isRequired>
                            <FormLabel>New password</FormLabel>
                            <InputGroup>
                                <Input type={showConfirmPassword ? "text" : "password"} name="confirmPassword" placeholder='Confirm password' onChange={formik.handleChange} />
                                <InputRightElement h={"full"}>
                                    <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                                        }
                                    >
                                        {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                            <p style={{ color: "red" }}>{formik.errors.confirmPassword}</p>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            type="submit" 
                            colorScheme="rgb(99, 122, 138);"
                            mr={3} 
                            isDisabled={submitted}
                            _hover={{
                                bgGradient: "linear(to-r, rgb(88, 168, 168), rgb(88, 135, 168))",
                                boxShadow: "xl",
                            }}
                        >
                            {submitted ? <LoadingIcon style={{ width: "20px", height: "20px", marginRight: "8px" }} /> : "Save"}
                        </Button>
                        <Button onClick={onCloseChangePassword}>Cancel</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}

export function Profile(){
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
    const { isOpen: isOpenChangePassword, onOpen: onOpenChangePassword, onClose: onCloseChangePassword } = useDisclosure();
    const { initialRef: initialRefEdit, finalRef: finalRefEdit } = useRef(null);
    const { initialRef: initialRefChangePassword, finalRef: finalRefChangePassword } = useRef(null);
    const [data, setData] = useState({});
    const [loadForm, setloadForm] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const fetchData = async () => {
                const response = await GET_USER_INFO(token);
                if (response.isSuccess) {
                    setData(response.data);
                    setloadForm(true);
                }
            }
            fetchData();
        }
    }, [loadForm]);

    return (
        <Box
            display={{ base: "flex", md: "block" }}
            marginTop={"15vh"}
            p={8}
            bg="white"
            borderRadius="xl"
            boxShadow="lg"
            maxWidth="1200px"
            mx="auto"
        >
            {loadForm ? (
                <div>
                    <EditProfile isOpenEdit={isOpenEdit} onOpenEdit={onOpenEdit} onCloseEdit={onCloseEdit} initialRefEdit={initialRefEdit} finalRefEdit={finalRefEdit} data={data} />
                    <ChangePassword isOpenChangePassword={isOpenChangePassword} onOpenChangePassword={onOpenChangePassword} onCloseChangePassword={onCloseChangePassword} initialRefChangePassword={initialRefChangePassword} finalRefChangePassword={finalRefChangePassword} />
                    <Flex flexWrap="wrap" justifyContent="space-between" mb={8}>
                        <Heading size="xl" color="teal.500">Profile Information</Heading>
                        <Flex gap={4}>
                            {data.role === "SUAdmin" && (
                                <Button
                                    onClick={onOpenEdit}
                                    size="md"
                                    colorScheme="teal"
                                    leftIcon={<Icon as={FaRegEdit} />}
                                >
                                    Edit Profile
                                </Button>
                            )}
                            <Button
                                onClick={onOpenChangePassword}
                                size="md"
                                colorScheme="blue"
                                variant="outline"
                            >
                                Change Password
                            </Button>
                        </Flex>
                    </Flex>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
                        <Box 
                            p={6} 
                            bg="gray.50" 
                            borderRadius="lg"
                        >
                            <VStack spacing={6} align="stretch">
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Fullname:</Text>
                                    <Text fontSize="lg">{data.fullName}</Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Gender:</Text>
                                    <Text fontSize="lg">{data.gender}</Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Date of birth:</Text>
                                    <Text fontSize="lg">{format(new Date(data.dob), 'dd/MM/yyyy')}</Text>
                                </Flex>
                            </VStack>
                        </Box>
                        <Box 
                            p={6} 
                            bg="gray.50" 
                            borderRadius="lg"
                        >
                            <VStack spacing={6} align="stretch">
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Phone:</Text>
                                    <Text fontSize="lg">{data.phone}</Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Email:</Text>
                                    <Text fontSize="lg">{data.email}</Text>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text fontWeight="bold" fontSize="lg" width="140px">Address:</Text>
                                    <Text fontSize="lg">{data.address}</Text>
                                </Flex>
                            </VStack>
                        </Box>
                    </Grid>
                </div>
            ) : null}
        </Box>
    );
}

function generatePatternImage(canvasRef, amount, setPatternImage){
    if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        const colors = generateRandomColor(amount);
        const patterns = pattern.generate(colors);
        const images = [];

        for (let i = 0; i < amount; i++) {
            const patternData = patterns[i]; 
            ctx.fillStyle = patternData;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            images.push(canvas.toDataURL());
        }
        console.log(images);
        return setPatternImage(images);
    }
};

const generateRandomColor = (amount) => {
    const colorArray = [];
    for(var i = 0; i < amount; i++){
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        colorArray.push(`#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`);
    }
    return colorArray;
};

export function GetUser(){
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
    const { initialRef: initialRefEdit, finalRef: finalRefEdit } = useRef(null);
    const id = useParams().id;
    const navigate = useNavigate();
    const [loadForm, setloadForm] = useState(false);
    const [data, setData] = useState({});
    const token = localStorage.getItem("token");
    const [patternImage, setPatternImage] = useState([]);
    const canvasRef = useRef(null);
    useEffect(() => {
        if(localStorage.getItem("id") === id || localStorage.role === "Student"){
            navigate("/user/profile");
            return;
        }
        const fetchData = async () => {
            const {isSuccess, res} = await GET_ACCOUNT_DETAIL(token, id);
            if (isSuccess) {
                const response = await res.json();
                setData(response.data);
                setloadForm(true);
            }
        };
        fetchData();
        if(loadForm){
            // Check if patternImage is already populated
            if (patternImage.length === 0) {
                let numPatterns = 1; // Default number of patterns
                if (data.userResModel.role === "Student") {
                    numPatterns = 1;
                } else if (data.classInformationOfOthers != null) {
                    numPatterns = data.classInformationOfOthers.length;
                }
                generatePatternImage(canvasRef, numPatterns, setPatternImage);
            }
        }
    }, [id, loadForm]);

    return (
        <Container maxW="container.xl" marginLeft={"-1vw"}>
            <Box
                display={{ base: "fixed", md: "block" }}
                marginTop={"15vh"}
                justifyContent= "flex" 
                alignItems="left" 
            >
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                <Flex marginBottom={"3vh"} color={"rgb(36, 112, 166)"}>
                    <Link to={`/student`}>
                        {"<<"} Go back
                    </Link>
                </Flex>
                {loadForm ? (
                    <div>
                        <EditProfile isOpenEdit={isOpenEdit} onOpenEdit={onOpenEdit} onCloseEdit={onCloseEdit} initialRefEdit={initialRefEdit} finalRefEdit={finalRefEdit} data={data.userResModel} setloadForm={setloadForm}/>
                        {/* <ChangePassword isOpenChangePassword={isOpenChangePassword} onOpenChangePassword={onOpenChangePassword} onCloseChangePassword={onCloseChangePassword} initialRefChangePassword={initialRefChangePassword} finalRefChangePassword={finalRefChangePassword} /> */}
                        <Box>
                            <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
                                {/* <Flex>
                                    <Flex>
                                        <Heading>Profile</Heading>
                                    </Flex>
                                    <Flex>
                                        <Text>{data.userResModel.role}</Text>
                                    </Flex>
                                </Flex> */}
                                <Flex>
                                    <Flex>
                                        <Heading>Profile</Heading>
                                    </Flex>
                                    <Center w='9vh' maxH={"5vh"} bg={data.userResModel.role === "Student" ? "green" : data.userResModel.role === "Admin" ? "rgb(240, 122, 48)" : data.userResModel.role === "Trainer" ? "rgb(242, 232, 41)" : "blue"} marginLeft={"15px"} borderRadius={20}>
                                        <Text color={"white"}>{data.userResModel.role}</Text>
                                    </Center>
                                    <Center w='9vh' className={data.userResModel.status === "Active" ? "breathing-active" : "breathing-inactive"} maxH={"5vh"} marginLeft={"15px"} borderRadius={20}>
                                        <Text color={"white"}>{data.userResModel.status}</Text>
                                    </Center>
                                </Flex>
                            <Flex>
                                    {localStorage.role === "SUAdmin" ? (
                                        <div>
                                            <Button onClick={onOpenEdit} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="1vh">
                                                <Icon fontSize="20px" as={FaRegEdit} marginRight={"10px"} /> Edit user profile
                                            </Button>
                                        </div>
                                    ) : (<div marginRight="1vh"></div>)}
                                    {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                        Change password
                                    </Button> */}
                                </Flex>
                            </Flex>
                            <Flex flexWrap="wrap">
                                <Stack className="col-6" marginTop="10" spacing={4}>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="2">
                                            <b>Fullname:</b>
                                        </Text>
                                        <Text as="span">
                                            {data.userResModel.fullName}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="2">
                                            <b>Gender:</b>
                                        </Text>
                                        <Text as="span">
                                            {data.userResModel.gender}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="5">
                                            <b>Date of birth:</b>
                                        </Text>
                                        <Text as="span">
                                            {format(new Date(data.userResModel.dob), 'dd/MM/yyyy')}
                                        </Text>
                                    </Flex>
                                </Stack>
                                <Stack className="col-6" marginTop="10" spacing={4}>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="2">
                                            <b>Phone:</b>
                                        </Text>
                                        <Text as="span">
                                            {data.userResModel.phone}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="2">
                                            <b>Email:</b>
                                        </Text>
                                        <Text as="span">
                                            {data.userResModel.email}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center" fontSize={"20px"}>
                                        <Text as="span" mr="2">
                                            <b>Address:</b>
                                        </Text>
                                        <Text as="span">
                                            {data.userResModel.address}
                                        </Text>
                                    </Flex>
                                </Stack>
                            </Flex>
                        </Box>
                        <Box
                            marginTop={"5vh"}
                        >
                            <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
                                <Heading>Class Information</Heading>
                                <Flex>
                                    {/* {data.role === "SUAdmin" ? (
                                        <div>
                                            <Button onClick={onOpenEdit} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="1vh">
                                                <Icon fontSize="20px" as={FaRegEdit} />
                                            </Button>
                                        </div>
                                    ) : null}
                                    <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                        Change password
                                    </Button> */}
                                </Flex>
                            </Flex>
                            {data.userResModel.role === "Student" && data.classInformationOfStudent != null ? (
                                <Box marginTop={"20px"}>
                                    <HStack spacing='10px'>
                                        <Link to={`/class/id/${data.classInformationOfStudent.classInformationOfOthers.classId}`}>
                                            <Card 
                                                maxW='sm'
                                                bg={"rgb(240, 240, 240)"}
                                                transition="transform 0.3s ease"
                                                _hover={{ transform: "scale(1.02)" }}
                                            >
                                                <CardBody>
                                                    <Image 
                                                        src={patternImage}
                                                        borderRadius='lg'
                                                    />
                                                    <Stack mt='6' spacing='3'>
                                                        <Heading size='md'>{data.classInformationOfStudent.classInformationOfOthers.className}
                                                        </Heading>
                                                        <Text>
                                                            {data.classInformationOfStudent.classInformationOfOthers.program} <span>•</span> {data.classInformationOfStudent.classInformationOfOthers.location}
                                                        </Text>
                                                        <Text>
                                                            {data.classInformationOfStudent.classInformationOfOthers.startAt} - {data.classInformationOfStudent.classInformationOfOthers.endAt}
                                                        </Text>
                                                        <Text fontSize={"12px"}>Managed by <b>{data.classInformationOfStudent.adminOfCLass.name}</b></Text>
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </HStack>
                                </Box>
                            ) : data.classInformationOfOthers != null ? (
                                <Box marginTop={"20px"} maxW="1000px"> {/* Thay đổi giá trị của maxW cho phù hợp */}
                                    {data.classInformationOfOthers != null ? (
                                        <Box overflowX="auto" overflowY="hidden" mt="10px" mb="10px" width="80vw">
                                            <HStack spacing='30px' maxH="100%">
                                                {data.classInformationOfOthers.map((item, index) => (
                                                    <Box key={index} minW='320px'>
                                                        <Link to={`/class/id/${item?.classId}`}>
                                                            <Card 
                                                                maxW='sm'
                                                                bg={"rgb(240, 240, 240)"}
                                                                transition="transform 0.3s ease"
                                                                _hover={{ transform: "scale(1.02)" }}
                                                            >
                                                                <CardBody>
                                                                    <Image 
                                                                        src={patternImage[index % patternImage.length]}
                                                                        borderRadius='lg'
                                                                    />
                                                                    <Stack mt='6' spacing='3'>
                                                                        <Heading size='md'>{item?.className}</Heading>
                                                                        <Text>
                                                                            {item?.program} <span>•</span> {item?.location}
                                                                        </Text>
                                                                        <Text>
                                                                            {item?.startAt} - {item?.endAt}
                                                                        </Text>
                                                                        {item?.trainerId != null ? (
                                                                            <Text fontSize={"12px"}>Managed by <b>{item?.admin}</b> <span>•</span> Trained by <b>{item?.trainer}</b></Text>
                                                                        ) : (
                                                                            <Text fontSize={"12px"}>Managed by <b>{item?.admin}</b></Text>
                                                                        )}
                                                                    </Stack>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </Box>
                                                ))}
                                            </HStack>
                                        </Box>
                                    ) : (
                                        <Flex justifyContent="center" alignItems="center" width="100%" height="100%" marginTop={"5vh"}>
                                            <Text
                                                fontSize={"20px"}
                                            >
                                                <i>No class information</i>
                                            </Text>
                                        </Flex>
                                    )}
                                </Box>
                            ): (
                                <Flex justifyContent="center" alignItems="center" width="100%" height="100%" marginTop={"5vh"}>
                                    <Text
                                        fontSize={"20px"}
                                    >
                                        <i>No class information</i>
                                    </Text>
                                </Flex>
                            )}
                        </Box>
                    </div>
                ) : (
                    <Flex flexWrap="wrap" justifyContent="space-between" display="flex" marginTop={"7vh"} marginLeft={"22vh"}>
                        <Icon as={LoadingIcon} w={100} h={100} />
                    </Flex>
                )}
            </Box>
        </Container>
    );
}
