import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    useToast,
    Box,
    Flex,
    PinInput,
    PinInputField,
    HStack,
    useDisclosure,
    Text,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { Icon } from "@chakra-ui/icons";
import { json, useParams, useNavigate, useLocation } from "react-router-dom";
import QRCodeComponent from "../QR/QRComponent";
import { CHECK_ATTENDANCE, GET_ATTENDANCE_DETAIL_STUDENT } from "../../api/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";

export function ShowAttendance({ isOpenAttendance, onOpenAttendance, onCloseAttendance, initialRefAttendance, finalRefAttendance, data, id, isLoadingAttendanceDetail }) {
    const toast = useToast();
    const handleCopy = (value) => {
        navigator.clipboard.writeText(value)
        .then(() => {
            toast({
                title: "Copied to clipboard",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        })
        .catch(err => {
            toast({
                title: "Error",
                description: err.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };
    return (
        <Modal
            initialFocusRef={initialRefAttendance}
            finalFocusRef={finalRefAttendance}
            isOpen={isOpenAttendance}
            onClose={onCloseAttendance}
        >
            <ModalOverlay />
            {isLoadingAttendanceDetail ? (
                <ModalContent>
                    <ModalHeader>Attendance</ModalHeader>
                    <ModalBody pb={6}>
                        <Flex justifyContent={"center"}>
                            <Flex
                                justifyContent={"center"}
                                alignItems={"center"}
                                flexWrap={"wrap"}
                            >
                                <Box mr={"10px"}>
                                    <Icon as={LoadingIcon} w={50} h={50} />
                                </Box>
                                <br/>
                                <Flex>
                                    <Text>Loading</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            ) : (
                <ModalContent>
                    <ModalHeader>Attendance</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                    <Flex justifyContent={"center"}>
                        <Flex
                            borderWidth={"2px"}
                            width={"25vh"}
                            height={"25vh"}
                            borderRadius={"md"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <QRCodeComponent qrText={`http://160.30.44.65:9003/attendance/id/${data.id}/class/id/${id}`} />
                        </Flex>
                    </Flex>
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" mt={"10px"}>
                        <Text>PIN Code</Text>
                            <Box onClick={() => handleCopy(data.code)} padding={"10px"}>
                                <HStack spacing={4} mt={"5px"}>
                                        <PinInput defaultValue={`${data.code}`} size={"lg"} isDisabled>
                                            <PinInputField style={{fontWeight:"bold", fontSize:"25px", opacity: 1}} />
                                            <PinInputField style={{fontWeight:"bold", fontSize:"25px", opacity: 1}} />
                                            <PinInputField style={{fontWeight:"bold", fontSize:"25px", opacity: 1}} />
                                            <PinInputField style={{fontWeight:"bold", fontSize:"25px", opacity: 1}} />
                                        </PinInput>
                                </HStack>
                            </Box>
                    </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseAttendance}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            )}
        </Modal>
    );
}

export function RedirectAttendance(){
    const navigate = useNavigate();
    const from = useLocation();
    const params = useParams();
    const { isOpen: isOpenAttendance, onOpen: onOpenAttendance, onClose: onCloseAttendance } = useDisclosure();
    const { initialRef: initialRefAttendance, finalRef: finalRefAttendance } = useRef(null);
    const { isOpen: isOpenLoading, onOpen: onOpenLoading, onClose: onCloseLoading } = useDisclosure();
    const { initialRef: initialRefLoading, finalRef: finalRefLoading } = useRef(null);
    const [ AttendanceData, setAttendanceData ] = useState({});
    const [isAuth, setIsAuth] = useState(false);
    const { attendanceid, id } = params;
    useEffect(() => {
        onOpenAttendance();
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            const role = localStorage.getItem("role");
            if (role === "Admin" || role === "SUAdmin") {
                navigate(`/class/id/${id}`);
            }
            const { isSuccess, res } = await GET_ATTENDANCE_DETAIL_STUDENT(token, attendanceid);
            if (isSuccess) {
                const response = await res.json();
                setAttendanceData(response.data);
                setIsAuth(true);
            } else {
                if(res.status === 401){
                    localStorage.setItem("from", from.pathname);
                    navigate("/login");
                }
            }
        }
        fetchData();
    }, [from, navigate, attendanceid, id, onOpenAttendance]);

    // Trang sẽ không hiển thị gì vì nó chỉ được sử dụng để thực hiện điều hướng
    return (
        <Box>
            <CheckAttendance isOpenAttendance={isOpenAttendance} onCloseAttendance={onCloseAttendance} initialRefAttendance={initialRefAttendance} finalRefAttendance={finalRefAttendance} AttendanceData={AttendanceData} isAuth={isAuth}/>
        </Box>
    );
}

export function CheckAttendance({isOpenAttendance, onCloseAttendance, initialRefAttendance, finalRefAttendance, AttendanceData, isAuth}){
    const navigate = useNavigate();
    const from = useLocation();
    const params = useParams();
    const { attendanceid, id } = params;
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema: Yup.object({
            code: Yup.string().required("Code is required")
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            try {
                const {isSuccess, res} = await CHECK_ATTENDANCE(token, attendanceid, values);
                if (isSuccess) {
                    const response = await res.json();
                    if(response.isSuccess){
                        setIsSubmitting(false);
                        navigate(`/home`);
                        toast({
                            title: response.message,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: "Error",
                            description: response.message,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                } else {
                    if(res.status === 401){
                        localStorage.setItem("from", from.pathname);
                        navigate("/login");
                    }
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    return (
        <Modal
            initialFocusRef={initialRefAttendance}
            finalFocusRef={finalRefAttendance}
            isOpen={isOpenAttendance}
            onClose={onCloseAttendance}
        >
            <ModalOverlay />
            {isAuth ? (
                <ModalContent>
                    <ModalHeader>Attendance</ModalHeader>
                    <ModalCloseButton />
                    {AttendanceData.attendanceStatus === "Done" ? (
                        <ModalBody pb={6}>
                            <Flex justifyContent={"center"}>
                                <Flex
                                    borderWidth={"2px"}
                                    width={"60vh"}
                                    height={"10vh"}
                                    padding={"30px"}
                                    alignContent={"center"}
                                    borderRadius={"md"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    fontWeight={"bold"}
                                    flexWrap={"wrap"}
                                >
                                    <Text>The attendance with</Text>
                                    <Text>{AttendanceData.class.className} is finished</Text> 
                                </Flex>
                            </Flex>
                        </ModalBody>
                    ) : (
                        <div>
                            {AttendanceData.isCheckAttendance === true ? (
                                <ModalBody pb={6}>
                                    <Flex justifyContent={"center"}>
                                        <Flex
                                            borderWidth={"2px"}
                                            width={"60vh"}
                                            height={"10vh"}
                                            padding={"30px"}
                                            alignContent={"center"}
                                            borderRadius={"md"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            fontWeight={"bold"}
                                            flexWrap={"wrap"}
                                        >
                                            <Text>You checked attendance with</Text>
                                            <Text>{AttendanceData.class.className} already</Text> 
                                        </Flex>
                                    </Flex>
                                </ModalBody>
                            ) : (
                                <form onSubmit={formik.handleSubmit}>
                                    <ModalBody pb={6}>
                                        <Flex justifyContent={"center"}>
                                            <Flex
                                                borderWidth={"2px"}
                                                width={"60vh"}
                                                height={"10vh"}
                                                padding={"30px"}
                                                alignContent={"center"}
                                                borderRadius={"md"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                fontWeight={"bold"}
                                                flexWrap={"wrap"}
                                            >
                                                You are checking attendance with <Text>{AttendanceData.class.className}</Text>
                                            </Flex>
                                        </Flex>
                                        <Flex justifyContent="center" flexDirection="column" alignItems="center" mt={"15px"}>
                                            <Text>PIN Code</Text>
                                            <HStack spacing={4} mt={"5px"}>
                                                <PinInput size={"lg"} placeholder="" onChange={(value) => formik.setFieldValue('code', value)}>
                                                    <PinInputField style={{fontWeight:"bold", fontSize:"25px"}} autoFocus={true}/>
                                                    <PinInputField style={{fontWeight:"bold", fontSize:"25px"}}/>
                                                    <PinInputField style={{fontWeight:"bold", fontSize:"25px"}}/>
                                                    <PinInputField style={{fontWeight:"bold", fontSize:"25px"}}/>
                                                </PinInput>
                                            </HStack>
                                        </Flex>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button colorScheme={"blue"} type="submit" mr={"10px"} isDisabled={isSubmitting}>{isSubmitting ? (
                                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                                            ) : (
                                            "Check Attendance"
                                        )}</Button>
                                        <Button onClick={onCloseAttendance}>Close</Button>
                                    </ModalFooter>
                                </form>
                            )}
                        </div>
                    )}
                </ModalContent>
                ) : (
                <ModalContent>
                    <ModalHeader>Attendance</ModalHeader>
                    <ModalBody pb={6}>
                        <Flex justifyContent={"center"}>
                            <Flex
                                justifyContent={"center"}
                                alignItems={"center"}
                                flexWrap={"wrap"}
                            >
                                <Box mr={"10px"}>
                                    <Icon as={LoadingIcon} w={50} h={50} />
                                </Box>
                                <br/>
                                <Flex>
                                    <Text>Loading</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            )}
        </Modal>
    );
}
