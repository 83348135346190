import {
    useToast,
} from "@chakra-ui/react";

import { useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";

export default function Auth(){
    const navigate = useNavigate();
    const toast = useToast();
    useEffect(() => {
        const token = localStorage.getItem('token'); // Lấy token từ local storage hoặc nơi lưu trữ khác
        if (token) {
            if (isTokenExpired(token)) {
                navigate("/login");
                localStorage.clear();
                toast({
                    title: "Error",
                    description: "Expired session, please login again",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                navigate("/home");
            }
        } else {
            // Nếu không có token, cũng load phần đăng nhập
            navigate("/login");
        }
    }, [navigate, toast]);

    function isTokenExpired(token) {
        const decodedToken = jwtDecode(token);
        if (!decodedToken.hasOwnProperty('exp')) {
            return false; // JWT không có trường exp, không xác định được hạn của token
        }

        const expirationTime = new Date(decodedToken.exp).getTime();
        const currentTime = new Date().getTime();

        return expirationTime < currentTime;
    }
    return null;
}