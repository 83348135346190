import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import logoutAccount from "./components/FnGeneral";

export const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
  const [loadForm, setLoadForm] = useState(false);

  const updateLoadForm = (newValue) => {
    setLoadForm(newValue);
  };
  
  return (
    <StateContext.Provider value={{ loadForm, updateLoadForm }}>
      {children}
    </StateContext.Provider>
  );
};
