// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCylsEYv6t6vWR8-qCzH34uJWfb-zqTABg",
  authDomain: "axlm-2024.firebaseapp.com",
  projectId: "axlm-2024",
  storageBucket: "axlm-2024.appspot.com",
  messagingSenderId: "530455458071",
  appId: "1:530455458071:web:8348b80938a60ca71fc752"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;