import {
    Box,
    Flex,
    CircularProgress,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    Icon,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    useToast,
    PinInput,
    PinInputField,
    HStack,
    Spacer,
    ButtonGroup,
    Table,
    Tr,
    Td,
    Tbody,
    color
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { ReactComponent as LoadingIcon } from '../icons/loading-logo.svg';
import { Link, json, useNavigate } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { SEND_OTP, VERIFY_OTP, RESET_PASSWORD } from "../api/api";

function ForgotPasswordForm (){
    const toast = useToast();
    const [submitted, setSubmited] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
        }),
        onSubmit: async (values) => {
            setSubmited(true);
            try {
                const response = await SEND_OTP(values.email);
                if (response.isSuccess) {
                    const now = new Date();
                    localStorage.setItem("verify-email", values.email);
                    localStorage.setItem("verify-create-at", now);
                    window.location.reload();
                    toast({
                        title: "Success",
                        description: "An email has been sent to your email address",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error("Recovery error:", error);
            } finally {
                setSubmited(false);
            };
        }
    });
    return(
        <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            minHeight: "100vh",
        }}>
            <Stack
                bg={"gray.50"}
                rounded={"xl"}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: "lg" }}
                width="100%"
            >
                <Stack spacing={4}>
                    <Heading
                        color={"gray.800"}
                        lineHeight={1.1}
                        fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    >
                        Recovery Password
                    </Heading>
                    <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                        You can use your email to recover your password.
                    </Text>
                </Stack>
                <Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={4}>
                            <FormControl isRequired>
                            <Input
                                bg={"gray.100"}
                                border={0}
                                color={"gray.500"}
                                placeholder="Enter your email address"
                                _placeholder={{
                                    color: "gray.500",
                                }}
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                            </FormControl>
                            <Button
                                fontFamily={"heading"}
                                mt={8}
                                w={"full"}
                                bgColor="rgb(99, 122, 138);"
                                color={"white"}
                                _hover={{
                                    bgGradient: "linear(to-r, rgb(88, 168, 168), rgb(88, 135, 168))",
                                    boxShadow: "xl",
                                }}
                                type="submit"
                                isDisabled={submitted}
                            >
                                {submitted ? <LoadingIcon style={{ width: "20px", height: "20px", marginRight: "8px" }} /> : "Send email"}
                            </Button>
                            <Text color={"black.500"} fontSize={{ base: "sm", sm: "md" }} marginTop={"10px"}>
                                <Link to={"/login"}>Go back to login</Link>
                            </Text>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </div>
    )
};

function VerifyOTPForm(){
    const [submitted, setSubmitted] = useState(false);
    const [minutesResend, setMinutesResend] = useState();
    const [secondsResend, setSecondsResend] = useState();
    const [canResend, setCanResend] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const email = localStorage.getItem("verify-email");

    const handleChange = (value, index) => {
        // Update the pinValue state with the new value at the given index
        const newOTPValue = formik.values.otpcode.slice(0, index) + value + formik.values.otpcode.slice(index + 1);
        formik.setFieldValue('otpcode', newOTPValue);
    };

    useEffect(() => {
        const createdTime = new Date(localStorage.getItem("verify-create-at"));
        const canResend = createdTime.setMinutes(createdTime.getMinutes() + 2);
        const firstNow = new Date();
        let time = canResend - firstNow;
        setMinutesResend(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)));
        setSecondsResend(Math.floor((time % (1000 * 60)) / 1000));
        const interval = setInterval(() => {
            const now = new Date();
            let time = canResend - now;
            setMinutesResend(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)));
            setSecondsResend(Math.floor((time % (1000 * 60)) / 1000));
            if(time < 0){
                setCanResend(true);
                clearInterval(interval);
                setMinutesResend(0);
                setSecondsResend(0);
            }
        }, 1000);
    },[]);

    function handleResendCode(){
        localStorage.removeItem("verify-email");
        window.location.reload();
    }
    
    function handleBackToLogin(){
        localStorage.removeItem("verify-email");
        localStorage.removeItem("verify-create-at");
    }

    const formik = useFormik({
        initialValues: {
            email: email,
            otpcode: ""
        },
        validationSchema: Yup.object({
            otpcode: Yup.string()
                .required("Required"),
        }),
        onSubmit: async (values) => {
            setSubmitted(true);
            try {
                const response = await VERIFY_OTP(values);
                if (response.isSuccess) {
                    localStorage.setItem("verify-otp", values.otp);
                    navigate("/recovery/reset");
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                    if(response.message === "The OTP is expired!"){
                        localStorage.removeItem("verify-email");
                        localStorage.removeItem("verify-create-at");
                        window.location.reload();
                    }
                }
            } catch (error) {
                console.error("Recovery error:", error);
            } finally {
                setSubmitted(false);
            };
        }
    });
    return(
        <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            minHeight: "100vh",
        }}>
            <Stack
                bg={"gray.50"}
                rounded={"xl"}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: "lg" }}
                width="100%"
            >
                <Stack spacing={4}>
                    <Heading
                        color={"gray.800"}
                        lineHeight={1.1}
                        fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    >
                        Verify OTP
                        <Text
                        as={"span"}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text"
                        ></Text>
                    </Heading>
                    <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                        The OTP has been sent to your email address <b>{email}</b>. Please check your email and enter the OTP code.
                    </Text>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        <HStack>
                            <PinInput size='md' placeholder="" onChange={(value) => formik.setFieldValue('otpcode', value)}>
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                                <PinInputField />
                            </PinInput>
                        </HStack>
                    </Box>
                    <Flex>
                    <Table>
                        <Tbody>
                            <Tr>
                                <Text color={"black.500"} fontSize={{ base: "sm", sm: "md" }} marginTop={"20px"} pointerEvents={!canResend ? "none" : ""}>
                                    <Link onClick={handleResendCode}>Resend code</Link> <span style={{ color: "rgb(99, 122, 138)" }}>{canResend ? "" : `in ${minutesResend} minutes ${secondsResend} seconds`}</span>
                                </Text>
                            </Tr>
                            <Tr>
                                <Flex flexWrap={"wrap"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Text color={"black.500"} fontSize={{ base: "sm", sm: "md" }}>
                                        <Link onClick={handleBackToLogin} to={"/login"}>Go back to login</Link>
                                    </Text>
                                    <Button
                                        fontFamily={"heading"}
                                        w={"15vh"}
                                        bgColor="rgb(99, 122, 138);"
                                        color={"white"}
                                        _hover={{
                                            bgGradient: "linear(to-r, rgb(88, 168, 168), rgb(88, 135, 168))",
                                            boxShadow: "xl",
                                        }}
                                        type="submit"
                                        isDisabled={submitted}
                                    >
                                        {submitted ? <LoadingIcon style={{ width: "20px", height: "20px", marginRight: "8px" }} /> : "Verify"}
                                    </Button>
                                </Flex>
                            </Tr>
                        </Tbody>
                    </Table>
                    </Flex>
                </form>
            </Stack>
        </div>
    );
}

export function ResetPasswordForm() {
    const [submitted, setSubmitted] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(5, "Must be at least 5 characters")
                .required("Required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required("Required")
        }),
        onSubmit: async (values) => {
            setSubmitted(true);
            try {
                const email = localStorage.getItem("verify-email");
                const sendData = {
                    email: email,
                    password: values.password
                }
                const response = await RESET_PASSWORD(sendData);
                if (response.isSuccess) {
                    toast({
                        title: "Success",
                        description: "Reset password successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    localStorage.clear();
                    navigate("/login");
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error("Recovery error:", error);
            } finally {
                setSubmitted(false);
            }
        }
    });
    return(
        <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            minHeight: "100vh",
        }}>
            <Stack
                bg={"gray.50"}
                rounded={"xl"}
                p={{ base: 4, sm: 6, md: 8 }}
                spacing={{ base: 8 }}
                maxW={{ lg: "lg" }}
                width="100%"
            >
                <Stack spacing={4}>
                    <Heading
                        color={"gray.800"}
                        lineHeight={1.1}
                        fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    >
                        Reset Password
                        <Text
                        as={"span"}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text"
                        ></Text>
                    </Heading>
                    <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                        The finally step to reset your password.
                    </Text>
                </Stack>
                <Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={4}>
                            <FormControl id="newpassword" isRequired>
                                <FormLabel>New password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            bg={"gray.100"}
                                            border={0}
                                            color={"gray.500"}
                                            _placeholder={{
                                                color: "gray.500",
                                            }}
                                            type={showNewPassword ? "text" : "password"}
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                        />
                                        <InputRightElement h={"full"}>
                                            <Button
                                                variant={"ghost"}
                                                onClick={() =>
                                                setShowNewPassword((showNewPassword) => !showNewPassword)
                                                }
                                            >
                                                {showNewPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                            </FormControl>
                            {formik.errors.password && formik.touched.password && (
                                <p style={{ color: "red" }}>{formik.errors.password}</p>
                            )}
                            <FormControl id="confirmpassword" isRequired>
                                <FormLabel>Confirm password</FormLabel>
                                <InputGroup>
                                <Input
                                    bg={"gray.100"}
                                    border={0}
                                    color={"gray.500"}
                                    _placeholder={{
                                    color: "gray.500",
                                    }}
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword} // Đoạn này cần sửa để lấy giá trị từ state/formik
                                    onChange={formik.handleChange} // Đoạn này cần sửa để gọi hàm handleChange của state/formik
                                    />
                                    <InputRightElement h={"full"}>
                                        <Button
                                        variant={"ghost"}
                                        onClick={() =>
                                            setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                                        }
                                        >
                                        {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                                        </Button>
                                    </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                                <p style={{ color: "red" }}>{formik.errors.confirmPassword}</p>
                            )}
                            <Button
                                fontFamily={"heading"}
                                mt={8}
                                w={"full"}
                                bgColor="rgb(99, 122, 138);"
                                color={"white"}
                                _hover={{
                                    bgGradient: "linear(to-r, rgb(88, 168, 168), rgb(88, 135, 168))",
                                    boxShadow: "xl",
                                }}
                                type="submit"
                                isDisabled={submitted}
                            >
                                {submitted ? <LoadingIcon style={{ width: "20px", height: "20px", marginRight: "8px" }} /> : "Reset Password"}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </div>
    );

}

export function Recovery() {
    const email = localStorage.getItem("verify-email");
    useEffect(() => {
        const interval = setInterval(() => {
            const created_at = localStorage.getItem("verify-create-at");
            const now = new Date();
            if (created_at !== null && now.getTime() - parseInt(created_at) > 600000) {
                localStorage.removeItem("verify-email");
                localStorage.removeItem("verify-create-at");
                window.location.reload();
            }
        }, 60000); // Check every minute (60000 milliseconds)

        return () => clearInterval(interval);
    }, []);
    if(email !== null){
        return(
            < VerifyOTPForm />
        );
    } else {
        return(
            < ForgotPasswordForm />
        );
    }
}