import {
  Box,
  Flex,
  CircularProgress,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  useDisclosure,
  Checkbox,
  Badge,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { format, set } from "date-fns";
import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Paginator } from "primereact/paginator";
import { FiTrash2 } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { Form, Link, json, useNavigate, useParams } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  GET_LIST_TRAINING_PROGRAM,
  DELETE_TRAINING_PROGRAM,
  CREATE_TRAINING_PROGRAM,
  GET_TRAINING_PROGRAM_DETAIL,
  GET_LIST_MODULE_BY_TRAINING_PROGRAM,
  UPDATE_TRAINING_PROGRAM,
} from "../../api/api";
import { AddModuleIntoTrainingProgram } from "../Module/Function/ModuleFunction";
import { DeleteModuleProgram } from "./Function/ModuleProgramFunction";

function formatDateForInput(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function EditTrainingProgram({
  isOpenEdit,
  onOpenEdit,
  onCloseEdit,
  initialRef,
  finalRef,
  data,
  setloadForm,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      name: data.name,
      code: data.code,
      duration: data.duration,
      status: data.status || "",  // Thêm trường status
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      duration: Yup.string().required("Required"),
      status: Yup.string().required("Required"),  // Thêm validation cho status
    }),
    onSubmit: async (values) => {
      console.log(values);
      setIsSubmitting(true);
      const token = localStorage.getItem("token");
      if (!token) {
        toast({
          title: "Error",
          description: "Authentication token is missing.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsSubmitting(false);
        return;
      }

      try {
        const response = await UPDATE_TRAINING_PROGRAM(token, data.id, values);
        if (response.isSuccess) {
          toast({
            title: "Update Training Program successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          formik.resetForm();
          setloadForm(false);
          onCloseEdit();
        } else {
          const errorMsg = response.responseFailed || response.message || "An error occurred";
          toast({
            title: "Error",
            description: errorMsg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenEdit}
      onClose={onCloseEdit}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update training program</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.name && formik.touched.name && (
              <p style={{ color: "red" }}>{formik.errors.name}</p>
            )}

            <FormControl mt={4}>
              <FormLabel>Code</FormLabel>
              <Input
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.code && formik.touched.code && (
              <p style={{ color: "red" }}>{formik.errors.code}</p>
            )}

            <FormControl mt={4}>
              <FormLabel>Duration</FormLabel>
              <Input
                name="duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
                maxW={"35vh"}
              />
              <Text as="span" ml={"2vh"}>
                months
              </Text>
            </FormControl>
            {formik.errors.duration && formik.touched.duration && (
              <p style={{ color: "red" }}>{formik.errors.duration}</p>
            )}

            {/* Thêm trường status */}
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Input
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.status && formik.touched.status && (
              <p style={{ color: "red" }}>{formik.errors.status}</p>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseEdit} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function AddNewProgram({
  isOpenAdd,
  onOpenAdd,
  onCloseAdd,
  initialRef,
  finalRef,
  setloadForm,
}) {
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      duration: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      code: Yup.string().required("Required"),
      duration: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      const token = localStorage.getItem("token");
      setIsSubmitting(true);
      if (token) {
        try {
          const response = await CREATE_TRAINING_PROGRAM(token, values);
          if (response.isSuccess) {
            setloadForm(false);
            onCloseAdd();
            toast({
              title: "Create new Training Program successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } else if (response.responseFailed != null) {
            toast({
              title: "Error",
              description: response.responseFailed,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else if (response.message != null) {
            toast({
              title: "Error",
              description: response.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsSubmitting(false);
        }
      }
    },
  });
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenAdd}
      onClose={onCloseAdd}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Training Program</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.name && formik.touched.name && (
              <p style={{ color: "red" }}>{formik.errors.name}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Code</FormLabel>
              <Input
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.code && formik.touched.code && (
              <p style={{ color: "red" }}>{formik.errors.code}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Duration</FormLabel>
              <Input
                name="duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
                maxW={"33vh"}
              />
              <Text as="span" ml={"2vh"}>
                months
              </Text>
            </FormControl>
            {formik.errors.duration && formik.touched.duration && (
              <p style={{ color: "red" }}>{formik.errors.duration}</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              Save
            </Button>
            <Button onClick={onCloseAdd} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function DeleteTrainingProgram({
  isOpenDelete,
  onOpenDelete,
  onCloseDelete,
  initialRefDelete,
  finalRefDelete,
  data,
  setloadForm,
  setCheckedItems,
}) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const jsonData = [];
  for (const key in data) {
    if (data[key]) {
      const id = key.split(":")[0].trim();
      jsonData.push(id);
    }
  }

  const handleDelete = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await DELETE_TRAINING_PROGRAM(token, jsonData);
        if (response.isSuccess) {
          onCloseDelete();
          toast({
            title: `Delete ${jsonData.length} Training Program(s) successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCheckedItems({});
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRefDelete}
      finalFocusRef={finalRefDelete}
      isOpen={isOpenDelete}
      onClose={onCloseDelete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Training Programs</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Are you sure to delete these Training Programs?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={handleDelete}
            isDisabled={isSubmitting}
          >
            {isSubmitting ? (
              <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
            ) : (
              "Delete"
            )}
          </Button>
          <Button onClick={onCloseDelete} isDisabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function TrainingProgramList() {
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const { initialRef: initialRefAdd, finalRef: finalRefAdd } = useRef(null);
  const { initialRef: initialRefDelete, finalRef: finalRefDelete } =
    useRef(null);
  const [TrainingProgramData, setTrainingProgramData] = useState([]);
  const [loadForm, setloadForm] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  useEffect(() => {
    if(!loadForm){
      const token = localStorage.getItem("token");
      if (token) {
        const fetchData = async () => {
          const response = await GET_LIST_TRAINING_PROGRAM(token);
          if (response.isSuccess) {
            setTrainingProgramData(response.data);
            setloadForm(true);
          } else {
            setloadForm(true);
          }
        };
        fetchData();
      }
    }
  }, [loadForm]);

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <div>
        <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
          <Heading>Training Program</Heading>
          <Flex>
            {localStorage.getItem("role") === "SUAdmin" ? (
              <Button
                onClick={onOpenDelete}
                size="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginRight="1vh"
                color={"white"}
                bgColor={"red.400"}
                _hover={{ bgColor: "red.500" }}
                isDisabled={countChecked() === 0}
              >
                <Icon fontSize="20px" as={FiTrash2} marginRight={"10px"} />{" "}
                Delete
              </Button>
            ) : null}
            {loadForm ? (
              <div>
                <AddNewProgram
                  isOpenAdd={isOpenAdd}
                  onOpenAdd={onOpenAdd}
                  onCloseAdd={onCloseAdd}
                  initialRefAdd={initialRefAdd}
                  finalRefAdd={finalRefAdd}
                  setloadForm={setloadForm}
                />
                <DeleteTrainingProgram
                  isOpenDelete={isOpenDelete}
                  onOpenDelete={onOpenDelete}
                  onCloseDelete={onCloseDelete}
                  initialRefDelete={initialRefDelete}
                  finalRefDelete={finalRefDelete}
                  data={checkedItems}
                  setloadForm={setloadForm}
                  setCheckedItems={setCheckedItems}
                />
              </div>
            ) : null}
            {localStorage.role === "SUAdmin" ? (
              <div>
                <Button
                  onClick={onOpenAdd}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="10vh"
                >
                  <Icon fontSize="20px" as={MdAdd} /> Add new training program
                </Button>
              </div>
            ) : null}
          </Flex>
        </Flex>
        <Flex marginTop={"5vh"}>
          <TableContainer minWidth={"90%"}>
            {loadForm ? (
              <Table variant="simple" className="Table">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Name</Th>
                    <Th>Code</Th>
                    <Th>Duration</Th>
                    <Th>Created by</Th>
                    <Th>Created at</Th>
                    <Th>Total Class</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {TrainingProgramData &&
                    TrainingProgramData.map((item, index) => (
                      <Tr key={item?.id}>
                        <Td>
                          {localStorage.getItem("role") === "SUAdmin" ? (
                            <Checkbox
                              value={item?.id}
                              isChecked={checkedItems[item?.id]}
                              onChange={handleCheck(item?.id)}
                            >
                              {/* Checkbox label */}
                            </Checkbox>
                          ) : null}
                        </Td>
                        <Td>
                          <Link to={`/training-program/id/${item?.id}`}>
                            <Text
                              color={"#2980a5"}
                              _hover={{
                                color: "rgb(6, 61, 100)",
                              }}
                            >
                              {item?.name}
                            </Text>
                          </Link>
                        </Td>
                        <Td>{item?.code}</Td>
                        <Td>{item?.duration} months</Td>
                        <Td>
                          <Link to={`/user/id/${item?.createdBy?.id}`}>
                            <Text
                              color={"#2980a5"}
                              _hover={{
                                color: "rgb(6, 61, 100)",
                              }}
                            >
                              {item?.createdBy?.fullName}
                            </Text>
                          </Link>
                        </Td>
                        <Td>
                          {format(new Date(item?.createdAt), "dd/MM/yyyy")}
                        </Td>
                        <Td>{item?.totalClass}</Td>
                        <Td>
                          {item?.status === "Active" ? (
                            <Badge variant="solid" colorScheme="green">
                              {item?.status}
                            </Badge>
                          ) : (
                            <Badge variant="solid" colorScheme="red">
                              {item?.status}
                            </Badge>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Icon as={LoadingIcon} w={100} h={100} />
              </Box>
            )}
          </TableContainer>
        </Flex>
      </div>
      {/* </div> */}
    </Box>
  );
}

export function TrainingProgramDetail() {
  const [loadForm, setloadForm] = useState(false);
  const [dataProgram, setDataProgram] = useState({});
  const [dataModule, setDataModule] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenAddModuleIntoTP,
    onOpen: onOpenAddModuleIntoTP,
    onClose: onCloseAddModuleIntoTP,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const { initialRef: initialRefDelete, finalRef: finalRefDelete } =
    useRef(null);
  const {
    initialRef: initialRefAddModuleIntoTP,
    finalRef: finalRefAddModuleIntoTP,
  } = useRef(null);
  const { initialRef: initialRefEdit, finalRef: finalRefEdit } = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const token = localStorage.getItem("token");
  const [checkedItems, setCheckedItems] = useState({});
  const id = useParams().id;
  useEffect(() => {
    if(loadForm === false){
      const fetchData = async () => {
        try {
          const [programResponse] = await Promise.all([
            GET_TRAINING_PROGRAM_DETAIL(token, id),
          ]);

          const programData = await programResponse.res.json();
          if (programResponse.isSuccess) {
            setDataProgram(programData.data);
            setloadForm(true);
          }
        } catch (error) {
          // Xử lý lỗi ở đây
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [loadForm]);

  useEffect(() => {
    if(isPageLoading){
      const fetchData = async () => {
        try {
          const [moduleResponse] = await Promise.all([
            GET_LIST_MODULE_BY_TRAINING_PROGRAM(token, id, `page=${currentPage}`),
          ]);
  
          const moduleData = await moduleResponse.res.json();
  
          if (moduleResponse.isSuccess) {
            setTotalRecords(moduleData.data.totalRecords);
            setDataModule(moduleData.data.listData);
            setCurrentPage(moduleData.data.page);
            setTotalPage(moduleData.data.totalPage);
            setIsPageLoading(false);
          } else if (moduleResponse.res.status === 404) {
            setIsPageLoading(false);
            setDataModule([]);
            setTotalPage(0);
            setCurrentPage(1);
            setTotalPage(0);
          }
        } catch (error) {
          // Xử lý lỗi ở đây
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }
  }, [currentPage, isPageLoading]);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsPageLoading(true);
  };

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <Flex marginBottom={"3vh"} color={"rgb(36, 112, 166)"}>
        <Link to={`/training-program`}>{"<<"} Go back</Link>
      </Flex>
      <div>
        <Box>
          {/* <EditProfile isOpenEdit={isOpenEdit} onOpenEdit={onOpenEdit} onCloseEdit={onCloseEdit} initialRefEdit={initialRefEdit} finalRefEdit={finalRefEdit} data={data} /> */}
          {/* <ChangePassword isOpenChangePassword={isOpenChangePassword} onOpenChangePassword={onOpenChangePassword} onCloseChangePassword={onCloseChangePassword} initialRefChangePassword={initialRefChangePassword} finalRefChangePassword={finalRefChangePassword} /> */}
          <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
            <Heading>Training Program Detail</Heading>
            <Flex>
              {localStorage.role === "SUAdmin" || localStorage.role === "Admin" ?
                (
                    <div>
                      {loadForm ? (
                        <EditTrainingProgram
                          isOpenEdit={isOpenEdit}
                          onOpenEdit={onOpenEdit}
                          onCloseEdit={onCloseEdit}
                          initialRef={initialRefEdit}
                          finalRef={finalRefEdit}
                          data={dataProgram}
                          setloadForm={setloadForm}
                        />
                      ) : null}
                      <Button
                        onClick={onOpenEdit}
                        size="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginRight="1vh"
                        isDisabled={!loadForm}
                      >
                        <Icon fontSize="20px" as={FaRegEdit} />
                      </Button>
                    </div>
                  )
                : null}
              {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                Change password
                            </Button> */}
            </Flex>
          </Flex>
          {loadForm ? (
            <Flex flexWrap="wrap">
              <Stack className="col-6" marginTop="10" spacing={4}>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Name:</b>
                  </Text>
                  <Text as="span">{dataProgram.name}</Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Code:</b>
                  </Text>
                  <Text as="span">{dataProgram.code}</Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="5">
                    <b>Duration:</b>
                  </Text>
                  <Text as="span">{dataProgram.duration} months</Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Created At:</b>
                  </Text>
                  <Text as="span">
                    {format(new Date(dataProgram.createdAt), "dd/MM/yyyy")}
                  </Text>
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Created by:</b>
                  </Text>
                  <Link to={`/user/id/${dataProgram.createdBy.id}`}>
                    <Text as="span">{dataProgram.createdBy.fullName}</Text>
                  </Link>
                </Flex>
              </Stack>
              <Stack className="col-6" marginTop="10" spacing={4}>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Updated by:</b>
                  </Text>
                  {dataProgram.updatedBy === null ? (
                    <Text as="span">N/a</Text>
                  ) : (
                    <Link to={`/user/id/${dataProgram.updatedBy.id}`}>
                      <Text as="span">{dataProgram.updatedBy.fullName}</Text>
                    </Link>
                  )}
                </Flex>
                <Flex alignItems="center" fontSize={"20px"}>
                  <Text as="span" mr="2">
                    <b>Updated at:</b>
                  </Text>
                  {dataProgram.updatedAt === null ? (
                    <Text as="span">N/a</Text>
                  ) : (
                    <Text as="span">
                      {format(new Date(dataProgram.updatedAt), "dd/MM/yyyy")}
                    </Text>
                  )}
                </Flex>
              </Stack>
            </Flex>
          ) : (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              display="flex"
              marginTop={"3vh"}
            >
              <Icon as={LoadingIcon} w={50} h={50} />
            </Flex>
          )}
        </Box>
        <Box marginTop={"5vh"}>
          <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
            <Heading>Modules</Heading>
            <Flex>
              {localStorage.role === "SUAdmin" ? (
                <div>
                  {!isPageLoading ? (
                    <div>
                      <DeleteModuleProgram
                        isOpenDelete={isOpenDelete}
                        onOpenDelete={onOpenDelete}
                        onCloseDelete={onCloseDelete}
                        initialRefDelete={initialRefDelete}
                        finalRefDelete={finalRefDelete}
                        data={checkedItems}
                        setIsPageLoading={setIsPageLoading}
                        setCheckedItems={setCheckedItems}
                      />
                    </div>
                  ) : null}
                  <Button
                    onClick={onOpenDelete}
                    size="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="1vh"
                    isDisabled={countChecked() === 0}
                    bg={"red"}
                    color={"white"}
                  >
                    <Icon
                      fontSize="20px"
                      as={MdFormatListBulletedAdd}
                      mr={"1vh"}
                    />{" "}
                    Delete
                  </Button>
                </div>
              ) : null}
              {localStorage.role === "SUAdmin" ? (
                <div>
                  {!isPageLoading ? (
                    <div>
                      <AddModuleIntoTrainingProgram
                        isOpenAddModuleIntoTP={isOpenAddModuleIntoTP}
                        onOpenAddModuleIntoTP={onOpenAddModuleIntoTP}
                        onCloseAddModuleIntoTP={onCloseAddModuleIntoTP}
                        initialRefAddModuleIntoTP={initialRefAddModuleIntoTP}
                        finalRefAddModuleIntoTP={finalRefAddModuleIntoTP}
                        dataProgram={dataProgram}
                        setIsPageLoading={setIsPageLoading}
                      />
                    </div>
                  ) : null}
                  <Button
                    onClick={onOpenAddModuleIntoTP}
                    size="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="1vh"
                    isDisabled={isPageLoading}
                  >
                    <Icon
                      fontSize="20px"
                      as={MdFormatListBulletedAdd}
                      mr={"1vh"}
                    />{" "}
                    Add Module
                  </Button>
                </div>
              ) : null}
              {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                Change password
                            </Button> */}
            </Flex>
          </Flex>
          {!isPageLoading ? (
            <Flex marginTop={"5vh"}>
              {dataModule.length !== 0 ? (
                <Box>
                  <TableContainer minWidth={"90%"}>
                    <Table variant="simple" className="Table">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>Name</Th>
                          <Th>Code</Th>
                          <Th>Created at</Th>
                          <Th>Created by</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataModule &&
                          dataModule.map((item, index) => (
                            <Tr key={item?.id}>
                              <Td>
                                <Checkbox
                                  value={item?.id}
                                  isChecked={checkedItems[item?.id]}
                                  onChange={handleCheck(item?.id)}
                                >
                                  {/* Checkbox label */}
                                </Checkbox>
                              </Td>
                              <Td>
                                <Link to={`/module/id/${item?.id}`}>
                                  <Text
                                    color={"rgb(88, 168, 168)"}
                                    _hover={{
                                      color: "rgb(88, 135, 168)",
                                    }}
                                  >
                                    {item?.name}
                                  </Text>
                                </Link>
                              </Td>
                              <Td>{item?.code}</Td>
                              <Td>
                                {format(
                                  new Date(item?.createdAt),
                                  "dd/MM/yyyy"
                                )}
                              </Td>
                              <Td>
                                <Link to={`/user/id/${item?.createdBy.id}`}>
                                  <Text as="span">{item?.createdBy.name}</Text>
                                </Link>
                              </Td>
                              <Td>{item?.status}</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Box></Box>
                    <Flex>
                      <Paginator
                        first={currentPage}
                        rows={10}
                        totalRecords={totalRecords}
                        onPageChange={onPageChange}
                        template={{
                          layout: "PrevPageLink PageLinks NextPageLink",
                        }}
                        className="p-mt-4"
                      />
                    </Flex>
                    <Flex>
                      <Box
                        marginTop={"2vh"}
                        marginRight={"15vh"}
                        bg="rgb(225, 232, 237)"
                        w="100px"
                        display={"flex"}
                        borderRadius={20}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text>
                          {currentPage} of {totalPage}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  marginBottom={"5vh"}
                >
                  <Text fontSize={"20px"}>
                    <i>No modules</i>
                  </Text>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              display="flex"
              marginTop={"3vh"}
            >
              <Icon as={LoadingIcon} w={50} h={50} />
            </Flex>
          )}
        </Box>
      </div>
    </Box>
  );
}
