import {
    Box,
    Flex,
    Heading,
    Text,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    useToast,
    useDisclosure,
    Checkbox,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    ButtonGroup,
    Badge,
    Progress,
    Grid,
    Divider,
    Stack,
    Center,
    Container,
    Avatar,
    Slide,
    CloseButton,
    HStack,
    Portal,
  } from "@chakra-ui/react";

import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useRef } from 'react';
import { MdDeleteForever } from "react-icons/md";
import { ref, uploadBytesResumable, getDownloadURL, getMetadata, listAll } from "firebase/storage";
import storage from '../../config/firebaseConfig';
import { Paginator } from 'primereact/paginator';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import '../../css/InputFile.css';
import { FiUpload } from "react-icons/fi";
import { FaRegFileLines, FaLock } from "react-icons/fa6";
import '../../css/Option.css';
import parse from 'html-react-parser';
import { format, set } from "date-fns";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { GET_ALL_RECEIVED_TICKET, GET_ALL_RECIPIENTS_TICKET, GET_ALL_SENT_TICKET, GET_TICKET_DETAIL, REPLY_TICKET, SEND_NEW_TICKET } from "../../api/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import logoutAccount from "../FnGeneral";
import { Navigate } from "react-router-dom";

function RichTextEditor() {
    const [content, setContent] = useState('');

    const handleChange = (value) => {
        console.log(value);
        setContent(value);
    };

    const handleSave = () => {
        // Lưu nội dung HTML vào cơ sở dữ liệu của bạn
        saveToDatabase(content);
    };

    const saveToDatabase = (htmlContent) => {
        // Trong đây, bạn có thể gửi dữ liệu HTML (htmlContent) đến API hoặc cơ sở dữ liệu của bạn để lưu trữ
        console.log('Đã lưu vào cơ sở dữ liệu:', htmlContent);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
        ]
    };

    return (
        <Box>
            <Box
                mb={10}
            >
                <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={handleChange}
                    modules={modules}
                    placeholder="Write here..."
                    scrollingContainer={true}
                    style={{height: "20vh", width: "50%"}}
                    
                />
            </Box>
            <Box>
                <Button onClick={handleSave}>Lưu</Button>
            </Box>
        </Box>
    );
}

export function AllTicket() {
    const [isLoadingTicket, setIsLoadingTicket] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [TiketData, setTicketData] = useState([]);
    const [toUserData, setToUserData] = useState([]);
    const { isOpen, onToggle } = useDisclosure();
    const quillRef = useRef(null);
    const [content, setContent] = useState('');
    const {isOpen: isOpenPop, onOpen: onOpenPop, onClose: onClosePop} = useDisclosure();
    const [isLoadingConversation, setIsLoadingConversation] = useState(false);
    const [ticketConversation, setTicketConversation] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [contentReply, setContentReply] = useState('');
    const [isReplying, setIsReplying] = useState(false);

    const handleEditorChange = (content, delta, source, editor) => {
        setContent(content);
    };

    const handleReplyChange = (content, delta, source, editor) => {
        setContentReply(content);
    };

    function handleReplies() {
        setIsReplying(true);
        setIsLoadingConversation(true);
        const token = localStorage.getItem('token');
        const fetchData = async () => {
            try {
                // Gọi API REPLY_TICKET trước
                const { isSuccess: isSuccessReply, res: resReply } = await REPLY_TICKET(token, selectedTicket.id, contentReply);
                if(isSuccessReply) {
                    const {isSuccess: isSuccessGet, res: resGet} = await GET_TICKET_DETAIL(token, selectedTicket.id);
                    if(isSuccessGet) {
                        const response = await resGet.json();
                        setTicketConversation(response.data);
                        setContentReply('');
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setIsReplying(false);
                setIsLoadingConversation(false);
            }
        }
        fetchData();
    }

    const formik = useFormik({
        initialValues: {
            recipientEmail: "",
            subject: ""
        },
        validationSchema: Yup.object({
            recipientEmail: Yup.string().email("Invalid email address").required("Required"),
            subject: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append("subject", values["subject"]);
            formData.append("content", content);
            formData.append("files", selectedFile);
            console.log(selectedFile);
            const fetchData = async () => {
                try {
                    const { isSuccess, res } = await SEND_NEW_TICKET(token, values["recipientEmail"], formData);
                    if(isSuccess) {
                        setIsLoadingTicket(true);
                        handleCloseSlide();
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        }
    });

    useEffect(() => {
        if(isOpen) {
            const token = localStorage.getItem('token');
            const fetchData = async () => {
                try {
                    const { isSuccess, res } = await GET_ALL_RECIPIENTS_TICKET(token);
                    if(isSuccess) {
                        const response = await res.json();
                        setToUserData(response.data);
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        }
    },[isOpen]);

    useEffect(() => {
        if(isLoadingTicket) {
            const token = localStorage.getItem('token');
            const request = `page=${currentPage}`
            const fetchData = async () => {
                try {
                    if(localStorage.role === "Student"){
                        const { isSuccess, res } = await GET_ALL_SENT_TICKET(token, request);
                        if(isSuccess) {
                            const response = await res.json();
                            setIsLoadingTicket(false);
                            setTotalRecords(response.data.totalRecords);
                            setTicketData(response.data.listData);
                        } else if(res.status === 401) {
                            logoutAccount();
                            Navigate("/login");
                        } else if(res.status === 404) {
                            setIsLoadingTicket(false);
                            setTicketData([]);
                        }
                    } else {
                        const { isSuccess, res } = await GET_ALL_RECEIVED_TICKET(token, request);
                        if(isSuccess) {
                            const response = await res.json();
                            setIsLoadingTicket(false);
                            setTotalRecords(response.data.totalRecords);
                            setTicketData(response.data.listData);
                        } else if(res.status === 401) {
                            logoutAccount();
                            Navigate("/login");
                        } else if(res.status === 404) {
                            setIsLoadingTicket(false);
                            setTicketData([]);
                        }
                    
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        }
    },[isLoadingTicket]);

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength - 3) + '...';
        } else {
            return text;
        }
    }

    function extractTextFromHTML(htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.childNodes[0].textContent || " ";
    }

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],[{ 'align': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ]
    };

    const handleCloseSlide = () => {
        formik.resetForm();
        setSelectedFile(null);
        setContent('');
        onClosePop();
        onToggle();
    };

    function handleSeletedTicket(item){
        if(!isLoadingConversation) {
            setSelectedTicket(item);
            setTicketConversation([]);
            setIsLoadingConversation(true);
            const token = localStorage.getItem('token');
            const fetchData = async () => {
                try {
                    const { isSuccess, res } = await GET_TICKET_DETAIL(token, item.id);
                    if(isSuccess) {
                        const response = await res.json();
                        setTicketConversation(response.data);
                    }
                }
                catch (error) {
                    console.log(error);
                } finally {
                    setIsLoadingConversation(false);
                }
            }
            fetchData();
        }
    }

    const onPageChange = (event) => {
        setCurrentPage(event.page + 1);
        setIsLoadingTicket(true);
    };

    const downloadFile = async (filePath, fileName) => {
        try {
          // Get download URL for the file from Firebase Storage
          const fileRef = ref(storage, `${filePath}/${fileName}`); // Create a reference to the file
    
          // Get download URL for the file from Firebase Storage
          const downloadURL = await getDownloadURL(fileRef);
    
          // Now download the file using downloadURL
          // For example, you can use fetch API to download the file
          const response = await fetch(downloadURL);
          if (response.ok) {
            // Convert response to blob
            const blob = await response.blob();
            // Create a link element to download the file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName; // You can set custom file name here
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            console.error("Failed to download file:", response.statusText);
          }
        } catch (error) {
          console.error("Error downloading file:", error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = () => {
        setSelectedFile(null);
        inputRef.current.value = null;
    };

    return (
        <Box
            overflow={"none"}
        >
            <Flex
                marginTop={"15vh"}
                flexWrap={"wrap"}
                justifyContent="space-between" // Để căn giữa theo chiều ngang
                alignItems="center" // Để căn giữa theo chiều dọc
                pr={20}
            >
                <Heading as="h1" size="lg">
                    Ticket
                </Heading>
                {localStorage.role === "Student" ? (
                    <Button onClick={onToggle}>New Ticket</Button>
                ) : null}
            </Flex>
            <Stack
                border={"2px"}
                borderRadius={"10px"}
                borderColor={"#EDF2F7"}
                width="95%"
                height="650px"
                mt={"6vh"}
                direction='row'
                p={0}
            >
                <Box
                    overflowY={"auto"}
                    overflowX={"hidden"}
                    minW={"330px"}
                >
                    <Table
                        minW={"330px"}
                    >
                        <Tbody
                            w={"330px"}
                        >
                            {!isLoadingTicket ? (
                                <div>
                                    {TiketData.length === 0 ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
                                            <Text>No ticket</Text>
                                        </Box>
                                    ) : TiketData.map((item, index) => {
                                        return (
                                            <Tr 
                                                key={index}
                                                w={"330px"}
                                                display="block"
                                                _hover={!isLoadingConversation ? { bg: "#EDF2F7", cursor: "default" } : {cursor: "not-allowed"}}
                                                onClick={() => handleSeletedTicket(item)}
                                                sx={{ userSelect: "none" }}
                                            >
                                                <Td
                                                    w={"330px"}
                                                    display="block"
                                                >
                                                    {localStorage.role === "Student" ? (
                                                        <Heading size={"sm"}>To: {item.recipient.email.split("@")[0]}</Heading>
                                                    ) : (
                                                        <Heading size={"sm"}>From: {item.sender.email.split("@")[0]}</Heading>
                                                    )}
                                                    <Text>{item.subject}<br/>{parse(truncateText(extractTextFromHTML(item.content), 35))}</Text>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </div>
                            ) : (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Icon as={LoadingIcon} w={50} h={50} />
                                </Box>
                            )}
                        </Tbody>
                    </Table>
                </Box>
                <Divider orientation='vertical' borderLeftWidth={2} opacity={1} p="0" maxW={"650px"}/>
                {selectedTicket != null ? (
                    <Container
                        maxW="container.xl"
                        overflowY={"auto"}
                        overflowX={"hidden"}
                        m={0}
                        mt={5}
                        size={"100%"}
                    >
                        <Box>
                            <Heading size={"lg"}>{selectedTicket.subject}</Heading>
                        </Box>
                        <Stack
                            bg="facebookBlue" // You can define a custom color for the header
                            color="white"
                            pt="4"
                        >
                            <Flex justifyContent={"space-between"}>
                                <Flex flexWrap={"wrap"} alignItems={"start"}>
                                    <Avatar size={"md"} src={localStorage.getItem("image")}/>
                                    <Box>
                                        <Flex flexWrap={"wrap"} alignItems={"center"}>
                                            <Text fontSize="md" fontWeight="bold" color="#000" ml={"1vh"}>
                                                {localStorage.role === "Student" ? selectedTicket.createdBy.fullName : selectedTicket.sender.fullName} 
                                            </Text>
                                            <Text fontSize="xs" color="gray.500" ml={"5px"}>
                                                {"<"}{localStorage.role === "Student" ? selectedTicket.createdBy.email : selectedTicket.sender.email}{">"}
                                            </Text>
                                        </Flex>
                                        <Text fontSize="xs" color="#000" ml={"1vh"} sx={{ userSelect: "none" }} title={localStorage.role === "Student" ? selectedTicket.recipient.email.split("@")[0] : "me"}>To: {localStorage.role === "Student" ? selectedTicket.recipient.email.split("@")[0] : "me"}</Text>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Text fontSize="xs" color="gray.500" ml={"5px"}>{format(new Date(selectedTicket.createdAt), "HH:mm dd-MM-yyyy")}</Text>
                                </Flex>
                            </Flex>
                        </Stack>
                        <Box className="Ticket">{selectedTicket ? parse(selectedTicket.content) : null}</Box>
                        {selectedTicket.attachment != null ? (
                            <Box
                                border={"1px"}
                                borderRadius={"10px"}
                                borderColor={"#EDF2F7"}
                                mt={"5vh"}
                                mb={"3vh"}
                            >
                                <Table>
                                    <Tbody>
                                        <Tr>
                                            <Td>
                                                <Flex
                                                    alignItems={"center"}
                                                    onClick={() =>
                                                        downloadFile(
                                                            `Mail_${selectedTicket.id}`,
                                                            selectedTicket.attachment
                                                        )
                                                    }
                                                    color={"#548ed1"}
                                                    _hover={{
                                                    color: "#787878",
                                                    cursor: "pointer",
                                                    }}
                                                >
                                                    <Icon
                                                    as={FaRegFileLines}
                                                    mr={"10px"}
                                                    />
                                                    <Text
                                                        mt={"5px"}
                                                    >
                                                        {selectedTicket.attachment}
                                                    </Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Box>
                        ) : null}
                        <Divider borderLeftWidth={1} opacity={1} p="0" mt={"1vh"} maxW={"100%"}/>
                        {ticketConversation.hasOwnProperty("Replies") ? ticketConversation.Replies.map((item, index) => {
                            console.log(item.sender.email);
                            var isMe = localStorage.role === "Student" ? item.sender.email !== localStorage.email ? "me" : selectedTicket.recipient.email.split("@")[0] : item.sender.email !== localStorage.email ? "me" : selectedTicket.sender.email.split("@")[0];
                            return (
                                <Stack
                                    key={index}
                                    bg="facebookBlue" // You can define a custom color for the header
                                    color="white"
                                    pt="4"
                                >
                                    <Flex justifyContent={"space-between"}>
                                        <Flex flexWrap={"wrap"} alignItems={"start"}>
                                            <Avatar size={"md"} src={null}/>
                                            <Box>
                                                <Flex flexWrap={"wrap"} alignItems={"center"}>
                                                    <Text fontSize="md" fontWeight="bold" color="#000" ml={"1vh"}>
                                                        {item.sender.fullName} 
                                                    </Text>
                                                    <Text fontSize="xs" color="gray.500" ml={"5px"}>
                                                        {"<" + item.sender.email + ">"}
                                                    </Text>
                                                </Flex>
                                                <Text fontSize="xs" color="#000" ml={"1vh"} sx={{ userSelect: "none" }} title={isMe}>To: {isMe}</Text>
                                            </Box>
                                        </Flex>
                                        <Flex>
                                            <Text fontSize="xs" color="gray.500" ml={"5px"}>{format(new Date(item.createdAt), "HH:mm dd-MM-yyyy")}</Text>
                                        </Flex>
                                    </Flex>
                                    <Box className="Ticket" color={"black"}>{parse(item.replyContent)}</Box>
                                    <Divider borderLeftWidth={1} opacity={1} p="0" mt={"1vh"} maxW={"100%"}/>
                                </Stack>
                            );
                        }) : null}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "3vh", marginBottom: "10vh" }}>
                            <ReactQuill
                                ref={quillRef}
                                theme="snow"
                                modules={modules}
                                value={contentReply}
                                placeholder="Write here..."
                                style={{ height: "15vh", width: "calc(100% - 100px)" }}
                                onChange={handleReplyChange}
                            />
                            <Button onClick={handleReplies} isDisabled={isReplying && isLoadingConversation}>{isReplying && isLoadingConversation ? (
                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                            ) : (
                            "Send"
                            )}</Button>
                        </div>
                    </Container>
                ) : (
                    <Container
                        w={"100%"}
                        h={"100%"}
                    >
                        <Flex
                            justifyContent= "center" 
                            alignItems="center"
                            mt={"3vh"}
                        >
                            <Box>
                                <Text w={"100%"}>Click on a ticket to view</Text>        
                            </Box>
                        </Flex>
                    </Container>
                )}
            </Stack>
            <Flex
                justifyContent={"end"}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Slide direction='bottom' in={isOpen} style={{ zIndex: 10, left: "auto" }}>
                        <Box
                            color='white'
                            mb='2'
                            mr='2'
                            bg='#F5F5F5'
                            rounded='md'
                            shadow='md'
                            height='85vh'
                            width='55vh'
                            boxShadow='lg'
                        >
                            <Flex
                                h='5%'
                                bg='#78909C'
                                roundedTopLeft='md'
                                roundedTopRight='md'
                                flexWrap='wrap'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Heading size="sm" ml="1vh">New Ticket</Heading>
                                <Popover
                                    isOpen={isOpenPop}
                                    onClose={onClosePop}
                                >
                                    <PopoverTrigger>
                                        <CloseButton color={"white"} onClick={onOpenPop}/>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <Text color={"#000"}>Are you sure to close the new ticket. Everything will not be saved</Text>
                                    </PopoverBody>
                                    <PopoverFooter>
                                        <Button colorScheme='red' onClick={handleCloseSlide} mr={2}>Yes</Button>
                                        <Button onClick={onClosePop}>No</Button>
                                    </PopoverFooter>
                                    </PopoverContent>
                                </Popover>
                            </Flex>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"10px"}
                            >
                                <Text color={"#607D8B"}>To</Text>
                                    <FormControl>
                                    <Select color={"#000"} name="recipientEmail" placeholder="Select email" value={formik.values.recipientEmail} onChange={formik.handleChange}>
                                        {toUserData.length > 0 ? toUserData.map((item, index) => (
                                            <option key={index} value={item.email} className="multi-line-option">
                                                <Box>
                                                    <strong>{item.name} ({item.role}) - <span>{item.email}</span></strong>
                                                </Box>
                                            </option>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </HStack>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"10px"}
                            >
                                <Text color={"#607D8B"}>Subject</Text>
                                    <FormControl>
                                    <Input onChange={formik.handleChange} color={"#000"} value={formik.values.subject} name="subject"></Input>
                                </FormControl>
                            </HStack>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"10px"}
                                color={"#000"}
                            >
                                <FormControl id="content">
                                    <ReactQuill
                                        ref={quillRef}
                                        theme="snow"
                                        modules={modules}
                                        value={content}
                                        placeholder="Write here..."
                                        style={{height: "28vh", width: "100%"}}
                                        onChange={handleEditorChange}
                                    />
                                    <Input type="hidden" name="content" value={content} /> {/* Trường input ẩn để lưu nội dung của Quill */}
                                </FormControl>
                            </HStack>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"40px"}
                                left={"auto"}
                                color={"#000"}
                                justifyContent={"center"}
                            >
                                <Box display="flex" justifyContent="center">
                                    <Input
                                        type="file"
                                        ref={inputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <Button className="file-btn" onClick={onChooseFile} mt={"5vh"} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                        <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                            <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                        </span>{" "}
                                            Upload File
                                    </Button>
                                </Box>
                            </HStack>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"10px"}
                                left={"auto"}
                                color={"#000"}
                            >
                                {selectedFile && (
                                    <div className="selected-file" style={{width: "100%"}}>
                                    <p>{selectedFile.name}</p>

                                    <button onClick={removeFile}>
                                        <span class="material-symbols-rounded">
                                        <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                                        </span>
                                    </button>
                                    </div>
                                )}
                            </HStack>
                            <HStack
                                spacing={"10px"}
                                pr={"20px"}
                                pl={"20px"}
                                pt={"20px"}
                                pb={"20px"}
                                left={"auto"}
                                color={"#000"}
                                justifyContent={"end"}
                            >
                                <Button type="submit">Send</Button>
                            </HStack>
                        </Box>
                    </Slide>
                </form>
            </Flex>
            <Flex
                flexWrap="wrap"
                justifyContent="start"
            >
                <Flex>
                    <Paginator
                    first={currentPage}
                    rows={10}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                    template={{
                        layout: "PrevPageLink PageLinks NextPageLink",
                    }}
                    className="p-mt-4"
                    />
                </Flex>
            </Flex>
        </Box>
    );
}