import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  useDisclosure,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  Badge,
  Progress,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Card,
  CardBody,
  CardHeader,
  Tab,
  TabPanels,
  Tabs,
  TabList,
  TabPanel,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import Pusher from "pusher-js";
import { format, set } from "date-fns";
import { Paginator } from "primereact/paginator";
import { Icon } from "@chakra-ui/icons";
import { IoSearchOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { HiLockOpen, HiLockClosed } from "react-icons/hi2";
import { MdDeleteForever } from "react-icons/md";
import { FaFileImport } from "react-icons/fa6";
import { PiTimer } from "react-icons/pi";
import "../../css/Text.css";
import "../../css/InputFile.css";
import { MdAdd } from "react-icons/md";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { ref, uploadBytesResumable, getDownloadURL, getMetadata, listAll } from "firebase/storage";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ActiveUser, DeactiveUser } from "../User/Function/UserFunction";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";
import logoutAccount from "../FnGeneral";
import ReactECharts from 'echarts-for-react';
import {
  CREATE_ACCOUNT,
  GET_ALL_STUDENTS,
  GET_LIST_SCORE_OF_STUDENT,
  IMPORT_STUDENT_TO_SYSTEM,
} from "../../api/api";
import storage from '../../config/firebaseConfig';

function ImportStudent({
  isOpenImport,
  onOpenImport,
  onCloseImport,
  initialRefImport,
  finalRefImport,
  setIsLoading,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef(null);
  const [percent, setPercent] = useState(100);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const toast = useToast();

  const closeModal = () => {
    onCloseImport();
    setSelectedFile(null);
    inputRef.current.value = null;
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      const { isSuccess, res } = await IMPORT_STUDENT_TO_SYSTEM(
        localStorage.token,
        formData
      );
      if (isSuccess) {
        const response = await res.json();
        if (response.isSuccess) {
          toast({
            title: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCount(0);
          setTotal(0);
          closeModal();
          setIsLoading(true);
        }
      } else {
        const response = res.json();
        if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        setIsSubmitting(false);
      }
    }
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const removeFile = () => {
    setSelectedFile(null);
    inputRef.current.value = null;
  };

  useEffect(() => {
    // Enable pusher logging - không nên sử dụng trong production
    Pusher.logToConsole = true;

    // Khởi tạo Pusher
    const pusher = new Pusher("a561cc1f29b052bf7808", {
      cluster: "ap1",
    });

    // Subscribe vào channel
    const channel = pusher.subscribe("my-channel");
    // Bind sự kiện 'my-event'
    channel.bind("my-event", function (data) {
      setPercent(data.percent);
      setCount(data.count);
      setTotal(data.total);
    });

    // Hủy đăng ký khi component unmount
    return () => {
      channel.unbind("my-event");
      pusher.disconnect();
    };
  }, []);

  return (
    <Modal
      initialFocusRef={initialRefImport}
      finalFocusRef={finalRefImport}
      isOpen={isOpenImport}
      onClose={onCloseImport}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import students from file</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box display="flex" justifyContent="center">
            <Input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button className="file-btn" onClick={onChooseFile}>
              <span class="material-symbols-rounded">
                <Icon as={FiUpload} fontSize="20px"></Icon>
              </span>{" "}
              Upload File
            </button>
          </Box>
          {selectedFile && (
            <div className="selected-file">
              <p>{selectedFile.name}</p>

              <button onClick={removeFile}>
                <span class="material-symbols-rounded">
                  <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                </span>
              </button>
            </div>
          )}
          <Box marginTop={"10px"}>
            {count !== 0 && total !== 0 ? (
              <Text>Users added: {`${count}/${total}`}</Text>
            ) : null}
            <Box marginTop={"10px"}>
              {isSubmitting ? (
                <Progress
                  hasStripe
                  isAnimated
                  variant="determinate"
                  value={percent}
                  color="primary"
                  borderRadius="full"
                />
              ) : null}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={handleUpload}
            isDisabled={isSubmitting || selectedFile == null}
          >
            {isSubmitting ? (
              <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
            ) : (
              "Upload"
            )}
          </Button>
          <Button onClick={closeModal} isDisabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function AddNewStudent({
  isOpenAdd,
  onOpenAdd,
  onCloseAdd,
  initialRef,
  finalRef,
  setloadForm,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      dob: "",
      address: "",
      gender: "",
      role: "Student",
      phone: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      dob: Yup.date().required("Required"),
      address: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      role: Yup.string().default("Student").required("Required"),
      phone: Yup.string()
        .matches("^(0|\\+84)(\\d{8,9})$", "Wrong phone format")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        console.log(values);
        const response = await CREATE_ACCOUNT(values, token);
        if (response.isSuccess) {
          toast({
            title: "Create account successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseAdd();
          formik.resetForm();
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 7000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  function handleCloseModal() {
    formik.resetForm();
    onCloseAdd();
  }
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenAdd}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New student</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Fullname</FormLabel>
              <Input
                ref={initialRef}
                name="fullName"
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.fullName && formik.touched.fullName && (
              <p style={{ color: "red" }}>{formik.errors.fullName}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input type="text" name="email" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.email && formik.touched.email && (
              <p style={{ color: "red" }}>{formik.errors.email}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Date of birth</FormLabel>
              <Input type="date" name="dob" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.dob && formik.touched.dob && (
              <p style={{ color: "red" }}>{formik.errors.dob}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                type="text"
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.address && formik.touched.address && (
              <p style={{ color: "red" }}>{formik.errors.address}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                placeholder="Select gender"
                onChange={formik.handleChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </FormControl>
            {formik.errors.gender && formik.touched.gender && (
              <p style={{ color: "red" }}>{formik.errors.gender}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Input
                name="role"
                type="text"
                value={"Student"}
                onChange={formik.handleChange}
                isDisabled={true}
              />
            </FormControl>
            {formik.errors.role && formik.touched.role && (
              <p style={{ color: "red" }}>{formik.errors.role}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Phone</FormLabel>
              <Input name="phone" type="text" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.phone && formik.touched.phone && (
              <p style={{ color: "red" }}>{formik.errors.phone}</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseAdd} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function StudentsList() {
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure();
  const {
    isOpen: isOpenDeactive,
    onOpen: onOpenDeactive,
    onClose: onCloseDeactive,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenImport,
    onOpen: onOpenImport,
    onClose: onCloseImport,
  } = useDisclosure();
  const { initialRef: initialRefImport, finalRef: finalRefImport } =
    useRef(null);
  const { initialRefFilter, finalRefFilter } = useRef(null);
  const { initialRef: initialRefAdd, finalRef: finalRefAdd } = useRef(null);
  const { initialRef: initialRefActive, finalRef: finalRefActive } =
    useRef(null);
  const { initialRef: initialRefDeactive, finalRef: finalRefDeactive } =
    useRef(null);
  const [StudentsData, setStudentsData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadForm, setloadForm] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const toast = useToast();
  const formikFilter = useFormik({
    initialValues: {
      gender: filterParams.get("gender") || "",
      status: filterParams.get("status") || "",
      searchValue: filterParams.get("searchValue") || "",
    },
    validationSchema: Yup.object().shape({
      gender: Yup.string().nullable(),
      status: Yup.string().nullable(),
      searchValue: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      for (const param in values) {
        if (values[param]) {
          // Kiểm tra giá trị filterParams[param] có tồn tại không
          filterParams.set(param, values[param]);
        } else {
          filterParams.delete(param);
        }
      }
      if (Object.keys(filterParams.toString()).length === 0) {
        filterParams.forEach((value, key) => {
          filterParams.delete(key);
        });
        formikFilter.setFieldValue("gender", "");
        formikFilter.setFieldValue("status", "");
        formikFilter.setFieldValue("searchValue", "");
        onCloseFilter();
        setIsFilter(false);
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
        navigate(`${location.pathname}`);
      } else {
        navigate(`${location.pathname}?${filterParams.toString()}`);
        onCloseFilter();
        if (
          filterParams.get("gender") !== null ||
          filterParams.get("status") !== null
        ) {
          setIsFilter(true);
        } else {
          setIsFilter(false);
        }
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
      }
    },
  });

  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  useEffect(() => {
    if(!loadForm || isLoading){
      let request = "";
      if (Object.keys(filterParams.toString()).length !== 0) {
        request = filterParams.toString() + `&page=${currentPage}`;
      } else {
        request = `page=${currentPage}&`;
      }
      console.log(request);
      const token = localStorage.getItem("token");
      const fetchData = async () => {
        try {
          const { isSuccess, res } = await GET_ALL_STUDENTS(token, request);
          if (isSuccess) {
            console.log(1);
            const response = await res.json();
            if (response.isSuccess) {
              setTotalRecords(response.data.totalRecords);
              setStudentsData(response.data.listData);
              setCurrentPage(response.data.page);
              setTotalPage(response.data.totalPage);
              setIsLoading(false);
              setloadForm(true);
            }
            //  else if (response.responseFailed != null) {
            //     toast({
            //         title: "Error",
            //         description: response.responseFailed,
            //         status: "error",
            //         duration: 3000,
            //         isClosable: true,
            //     });
            //     filterParams.forEach((value, key) => {
            //         filterParams.delete(key);
            //     })
            //     formikFilter.setFieldValue('gender', '');
            //     formikFilter.setFieldValue('status', '');
            //     onCloseFilter();
            //     setIsFilter(false);
            //     setIsLoading(true);
            //     setloadForm(false);
            //     setCurrentPage(1);
            //     navigate(`${location.pathname}`);
            // } else if (response.message != null && response.data == null) {
            //     setStudentsData([]);
            //     setTotalRecords(0);
            //     setCurrentPage(1);
            //     setIsLoading(false);
            // }
          } else {
            if (res.status === 401) {
              logoutAccount();
              navigate("/login");
            } else if (res.status === 403) {
              navigate("/home");
            } else if (res.status === 400 || res.status === 404) {
              const response = await res.json();
              if (response.responseFailed != null) {
                toast({
                  title: "Error",
                  description: response.responseFailed,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                filterParams.forEach((value, key) => {
                  filterParams.delete(key);
                });
                formikFilter.setFieldValue("gender", "");
                formikFilter.setFieldValue("status", "");
                onCloseFilter();
                setIsFilter(false);
                setIsLoading(true);
                setloadForm(false);
                setCurrentPage(1);
                navigate(`${location.pathname}`);
              } else if (response.message != null && response.data == null) {
                setStudentsData([]);
                setTotalRecords(0);
                setCurrentPage(1);
                setIsLoading(false);
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [loadForm, isLoading]);

  function handleClearFilter() {
    filterParams.forEach((value, key) => {
      if (key === "gender" || key === "status") {
        filterParams.delete(key);
      }
    });
    formikFilter.setFieldValue("gender", "");
    formikFilter.setFieldValue("status", "");
    console.log(filterParams.get("gender"));
    navigate(`${location.pathname}?${filterParams.toString()}`);
    setIsLoading(true);
    setCurrentPage(1);
    onCloseFilter();
    setIsFilter(false);
    setloadForm(false);
  }

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsLoading(true);
    setloadForm(false);
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <div>
        <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
          <Heading>Student</Heading>
          <Flex>
            <form onSubmit={formikFilter.handleSubmit}>
              <InputGroup>
                <Input
                  name="searchValue"
                  type="text"
                  border="1px"
                  borderColor="#3BABE0"
                  placeholder="Search..."
                  value={formikFilter.values.searchValue}
                  onChange={formikFilter.handleChange}
                  width={"30vh"}
                  height={"41.5px"}
                  marginRight={"1.1vh"}
                  borderRadius={"6.5px"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    display={"flex"}
                    variant={"ghost"}
                    marginRight={"19px"}
                    type="submit"
                  >
                    <Icon
                      borderRadius="1px"
                      fontSize="20px"
                      color={"#3BABE0"}
                      as={IoSearchOutline}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </form>
            <Popover
              initialFocusRef={initialRefFilter}
              finalFocusRef={finalRefFilter}
              isOpen={isOpenFilter}
              // onClose={handleCloseModal}
            >
              <PopoverTrigger>
                <Button
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"5vh"}
                  bg={"#3BABE0"}
                  _hover={{
                    boxShadow: "xl",
                  }}
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                >
                  {isFilter ? (
                    <Icon fontSize="20px" as={RiFilterFill} />
                  ) : (
                    <Icon fontSize="20px" as={RiFilterLine} />
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                />
                <PopoverHeader>Filter option</PopoverHeader>
                <PopoverBody>
                  <form onSubmit={formikFilter.handleSubmit}>
                    <FormControl>
                      <FormLabel>Gender</FormLabel>
                      <Select
                        name="gender"
                        value={formikFilter.values.gender}
                        onChange={formikFilter.handleChange}
                        placeholder="Select gender"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Status</FormLabel>
                      <Select
                        name="status"
                        value={formikFilter.values.status}
                        onChange={formikFilter.handleChange}
                        placeholder="Select status"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Select>
                    </FormControl>
                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pt={4}
                    >
                      <Box></Box>
                      <ButtonGroup size="sm">
                        {/* <FilterButton filterParams={{ gender: genderFilter, status: statusFilter }} setloadForm={setloadForm} setGenderFilter={setGenderFilter} setStatusFilter={setStatusFilter}/> */}
                        {isFilter ? (
                          <Button
                            colorScheme="red"
                            color={"white"}
                            size={"md"}
                            onClick={handleClearFilter}
                            marginRight={"10px"}
                          >
                            Clear
                          </Button>
                        ) : null}
                        <Button
                          bgColor={"rgb(99, 122, 138);"}
                          color={"white"}
                          size={"md"}
                          type="submit"
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </form>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {localStorage.role === "SUAdmin" ? (
              <Flex>
                <AddNewStudent
                  isOpenAdd={isOpenAdd}
                  onOpenAdd={onOpenAdd}
                  onCloseAdd={onCloseAdd}
                  initialRefAdd={initialRefAdd}
                  finalRefAdd={finalRefAdd}
                  setloadForm={setloadForm}
                />
                <ActiveUser
                  isOpenActive={isOpenActive}
                  onOpenActive={onOpenActive}
                  onCloseActive={onCloseActive}
                  initialRefActive={initialRefActive}
                  finalRefActive={finalRefActive}
                  data={checkedItems}
                  setIsLoading={setIsLoading}
                  setCheckedItems={setCheckedItems}
                />
                <DeactiveUser
                  isOpenDeactive={isOpenDeactive}
                  onOpenDeactive={onOpenDeactive}
                  onCloseDeactive={onCloseDeactive}
                  initialRefDeactive={initialRefDeactive}
                  finalRefDeactive={finalRefDeactive}
                  data={checkedItems}
                  setIsLoading={setIsLoading}
                  setCheckedItems={setCheckedItems}
                />
                <Button
                  onClick={onOpenActive}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"15vh"}
                  bgColor={"green.400"}
                  _hover={{
                    bgColor: "green.300",
                    boxShadow: "xl",
                  }}
                  isDisabled={countChecked() === 0}
                >
                  <Icon fontSize="20px" as={HiLockOpen} marginRight={"10px"} />{" "}
                  Activate
                </Button>
                <Button
                  onClick={onOpenDeactive}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"15vh"}
                  bgColor={"red.400"}
                  _hover={{
                    bgColor: "red.300",
                    boxShadow: "xl",
                  }}
                  isDisabled={countChecked() === 0}
                >
                  <Icon
                    fontSize="20px"
                    as={HiLockClosed}
                    marginRight={"10px"}
                  />{" "}
                  Deactivate
                </Button>
              </Flex>
            ) : null}

            {localStorage.role === "SUAdmin" ||
            localStorage.role === "Admin" ? (
              <Flex>
                <Button
                  onClick={onOpenImport}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  minWidth={"20vh"}
                  bg={"#4FC0FE"}
                  color={"#fff"}
                  _hover={{ bg: "" }}
                >
                  <ImportStudent
                    isOpenImport={isOpenImport}
                    onOpenImport={onOpenImport}
                    onCloseImport={onCloseImport}
                    initialRefImport={initialRefImport}
                    finalRefImport={finalRefImport}
                    setIsLoading={setIsLoading}
                  />
                  <Icon
                    fontSize="20px"
                    as={FaFileImport}
                    marginRight={"10px"}
                  />{" "}
                  Import student
                </Button>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/fams-69dd8.appspot.com/o/TestImportStudent.xlsx?alt=media&token=e18f574f-9a24-4ac5-aa5a-63c0aaedaba0"
                  download={true}
                >
                  <Button
                    size="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="1vh"
                    minWidth={"20vh"}
                    bg={"#44E0A3"}
                    color={"#fff"}
                  >
                    <Icon
                      fontSize="20px"
                      as={FaFileImport}
                      marginRight={"10px"}
                    />{" "}
                    Download template import
                  </Button>
                </a>
                <Button
                  onClick={onOpenAdd}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="10vh"
                  _hover={{
                    boxShadow: "xl",
                  }}
                >
                  <Icon fontSize="20px" as={MdAdd} /> Add new student
                </Button>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        {!isLoading ? (
          <div>
            {StudentsData.length !== 0 ? (
              <Box>
                <Flex marginTop={"5vh"}>
                  <TableContainer minWidth={"90%"}>
                    <Table variant="simple" className="Table">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>Name</Th>
                          <Th>Email</Th>
                          <Th>Address</Th>
                          <Th>Dob</Th>
                          <Th>Gender</Th>
                          <Th>Phone</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {StudentsData &&
                          StudentsData.map((item, index) => (
                            <Tr key={item?.id}>
                              <Td>
                                {localStorage.getItem("role") === "SUAdmin" ? (
                                  <Checkbox
                                    key={item?.id}
                                    value={item?.id}
                                    isChecked={checkedItems[item?.id]}
                                    onChange={handleCheck(item?.id)}
                                  >
                                    {/* Checkbox label */}
                                  </Checkbox>
                                ) : null}
                              </Td>
                              <Td>
                                <Link to={`/user/id/${item?.id}`}>
                                  <Text
                                    color={"#2980a5"}
                                    _hover={{
                                      color: "rgb(6, 61, 100)",
                                    }}
                                  >
                                    {item?.fullName}
                                  </Text>
                                </Link>
                              </Td>
                              <Td>{item?.email}</Td>
                              <Td>{item?.address}</Td>
                              <Td>
                                {format(new Date(item?.dob), "dd/MM/yyyy")}
                              </Td>
                              <Td>{item?.gender}</Td>
                              <Td>{item?.phone}</Td>
                              <Td>
                                {item?.status === "Active" ? (
                                  <Badge variant="solid" colorScheme="green">
                                    {item?.status}
                                  </Badge>
                                ) : (
                                  <Badge variant="solid" colorScheme="red">
                                    {item?.status}
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Box></Box>
                  <Flex>
                    <Paginator
                      first={currentPage}
                      rows={10}
                      totalRecords={totalRecords}
                      onPageChange={onPageChange}
                      template={{
                        layout: "PrevPageLink PageLinks NextPageLink",
                      }}
                      className="p-mt-4"
                    />
                  </Flex>
                  <Flex>
                    <Box
                      marginTop={"2vh"}
                      marginRight={"15vh"}
                      bg="rgb(225, 232, 237)"
                      w="100px"
                      display={"flex"}
                      borderRadius={20}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text>
                        {currentPage} of {totalPage}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                marginBottom={"5vh"}
                marginTop={"5vh"}
              >
                <Text fontSize={"20px"}>
                  <i>No record match</i>
                </Text>
              </Flex>
            )}
          </div>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={"5vh"}
            marginRight={"18vh"}
          >
            <Icon as={LoadingIcon} w={100} h={100} />
          </Box>
        )}
      </div>
      {/* </div> */}
    </Box>
  );
}

export function StudentScoreDetail({isOpenStudentScore, onOpenStudentScore, onCloseStudentScore, initialRefStudentScore, finalRefStudentScore, student}) {
  const [isLoadingMyCcore, setIsLoadingMyCcore] = useState(true);
  const [myScoreData, setMyScoreData] = useState({});
  const [dataChart, setDataChart] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [dataPie, setDataPie] = useState([
    { 
      value: 0, 
      name: 'Not submitted', 
      itemStyle: { 
        color: "#FFCDD2"
      }
    },
    { 
      value: 0, 
      name: 'Not graded',
      itemStyle: { 
        color: "#FFF9C4"
      } 
    },
    { 
      value: 0, 
      name: 'Graded',
      itemStyle: { 
        color: "#C8E6C9"
      } 
    }
  ]);
  var option = {
      title: {
          text: `Overall score of ${!isLoadingMyCcore ? student.name : null}`,
          left: 'center'
      },
      color: ["#3398DB"],
      tooltip: {
          trigger: "axis",
          axisPointer: {
              type: "shadow"
          }
      },
      grid: {},
      xAxis: [{
          type: "category",
          data: ['0.00 - 1.00', '1.00 - 2.00', '2.00 - 3.00', '3.00 - 4.00', '4.00 - 5.00', '5.00 - 6.00', '6.00 - 7.00', '7.00 - 8.00', '8.00 - 9.00', '9.00 - 10.00'],
          axisLabel: {
              rotate: 45 
          }
      }],
      yAxis: [{
          type: "value"
      }],
      series: [{
          name: "Assignments",
          type: "bar",
          barWidth: "50%",
          data: dataChart
      }]
  }

  var optionPie = {
    title: {
      text: `Assignments status of ${!isLoadingMyCcore ? student.name : null}`,
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      bottom: 10,
      left: 'center',
      data: [
        {
          name: 'Not submitted', 
          itemStyle: { 
            color: "#FFCDD2"
          }
        },
        {
          name: 'Not graded', 
          itemStyle: { 
            color: "#FFF9C4"
          }
        },
        {
          name: 'Graded', 
          itemStyle: { 
            color: "#C8E6C9"
          }
        }
      ]
    },
    series: [
      {
        type: 'pie',
        radius: '65%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data: dataPie,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  useEffect(() => {
    if(isOpenStudentScore && isLoadingMyCcore) {
      const token = localStorage.getItem('token');
      const fetchData = async () => {
        try {
          const { isSuccess, res } = await GET_LIST_SCORE_OF_STUDENT(token, student.id);
          if(isSuccess) {
            const response = await res.json();
            if(response.isSuccess) {
              setMyScoreData(response.data);
              if(response.data != null) {
                countScore(response.data);
                countAssignmentStatus(response.data);
              }
              setIsLoadingMyCcore(false);
            }
          }
        }
        catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }
  },[isOpenStudentScore]);

  function countScore(data) {
    var dataChartTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    data.studentScores.forEach((dataModule) => {
      dataModule.assignments.forEach((dataAssignment) => {
        if(dataAssignment.status === "Graded") {
          if(dataAssignment == null){
            dataChartTemp[0] += 1;
          } else if(dataAssignment.score === 10) {
            dataChartTemp[9] += 1;
          } else {
            dataChartTemp[Math.floor(dataAssignment.score)] += 1;
          }
        }
      });
    });
    setDataChart(dataChartTemp);
  }

  function countAssignmentStatus(data) {
    var dataPieTemp = [
      { 
        value: 0, 
        name: 'Not submitted', 
        itemStyle: { 
          color: "#FFCDD2"
        }
      },
      { 
        value: 0, 
        name: 'Not graded',
        itemStyle: { 
          color: "#FFF9C4"
        } 
      },
      { 
        value: 0, 
        name: 'Graded',
        itemStyle: { 
          color: "#C8E6C9"
        } 
      }
    ];
    data.studentScores.forEach((dataModule) => {
      dataModule.assignments.forEach((dataAssignment) => {
        if(dataAssignment.status === "Not submitted") {
          dataPieTemp[0].value += 1;
        } else if(dataAssignment.status === "Not graded") {
          dataPieTemp[1].value += 1;
        } else {
          dataPieTemp[2].value += 1;
        }
      });
    });
    setDataPie(dataPieTemp);
  }

  const handleCloseModal = () => {
    setIsLoadingMyCcore(true);
    onCloseStudentScore();
  }

  return (
    <Modal
        initialFocusRef={initialRefStudentScore}
        finalFocusRef={finalRefStudentScore}
        isOpen={isOpenStudentScore}
        onClose={handleCloseModal}
        size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent>
          <ModalHeader>{isOpenStudentScore ? student.name : null}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          {!isLoadingMyCcore ? (
            <Box>
              <Tabs isFitted variant="enclosed">
                <TabList mb="1em">
                  <Tab id={"Admin"}>Scores</Tab>
                  <Tab value={"Trainer"}>Assignments status</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box 
                      mt={"5vh"}
                    >
                      <ReactECharts
                        option={option}
                        style={{ height: '400px', width: '100%' }}
                        opts={{ renderer: 'canvas', useDirtyRect: false }}
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box 
                      mt={"5vh"}
                    >
                      <ReactECharts
                          option={optionPie}
                          style={{ height: '400px', width: '100%' }}
                          opts={{ renderer: 'canvas', useDirtyRect: false }}
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Accordion allowMultiple mt={"5vh"}>
                  {myScoreData.studentScores.map((item, index) => (
                    <AccordionItem key={index}>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {item.name} - {item.code}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Heading size={"lg"}>
                          Avg. Score: {item.moduleAvgScore}/10
                        </Heading>
                        {item.assignments.map((assignment, index) => (
                          <Box>
                            <Card key={index} mt={4}>
                              <CardHeader>
                                <Flex
                                  flexWrap={"wrap"}
                                  justifyContent={"space-between"}
                                >
                                  <Box>
                                    <Flex 
                                      flexWrap={"wrap"}
                                      alignItems={"center"}
                                    >
                                      <Heading 
                                        size="md"
                                        color={"#548ed1"}
                                        _hover={{
                                          color: "#787878",
                                          cursor: "pointer"
                                        }}
                                      >
                                        {assignment?.title}
                                      </Heading>
                                    </Flex>
                                  </Box>
                                </Flex>
                                <Box mt={"2px"}>
                                  <Flex flexWrap={"wrap"} alignItems={"center"}>
                                    <Icon
                                      as={PiTimer}
                                      w={4}
                                      h={4}
                                      color={"#787878"}
                                      mr={"5px"}
                                    />
                                    <Box mt={"5px"}>
                                      <Text as="span" fontSize={"15px"}>
                                        {format(new Date(assignment?.createdAt),"dd/MM/yyyy")}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              </CardHeader>
                              <CardBody>
                                <Text>{assignment?.content}</Text>
                                <Flex flexWrap={"wrap"} alignItems={"center"}>
                                  <TableContainer>
                                      <Table variant="simple" style={{width: "100%", tableLayout: "fixed"}}>
                                          <Tbody>
                                            <Tr
                                              bg={assignment.status === "Graded" ? assignment?.score >= 4 ? "green.100" : "red.100" : assignment.status === "Not graded" ? "yellow.100" : "red.100"}
                                            >
                                                <Td>
                                                    Grade
                                                </Td>
                                                <Td>
                                                    {assignment.status === "Graded" ? `${assignment.score}/10` : assignment.status}
                                                </Td>
                                            </Tr>
                                          </Tbody>
                                      </Table>
                                  </TableContainer>
                                </Flex>
                              </CardBody>
                            </Card>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Box>
            ) : (
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                display="flex"
                marginTop={"3vh"}
              >
                <Icon as={LoadingIcon} w={50} h={50} />
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
          <Button onClick={handleCloseModal}>
              Close
          </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
