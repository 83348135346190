// Import App Route
import React, { useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import RoutesApp from "./routes";
import { StateContext } from "./stateContext.js";
import "primereact/resources/themes/lara-light-cyan/theme.css";
// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  const { loadForm, updateLoadForm } = useContext(StateContext);

  useEffect(() => {
    // Kiểm tra token tại đây
    function isTokenExpired(token) {
      try{
        const decodedToken = jwtDecode(token);
        if (!decodedToken.hasOwnProperty('exp')) {
            return false; // JWT không có trường exp, không xác định được hạn của token
        }

        const expirationTime = new Date(decodedToken.exp).getTime();
        const currentTime = new Date().getTime();

        return expirationTime < currentTime;
      } catch(e){
        console.log(e);
      }
    }
    const token = localStorage.getItem("token");
    if (token === null || !isTokenExpired(token)) {
      // Nếu token không hợp lệ, thực hiện các hành động tương ứng (chẳng hạn chuyển hướng đến trang đăng nhập)
      // Ví dụ:
      updateLoadForm(false); // Thực hiện cập nhật state để render lại ứng dụng (nếu cần)
      // Chuyển hướng đến trang đăng nhập
      // history.push("/login"); // Bạn cần sử dụng hook useHistory hoặc thư viện React Router để chuyển hướng
    }
  }, []);
  return (
    <ChakraProvider>
        <RoutesApp />
    </ChakraProvider>
  );
}

export default App;
