import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { ReactComponent as LoadingIcon } from '../../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../../icons/loading-logo.svg";
import { UPDATE_STATUS_USERS_IN_BATCH } from "../../../api/api";

export function ActiveUser({ isOpenActive, onOpenActive, onCloseActive, initialRefActive, finalRefActive, data, setIsLoading, setCheckedItems}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userIds = [];
    for(const key in data) {
        if(data[key]) {
            const id = key.split(':')[0].trim();
            userIds.push(id);
        }
    }
    
    const jsonData = {
        userId: userIds,
        status: "Active"
    }

    const handleActive = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await UPDATE_STATUS_USERS_IN_BATCH(token, jsonData);
                if (response.isSuccess) {
                    onCloseActive();
                    toast({
                        title: `Active ${jsonData.length} user(s) successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setCheckedItems({});
                    setIsLoading(true);
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    return (
        <Modal
            initialFocusRef={initialRefActive}
            finalFocusRef={finalRefActive}
            isOpen={isOpenActive}
            onClose={onCloseActive}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Active users</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    Are you sure to active these users?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={handleActive} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Activate"}</Button>
                    <Button onClick={onCloseActive} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export function DeactiveUser({ isOpenDeactive, onOpenDeactive, onCloseDeactive, initialRefDeactive, finalRefDeactive, data, setIsLoading, setCheckedItems}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userIds = [];
    for(const key in data) {
        if(data[key]) {
            const id = key.split(':')[0].trim();
            userIds.push(id);
        }
    }

    const jsonData = {
        userId: userIds,
        status: "Inactive"
    }

    const handleActive = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await UPDATE_STATUS_USERS_IN_BATCH(token, jsonData);
                if (response.isSuccess) {
                    onCloseDeactive();
                    toast({
                        title: `Deactive ${jsonData.length} user(s) successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setCheckedItems({});
                    setIsLoading(true);
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    return (
        <Modal
            initialFocusRef={initialRefDeactive}
            finalFocusRef={finalRefDeactive}
            isOpen={isOpenDeactive}
            onClose={onCloseDeactive}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Deactivate users</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    Are you sure to deactivate these users?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={handleActive} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Deactivate"}</Button>
                    <Button onClick={onCloseDeactive} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}