import {
  Box,
  Flex,
  CircularProgress,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  useDisclosure,
  Checkbox,
  ButtonGroup,
  Badge,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Card,
  CardBody,
  CardHeader,
  StackDivider,
  Divider,
  color,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { format, set } from "date-fns";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { Paginator } from "primereact/paginator";
import { IoSearchOutline } from "react-icons/io5";
import { PiTimer } from "react-icons/pi";
import { FiTrash2 } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";
import { FaRegFileLines, FaLock } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";
import {
  Form,
  Link,
  json,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  GET_CLASSES,
  GET_CLASS_DETAIL,
  DELETE_CLASSES,
  CREATE_CLASS,
  GET_UNASSIGN_STUDENTS,
  ADD_STUDENTS_TO_CLASS,
  GET_LIST_DROP_DOWN_TRAINING_PROGRAM,
  UPDATE_CLASS,
  GET_MODULE_LECTURE_DETAIL,
  CREATE_ATTENDANCE,
  GET_LIST_ATTENDANCE,
  GET_ATTENDANCE,
  GET_CLASS_DETAILS_FOR_STUDENT,
  GET_LIST_ASSIGNMENT,
  GET_MODULE_LECTURE_FOR_STUDENT,
  GET_LIST_SCORE_OF_STUDENT,
  GET_LIST_SCORE_OF_CLASS,
} from "../../api/api";
import logoutAccount from "../FnGeneral";
import storage from "../../config/firebaseConfig";
import { DeleteStudentsFromClass } from "./Function/StudentsClass";
import { ShowAttendance } from "../Attendance/Attendance";
import { CreateAttendance } from "../Attendance/Function/AttendanceFunction";
import { AssignmentDetail, CreateAssignment, EditAssignment } from "../Assignment/Assignment";
import { CreateLecture, DeleteLecture, EditLecture } from "./Function/Lectures";
import { AddNewTrainerIntoClass } from "../Manager/Manager";
import { StudentScoreDetail } from "../Student/Student";

function EditClass({
  isOpenEdit,
  onOpenEdit,
  onCloseEdit,
  initialRef,
  finalRef,
  data,
  setloadForm,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [programData, setProgramData] = useState([]);
  const toast = useToast();

  function formatDateForInput(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (isOpenEdit) {  // Thêm điều kiện kiểm tra khi cửa sổ mở
        const token = localStorage.getItem("token");
        if (token) {
            const fetchData = async () => {
                const response = await GET_LIST_DROP_DOWN_TRAINING_PROGRAM(token);
                if (response.isSuccess) {
                    setProgramData(response.data);
                }
            };
            fetchData();
        }
    }
}, [isOpenEdit]); // Thêm isOpenEdit vào danh sách phụ thuộc

  console.log(data.endAt);
  const formik = useFormik({
    initialValues: {
      name: data.name,
      programId: data.programDetails.id,
      startAt: formatDateForInput(new Date(data.startAt)),
      endAt: formatDateForInput(new Date(data.endAt)),
      location: data.location,
      status: data.status,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      startAt: Yup.date().required("Required"),
      programId: Yup.string().required("Required"),
      endAt: Yup.date()
        .required("Required")
        .when(
          "startAt",
          (startAt, schema) =>
            startAt && schema.min(startAt, "End date must be after start date")
        ),
      location: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setIsSubmitting(true);
      const token = localStorage.getItem("token");
      try {
        const response = await UPDATE_CLASS(token, values, data.id);
        if (response.isSuccess) {
          toast({
            title: "Update Class successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          formik.resetForm();
          setloadForm(false);
          onCloseEdit();
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenEdit}
      onClose={onCloseEdit}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update class</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Training program</FormLabel>
              <Select
                name="programId"
                placeholder="Select Training program"
                value={formik.values.programId}
                onChange={formik.handleChange}
              >
                {programData.map((item, index) => (
                  <option value={item.id} key={item.id}>
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Start at</FormLabel>
              <Input
                type="date"
                name="startAt"
                value={formik.values.startAt}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.startAt && formik.touched.startAt && (
              <p style={{ color: "red" }}>{formik.errors.startAt}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>End at</FormLabel>
              <Input
                type="date"
                name="endAt"
                value={formik.values.endAt}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.endAt && formik.touched.endAt && (
              <p style={{ color: "red" }}>{formik.errors.endAt}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Location</FormLabel>
              <Input
                name="location"
                type="text"
                value={formik.values.location}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                isDisabled={true}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseEdit} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function AddNewClass({
  isOpenAdd,
  onOpenAdd,
  onCloseAdd,
  initialRef,
  finalRef,
  setloadForm,
}) {
  const toast = useToast();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const [programData, setProgramData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function formatDateForInput(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const fetchData = async () => {
        const response = await GET_LIST_DROP_DOWN_TRAINING_PROGRAM(token);
        if (response.isSuccess) {
          setProgramData(response.data);
        }
      };
      fetchData();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      startAt: formatDateForInput(new Date()),
      endAt: formatDateForInput(endDate),
      programId: "",
      location: "",
      status: "Active",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      startAt: Yup.date().required("Required"),
      programId: Yup.string().required("Required"),
      endAt: Yup.date()
        .required("Required")
        .when(
          "startAt", // Tên trường để kiểm tra
          (startAt, schema) =>
            startAt && schema.min(startAt, "End date must be after start date")
        ),
      location: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const token = localStorage.getItem("token");
      try {
        var response = await CREATE_CLASS(token, values);
        if (response.isSuccess) {
          toast({
            title: "Create class successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseAdd();
          formik.resetForm();
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenAdd}
      onClose={onCloseAdd}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New class</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                name="name"
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Training program</FormLabel>
              <Select
                name="programId"
                placeholder="Select Training program"
                onChange={formik.handleChange}
              >
                {programData.map((item, index) => (
                  <option value={item.id} key={item.id}>
                    {item.code}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Start at</FormLabel>
              <Input
                type="date"
                name="startAt"
                value={formik.values.startAt}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.startAt && formik.touched.startAt && (
              <p style={{ color: "red" }}>{formik.errors.startAt}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>End at</FormLabel>
              <Input
                type="date"
                name="endAt"
                value={formik.values.endAt}
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.endAt && formik.touched.endAt && (
              <p style={{ color: "red" }}>{formik.errors.endAt}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Location</FormLabel>
              <Input
                name="location"
                type="text"
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                isDisabled={true}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseAdd} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function DeleteClass({
  isOpenDelete,
  onOpenDelete,
  onCloseDelete,
  initialRefDelete,
  finalRefDelete,
  data,
  setloadForm,
  setCheckedItems,
}) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const jsonData = [];
  for (const key in data) {
    if (data[key]) {
      const id = key.split(":")[0].trim();
      jsonData.push(id);
    }
  }

  const handleDelete = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem("token");
    const jsonSendReq = {
      classId: jsonData,
    };
    if (token) {
      try {
        const response = await DELETE_CLASSES(token, jsonSendReq);
        if (response.isSuccess) {
          onCloseDelete();
          toast({
            title: `Delete ${jsonData.length} class(es) successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCheckedItems({});
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRefDelete}
      finalFocusRef={finalRefDelete}
      isOpen={isOpenDelete}
      onClose={onCloseDelete}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete classes</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>Are you sure to delete these classes?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={handleDelete}
            isDisabled={isSubmitting}
          >
            {isSubmitting ? (
              <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
            ) : (
              "Delete"
            )}
          </Button>
          <Button onClick={onCloseDelete} isDisabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function ClassList() {
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const { initialRef: initialRefAdd, finalRef: finalRefAdd } = useRef(null);
  const { initialRef: initialRefDelete, finalRef: finalRefDelete } =
    useRef(null);
  const [ClassData, setClassData] = useState([]);
  const [loadForm, setloadForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const { initialRefFilter, finalRefFilter } = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();
  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  const formikFilter = useFormik({
    initialValues: {
      StartAt: filterParams.get("StartAt") || "",
      EndAt: filterParams.get("EndAt") || "",
      searchValue: filterParams.get("searchValue") || "",
    },
    validationSchema: Yup.object().shape({
      StartAt: Yup.date().nullable(),
      EndAt: Yup.date().nullable(),
      searchValue: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      for (const param in values) {
        if (values[param]) {
          // Kiểm tra giá trị filterParams[param] có tồn tại không
          filterParams.set(param, values[param]);
        } else {
          filterParams.delete(param);
        }
      }
      if (Object.keys(filterParams.toString()).length === 0) {
        filterParams.forEach((value, key) => {
          filterParams.delete(key);
        });
        formikFilter.setFieldValue("StartAt", "");
        formikFilter.setFieldValue("EndAt", "");
        formikFilter.setFieldValue("searchValue", "");
        onCloseFilter();
        setIsFilter(false);
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
        navigate(`${location.pathname}`);
      } else {
        navigate(`${location.pathname}?${filterParams.toString()}`);
        onCloseFilter();
        if (
          filterParams.get("StartAt") !== null ||
          filterParams.get("EndAt") !== null
        ) {
          setIsFilter(true);
        } else {
          setIsFilter(false);
        }
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
      }
    },
  });

  useEffect(() => {
    let request = "";
    if (Object.keys(filterParams.toString()).length !== 0) {
      request = filterParams.toString() + `&page=${currentPage}`;
    } else {
      request = `page=${currentPage}`;
    }
    console.log(request);
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        const { isSuccess, res } = await GET_CLASSES(token, request);
        if (isSuccess) {
          console.log(1);
          const response = await res.json();
          if (response.isSuccess) {
            setTotalRecords(response.data.totalRecords);
            setClassData(response.data.listData);
            setCurrentPage(response.data.page);
            setTotalPage(response.data.totalPage);
            setIsLoading(false);
            setloadForm(true);
          }
          //  else if (response.responseFailed != null) {
          //     toast({
          //         title: "Error",
          //         description: response.responseFailed,
          //         status: "error",
          //         duration: 3000,
          //         isClosable: true,
          //     });
          //     filterParams.forEach((value, key) => {
          //         filterParams.delete(key);
          //     })
          //     formikFilter.setFieldValue('gender', '');
          //     formikFilter.setFieldValue('status', '');
          //     onCloseFilter();
          //     setIsFilter(false);
          //     setIsLoading(true);
          //     setloadForm(false);
          //     setCurrentPage(1);
          //     navigate(`${location.pathname}`);
          // } else if (response.message != null && response.data == null) {
          //     setStudentsData([]);
          //     setTotalRecords(0);
          //     setCurrentPage(1);
          //     setIsLoading(false);
          // }
        } else {
          if (res.status === 401) {
            logoutAccount();
            navigate("/login");
          } else if (res.status === 403) {
            navigate("/home");
          } else if (res.status === 400 || res.status === 404) {
            const response = await res.json();
            if (response.responseFailed != null) {
              toast({
                title: "Error",
                description: response.responseFailed,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
              filterParams.forEach((value, key) => {
                filterParams.delete(key);
              });
              formikFilter.setFieldValue("StartAt", "");
              formikFilter.setFieldValue("EndAt", "");
              onCloseFilter();
              setIsFilter(false);
              setIsLoading(true);
              setloadForm(false);
              setCurrentPage(1);
              navigate(`${location.pathname}`);
            } else if (response.message != null || response.data == null) {
              setClassData([]);
              setloadForm(true);
              setTotalRecords(0);
              setCurrentPage(1);
              setIsLoading(false);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [loadForm, filterParams.toString(), currentPage, isLoading]);

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  function handleClearFilter() {
    filterParams.forEach((value, key) => {
      if (key === "StartAt" || key === "EndAt") {
        filterParams.delete(key);
      }
    });
    formikFilter.setFieldValue("StartAt", "");
    formikFilter.setFieldValue("EndAt", "");
    navigate(`${location.pathname}?${filterParams.toString()}`);
    setIsLoading(true);
    setCurrentPage(1);
    onCloseFilter();
    setIsFilter(false);
    setloadForm(false);
  }

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsLoading(true);
    setloadForm(false);
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <div>
        <DeleteClass
          isOpenDelete={isOpenDelete}
          onOpenDelete={onOpenDelete}
          onCloseDelete={onCloseDelete}
          initialRefDelete={initialRefDelete}
          finalRefDelete={finalRefDelete}
          data={checkedItems}
          setloadForm={setloadForm}
          setCheckedItems={setCheckedItems}
        />
        <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
          <Heading>Class</Heading>
          <Flex>
            <form onSubmit={formikFilter.handleSubmit}>
              <InputGroup>
                <Input
                  name="searchValue"
                  type="text"
                  border="1px"
                  borderColor="#3BABE0"
                  placeholder="Search..."
                  value={formikFilter.values.searchValue}
                  onChange={formikFilter.handleChange}
                  width={"30vh"}
                  height={"41.5px"}
                  marginRight={"1.1vh"}
                  borderRadius={"6.5px"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    display={"flex"}
                    variant={"ghost"}
                    marginRight={"19px"}
                    type="submit"
                  >
                    <Icon
                      borderRadius="1px"
                      fontSize="20px"
                      color={"#3BABE0"}
                      as={IoSearchOutline}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </form>
            <Popover
              initialFocusRef={initialRefFilter}
              finalFocusRef={finalRefFilter}
              isOpen={isOpenFilter}
              // onClose={handleCloseModal}
            >
              <PopoverTrigger>
                <Button
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"5vh"}
                  bg={"#3BABE0"}
                  _hover={{
                    boxShadow: "xl",
                  }}
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                >
                  {isFilter ? (
                    <Icon fontSize="20px" as={RiFilterFill} />
                  ) : (
                    <Icon fontSize="20px" as={RiFilterLine} />
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                />
                <PopoverHeader>Filter option</PopoverHeader>
                <PopoverBody>
                  <form onSubmit={formikFilter.handleSubmit}>
                    <FormControl>
                      <FormLabel>Start at</FormLabel>
                      <Input
                        name="StartAt"
                        type="Date"
                        value={formikFilter.values.StartAt}
                        onChange={formikFilter.handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>End at</FormLabel>
                      <Input
                        name="EndAt"
                        type="Date"
                        value={formikFilter.values.EndAt}
                        onChange={formikFilter.handleChange}
                      />
                    </FormControl>
                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pt={4}
                    >
                      <Box></Box>
                      <ButtonGroup size="sm">
                        {/* <FilterButton filterParams={{ gender: genderFilter, status: statusFilter }} setloadForm={setloadForm} setGenderFilter={setGenderFilter} setStatusFilter={setStatusFilter}/> */}
                        {isFilter ? (
                          <Button
                            colorScheme="red"
                            color={"white"}
                            size={"md"}
                            onClick={handleClearFilter}
                            marginRight={"10px"}
                          >
                            Clear
                          </Button>
                        ) : null}
                        <Button
                          bgColor={"rgb(99, 122, 138);"}
                          color={"white"}
                          size={"md"}
                          type="submit"
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </form>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Button
              onClick={onOpenDelete}
              size="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginRight="1vh"
              color={"white"}
              bgColor={"red.400"}
              _hover={{ bgColor: "red.500" }}
              isDisabled={countChecked() === 0}
            >
              <Icon fontSize="20px" as={FiTrash2} marginRight={"10px"} /> Delete
            </Button>
            {localStorage.role === "SUAdmin" ? (
              <div>
                <AddNewClass
                  isOpenAdd={isOpenAdd}
                  onOpenAdd={onOpenAdd}
                  onCloseAdd={onCloseAdd}
                  initialRefAdd={initialRefAdd}
                  finalRefAdd={finalRefAdd}
                  setloadForm={setloadForm}
                />
                <Button
                  onClick={onOpenAdd}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="10vh"
                >
                  <Icon fontSize="20px" as={MdAdd} /> Add new class
                </Button>
              </div>
            ) : null}
          </Flex>
        </Flex>
        {loadForm ? (
          <Flex>
            {ClassData.length !== 0 ? (
              <Box>
                <Flex marginTop={"5vh"}>
                  <TableContainer minWidth={"90%"}>
                    <Table variant="simple" className="Table">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>Name</Th>
                          <Th>Start at</Th>
                          <Th>End at</Th>
                          <Th>Created by</Th>
                          <Th>Created at</Th>
                          <Th>Location</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {ClassData &&
                          ClassData.map((item, index) => (
                            <Tr key={index}>
                              <Td>
                                <Checkbox
                                  key={item?.id}
                                  value={item?.id}
                                  isChecked={checkedItems[item?.id]}
                                  onChange={handleCheck(item?.id)}
                                >
                                  {/* Checkbox label */}
                                </Checkbox>
                              </Td>
                              <Td>
                                <Link to={`/class/id/${item?.id}`}>
                                  <Text
                                    color={"#2980a5"}
                                    _hover={{
                                      color: "rgb(6, 61, 100)",
                                    }}
                                  >
                                    {item?.name}
                                  </Text>
                                </Link>
                              </Td>
                              <Td>
                                {format(new Date(item?.startAt), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                {format(new Date(item?.endAt), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                <Link to={`/user/id/${item?.createdBy?.id}`}>
                                  <Text
                                    color={"#2980a5"}
                                    _hover={{
                                      color: "rgb(6, 61, 100)",
                                    }}
                                  >
                                    {item?.createdBy?.name}
                                  </Text>
                                </Link>
                              </Td>
                              <Td>
                                {format(
                                  new Date(item?.createdAt),
                                  "dd/MM/yyyy"
                                )}
                              </Td>
                              <Td>{item?.location}</Td>
                              <Td>
                                {item?.status === "Active" ? (
                                  <Badge variant="solid" colorScheme="green">
                                    {item?.status}
                                  </Badge>
                                ) : (
                                  <Badge variant="solid" colorScheme="red">
                                    {item?.status}
                                  </Badge>
                                )}
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Box></Box>
                  <Flex marginTop={"2vh"}>
                    <Paginator
                      first={currentPage}
                      rows={10}
                      totalRecords={totalRecords}
                      onPageChange={onPageChange}
                      template={{
                        layout: "PrevPageLink PageLinks NextPageLink",
                      }}
                      className="p-mt-4"
                    />
                  </Flex>
                  <Flex>
                    <Box
                      marginTop={"2vh"}
                      marginRight={"15vh"}
                      bg="rgb(225, 232, 237)"
                      w="100px"
                      h={"50px"}
                      display={"flex"}
                      borderRadius={20}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text>
                        {currentPage} of {totalPage}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                marginBottom={"5vh"}
                marginTop={"5vh"}
              >
                <Text fontSize={"20px"}>
                  <i>No record match</i>
                </Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Icon as={LoadingIcon} w={100} h={100} />
          </Box>
        )}
      </div>
      {/* </div> */}
    </Box>
  );
}

function AddStudentIntoClass({
  isOpenAddStudent,
  onOpenAddStudent,
  onCloseAddStudent,
  initialRefAddStudent,
  finalRefAddStudent,
  setloadForm,
}) {
  const navigate = useNavigate();
  const toast = useToast();
  const endDate = new Date();
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);
  endDate.setDate(endDate.getDate() + 30);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const { initialRefFilter, finalRefFilter } = useRef(null);
  const [isFilter, setIsFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [StudentsData, setStudentsData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const classId = useParams().id;

  useEffect(() => {
    if (isOpenAddStudent) {
      const token = localStorage.getItem("token");
      const newCheckedItems = {};
      let requests = []; // Mảng để tích lũy các yêu cầu API

      for (let i = 1; i <= totalPage; i++) {
        let request = `page=${i}`; // Sử dụng tham số i để xác định trang
        requests.push(GET_UNASSIGN_STUDENTS(token, request)); // Gửi yêu cầu API vào mảng
      }

      async function fetchData() {
        setIsLoading(true); // Bắt đầu tải dữ liệu
        const responses = await Promise.all(requests); // Gửi các yêu cầu API cùng một lúc

        // Xử lý dữ liệu trả về từ các yêu cầu API
        responses.forEach((response) => {
          if (response.isSuccess) {
            response.data.listData.forEach((item) => {
              newCheckedItems[item.id] = checkAll;
            });
          }
        });

        // Cập nhật state một lần duy nhất sau khi tất cả dữ liệu đã được xử lý
        setCheckedItems(newCheckedItems);
        setIsLoading(false); // Kết thúc tải dữ liệu
      }

      fetchData(); // Gọi hàm lấy dữ liệu
    }
  }, [checkAll, totalPage, isOpenAddStudent]); // Chỉ kích hoạt lại useEffect khi checkAll hoặc totalPage thay đổi

  useEffect(() => {
    if (isOpenAddStudent) {
      let request = "";
      if (Object.keys(filterParams.toString()).length !== 0) {
        setIsFilter(true);
        request = filterParams.toString() + `&page=${currentPage}`;
      } else {
        request = `page=${currentPage}`;
      }
      const token = localStorage.getItem("token");
      if (token) {
        const fetchData = async () => {
          const response = await GET_UNASSIGN_STUDENTS(token, request);
          if (response.isSuccess) {
            setTotalRecords(response.data.totalRecords);
            setTotalPage(response.data.totalPage);
            setStudentsData(response.data.listData);
            setCurrentPage(response.data.page);
            setIsLoading(false);
          } else if (response.responseFailed != null) {
            toast({
              title: "Error",
              description: response.responseFailed,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            filterParams.forEach((value, key) => {
              filterParams.delete(key);
            });
            formikFilter.setFieldValue("gender", "");
            formikFilter.setFieldValue("status", "");
            onCloseFilter();
            setIsFilter(false);
            setIsLoading(true);
            setloadForm(false);
            setCurrentPage(1);
            navigate(`${location.pathname}`);
          } else if (response.message != null && response.data == null) {
            setStudentsData([]);
            setTotalRecords(0);
            setCurrentPage(1);
            setIsLoading(false);
          }
        };
        fetchData();
      }
    }
  }, [isOpenAddStudent, isLoading]);

  const formikFilter = useFormik({
    initialValues: {
      gender: filterParams.get("gender") || "",
      status: filterParams.get("status") || "",
    },
    validationSchema: Yup.object().shape({
      gender: Yup.string().nullable(),
      status: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      for (const param in values) {
        if (values[param]) {
          // Kiểm tra giá trị filterParams[param] có tồn tại không
          filterParams.set(param, values[param]);
        } else {
          filterParams.delete(param);
        }
      }
      if (Object.keys(filterParams.toString()).length === 0) {
        filterParams.forEach((value, key) => {
          filterParams.delete(key);
        });
        formikFilter.setFieldValue("gender", "");
        formikFilter.setFieldValue("status", "");
        onCloseFilter();
        setIsFilter(false);
        setIsLoading(true);
        setCurrentPage(1);
        navigate(`${location.pathname}`);
      } else {
        navigate(`${location.pathname}?${filterParams.toString()}`);
        onCloseFilter();
        setIsFilter(true);
        setIsLoading(true);
        setCurrentPage(1);
      }
    },
  });

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const handleSaveAddStudentsIntoClass = async () => {
    setIsSubmitting(true);
    setCheckedItems({});
    const token = localStorage.getItem("token");
    const jsonData = [];
    for (const key in checkedItems) {
      if (checkedItems[key]) {
        const id = key.split(":")[0].trim();
        jsonData.push(id);
      }
    }
    const jsonSendReq = {
      studentId: jsonData,
    };
    console.log(jsonSendReq);
    if (token) {
      try {
        const response = await ADD_STUDENTS_TO_CLASS(
          token,
          jsonSendReq,
          classId
        );
        if (response.isSuccess) {
          onCloseAddStudent();
          toast({
            title: `Add ${jsonData.length} student(s) into class successfully`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function handleClearFilter() {
    filterParams.forEach((value, key) => {
      filterParams.delete(key);
    });
    formikFilter.setFieldValue("gender", "");
    navigate(`${location.pathname}`);
    setIsLoading(true);
    setCurrentPage(1);
    onCloseFilter();
    setIsFilter(false);
  }

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsLoading(true);
  };

  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  return (
    <Modal
      initialFocusRef={initialRefAddStudent}
      finalFocusRef={finalRefAddStudent}
      isOpen={isOpenAddStudent}
      onClose={onCloseAddStudent}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Students into Class</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
            <Box></Box>
            <Flex marginBottom={"2vh"}>
              <InputGroup>
                <Input
                  type="text"
                  border={0}
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  width={"30vh"}
                  marginRight={"1vh"}
                />
                <InputRightElement h={"full"}>
                  <Button
                    display={"flex"}
                    variant={"ghost"}
                    marginRight={"25px"}
                  >
                    <Icon fontSize="20px" as={IoSearchOutline} />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Popover
                initialFocusRef={initialRefFilter}
                finalFocusRef={finalRefFilter}
                isOpen={isOpenFilter}
                // onClose={handleCloseModal}
              >
                <PopoverTrigger>
                  <Button
                    size="md"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginRight="1vh"
                    color={"black"}
                    minWidth={"5vh"}
                    _hover={{
                      boxShadow: "xl",
                    }}
                    onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                  >
                    {isFilter ? (
                      <Icon fontSize="20px" as={RiFilterFill} />
                    ) : (
                      <Icon fontSize="20px" as={RiFilterLine} />
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton
                    onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                  />
                  <PopoverHeader>Filter option</PopoverHeader>
                  <PopoverBody>
                    <form onSubmit={formikFilter.handleSubmit}>
                      <FormControl>
                        <FormLabel>Gender</FormLabel>
                        <Select
                          name="gender"
                          value={formikFilter.values.gender}
                          onChange={formikFilter.handleChange}
                          placeholder="Select gender"
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Select>
                      </FormControl>
                      <PopoverFooter
                        border="0"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        pt={4}
                      >
                        <Box></Box>
                        <ButtonGroup size="sm">
                          {/* <FilterButton filterParams={{ gender: genderFilter, status: statusFilter }} setloadForm={setloadForm} setGenderFilter={setGenderFilter} setStatusFilter={setStatusFilter}/> */}
                          {isFilter ? (
                            <Button
                              colorScheme="red"
                              color={"white"}
                              size={"md"}
                              onClick={handleClearFilter}
                              marginRight={"10px"}
                            >
                              Clear
                            </Button>
                          ) : null}
                          <Button
                            bgColor={"rgb(99, 122, 138);"}
                            color={"white"}
                            size={"md"}
                            type="submit"
                          >
                            Apply
                          </Button>
                        </ButtonGroup>
                      </PopoverFooter>
                    </form>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Flex>
          {StudentsData.length === 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Text fontSize="20px">No record match</Text>
            </Box>
          ) : !isLoading ? (
            <Box>
              <Table variant="simple" className="Table">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        isChecked={checkAll}
                        onChange={(e) => setCheckAll(e.target.checked)}
                      >
                        {/* Checkbox label */}
                      </Checkbox>
                    </Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Address</Th>
                    <Th>Dob</Th>
                    <Th>Gender</Th>
                    <Th>Phone</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {StudentsData &&
                    StudentsData.map((item, index) => (
                      <Tr key={index}>
                        <Td>
                          <Checkbox
                            key={item?.id}
                            value={item?.id}
                            isChecked={checkedItems[item?.id]}
                            onChange={handleCheck(item?.id)}
                          >
                            {/* Checkbox label */}
                          </Checkbox>
                        </Td>
                        <Td>
                          <Link to={`/user/id/${item?.id}`}>
                            <Text
                              color={"rgb(88, 168, 168)"}
                              _hover={{
                                color: "rgb(88, 135, 168)",
                              }}
                            >
                              {item?.fullName}
                            </Text>
                          </Link>
                        </Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.address}</Td>
                        <Td>{item?.dob}</Td>
                        <Td>{item?.gender}</Td>
                        <Td>{item?.phone}</Td>
                        <Td>
                          {item?.status === "Active" ? (
                            <Badge variant="solid" colorScheme="green">
                              {item?.status}
                            </Badge>
                          ) : (
                            <Badge variant="solid" colorScheme="red">
                              {item?.status}
                            </Badge>
                          )}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              <Paginator
                first={currentPage}
                rows={10}
                totalRecords={totalRecords}
                onPageChange={onPageChange}
                template={{ layout: "PrevPageLink PageLinks NextPageLink" }}
                className="p-mt-4"
              />
            </Box>
          ) : (
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              display="flex"
              marginTop={"3vh"}
            >
              <Icon as={LoadingIcon} w={50} h={50} />
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSaveAddStudentsIntoClass}
            isDisabled={isSubmitting || countChecked() === 0}
          >
            {isSubmitting ? (
              <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
            ) : (
              "Save"
            )}
          </Button>
          <Button onClick={onCloseAddStudent} isDisabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function ClassDetail() {
  const [loadForm, setloadForm] = useState(false);
  const now = new Date();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const { initialRef: initialRefEdit, finalRef: finalRefEdit } = useRef(null);
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const { initialRef: initialRefDelete, finalRef: finalRefDelete } =
    useRef(null);
  const {
    isOpen: isOpenAddStudent,
    onOpen: onOpenAddStudent,
    onClose: onCloseAddStudent,
  } = useDisclosure();
  const { initialRef: initialRefAddStudent, finalRef: finalRefAddStudent } =
    useRef(null);
  const {
    isOpen: isOpenAttendance,
    onOpen: onOpenAttendance,
    onClose: onCloseAttendance,
  } = useDisclosure();
  const { initialRef: initialRefAttendance, finalRef: finalRefAttendance } =
    useRef(null);
  const [data, setData] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [moduleLectureData, setModuleLectureData] = useState([]);
  const [assignmentListData, setAssignmentListData] = useState([]);
  const [attendanceListData, setAttendanceListData] = useState([]);
  const { isOpen: isOpenCreateAttendance, onOpen: onOpenCreateAttendance, onClose: onCloseCreateAttendance } = useDisclosure();
  const { isOpen: isOpenAssignmentDetail, onOpen: onOpenAssignmentDetail, onClose: onCloseAssignmentDetail } = useDisclosure();
  const { isOpen: isOpenDeleteLecture, onOpen: onOpenDeleteLecture, onClose: onCloseDeleteLecture } = useDisclosure();
  const { initialRef: initialRefDeleteLecture, finalRef: finalRefDeleteLecture } = useRef(null);  
  const { initialRef: initialRefAssignmentDetail, finalRef: finalRefAssignmentDetail } = useRef(null);
  const { initialRef: initialRefCreateAttendance, finalRef: finalRefCreateAttendance } = useRef(null);
  const { isOpen: isOpenCreateLecture, onOpen: onOpenCreateLecture, onClose: onCloseCreateLecture } = useDisclosure();
  const { initialRef: initialRefCreateLecture, finalRef: finalRefCreateLecture } = useRef(null);
  const { isOpen: isOpenCreateAssignment, onOpen: onOpenCreateAssignment, onClose: onCloseCreateAssignment } = useDisclosure();
  const { initialRef: initialRefCreateAssignment, finalRef: finalRefCreateAssignment } = useRef(null);
  const { isOpen: isOpenAddTrainer, onOpen: onOpenAddTrainer, onClose: onCloseAddTrainer } = useDisclosure();
  const { initialRef: initialRefAddTrainer, finalRef: finalRefAddTrainer } = useRef(null);
  const { isOpen: isOpenEditAssignment, onOpen: onOpenEditAssignment, onClose: onCloseEditAssignment} = useDisclosure();
  const { initialRef: initialRefEditAssignment, finalRef: finalRefEditAssignment } = useRef(null);
  const { isOpen: isOpenEditLecture, onOpen: onOpenEditLecture, onClose: onCloseEditLecture} = useDisclosure();
  const { initialRef: initialRefEditLecture, finalRef: finalRefEditLecture } = useRef(null);
  const { isOpen: isOpenStudentScore, onOpen: onOpenStudentScore, onClose: onCloseStudentScore } = useDisclosure();
  const { initialRef: initialRefStudentScore, finalRef: finalRefStudentScore } = useRef(null);
  const token = localStorage.getItem("token");
  const [checkedItems, setCheckedItems] = useState({});
  const [indexTabs, setIndexTabs] = useState(0);
  const [isLoadingLecture, setIsLoadingLecture] = useState(true);
  const [isLoadingAssignment, setIsLoadingAssignment] = useState(true);
  const [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
  const [isLoadingMyCcore, setIsLoadingMyCcore] = useState(true);
  const [myScoreData, setMyScoreData] = useState({});
  const [isLoadingAttendanceDetail, setIsLoadingAttendanceDetail] = useState(false);
  const [currentPageAttendance, setCurrentPageAttendance] = useState(1);
  const [totalPageAttendance, setTotalPageAttendance] = useState(0);
  const [totalRecordsAttendance, setTotalRecordsAttendance] = useState(0);
  const [moduleLectureDetail, setModuleLectureDetail] = useState({});
  const [moduleAssignmentDetail, setModuleAssignmentDetail] = useState({});
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [assignmentDetailEdit, setAssignmentDetailEdit] = useState({});
  const [lectureDataDetail, setLectureDataDetail] = useState({});
  const [ModuleId, setModuleId] = useState(null);
  const [Lecture, setLecture] = useState(null);
  const [Student, setStudent] = useState(null);
  const [DataListScore, setDataListScore] = useState([0][0]);
  const id = useParams().id;
  const toast = useToast(); 
  const navigate = useNavigate();
  const from = useLocation();
  useEffect(() => {
    if(indexTabs === 0 && !loadForm) {
      const fetchData = async () => {
        if(localStorage.role === "SUAdmin" || localStorage.role === "Admin" || localStorage.role === "Trainer") {
          const {isSuccess, res} = await GET_CLASS_DETAIL(token, id);
          if (isSuccess) {
            const response = await res.json();
            setData(response.data);
            setloadForm(true);
          } else {
            if(res.status === 401) {
                logoutAccount();
                navigate('/login');
                localStorage.setItem('from', from.pathname);
            }
          }
        } else {
          const {isSuccess, res} = await GET_CLASS_DETAILS_FOR_STUDENT(token);
          if (isSuccess) {
            const response = await res.json();
            setData(response.data);
            console.log(response.data);
            setloadForm(true);
          } else {
            if(res.status === 401) {
                logoutAccount();
                navigate('/login');
                localStorage.setItem('from', from.pathname);
            }
          }
        }
      };
      fetchData();
    }
  }, [indexTabs, id, token, from.pathname, navigate, loadForm]);

  useEffect(() => {
    if(indexTabs === 1 && isLoadingLecture) {
      setIsLoadingLecture(true);
      const fetchData = async () => {
        if(localStorage.role === "SUAdmin" || localStorage.role === "Admin" || localStorage.role === "Trainer") {
          const { isSuccess, res } = await GET_MODULE_LECTURE_DETAIL(token, id);
          if (isSuccess) {
            const response = await res.json();
            setModuleLectureData(response.data);
            setIsLoadingLecture(false);
          }
        } else {
          const { isSuccess, res } = await GET_MODULE_LECTURE_FOR_STUDENT(token);
          if (isSuccess) {
            const response = await res.json();
            setModuleLectureData(response.data);
            setIsLoadingLecture(false);
          }
        }
      };
      fetchData();
    }
  }, [indexTabs, id, token, isLoadingLecture]);

  useEffect(() => {
    if(indexTabs === 2 && (localStorage.role === "SUAdmin" || localStorage.role === "Admin")) {
      let request = `page=${currentPageAttendance}`;
      const fetchData = async () => {
        const { isSuccess, res } = await GET_LIST_ATTENDANCE(token, id, request);
        if (isSuccess) {
          const response = await res.json();
          setAttendanceListData(response.data.listData);
          setIsLoadingAttendance(false);
          setTotalPageAttendance(response.data.totalPage);
          setTotalRecordsAttendance(response.data.totalRecords);
        }
      };
      fetchData();
    }
  }, [indexTabs, id, token, currentPageAttendance, isLoadingAttendance]);

  useEffect(() => {
    if(indexTabs === 2 && (localStorage.role === "Student" || localStorage.role === "Trainer") && isLoadingAssignment) {
      const fetchData = async () => {
        const { isSuccess, res } = await GET_LIST_ASSIGNMENT(token, id);
        if (isSuccess) {
          const response = await res.json();
          setAssignmentListData(response.data);
          setIsLoadingAssignment(false);
        }
      };
      fetchData();
    }
  }, [indexTabs, id, token, isLoadingAssignment]);

  useEffect(() => {
    if(indexTabs === 3 && localStorage.role === "Student" && isLoadingMyCcore) {
      const fetchData = async () => {
        const { isSuccess, res } = await GET_LIST_SCORE_OF_STUDENT(token, localStorage.id);
        if (isSuccess) {
          const response = await res.json();
          setMyScoreData(response.data);
          setIsLoadingMyCcore(false);
        }
      };
      fetchData();
    } else if(indexTabs === 3 && localStorage.role !== "Student" && isLoadingMyCcore) {
      const fetchData = async () => {
        const { isSuccess, res } = await GET_LIST_SCORE_OF_CLASS(token, id);
        if (isSuccess) {
          const response = await res.json();
          setMyScoreData(response.data);
          if(response.data != null || response.data.length !== 0) {
            handleDataListScore(response.data);
          }
          setIsLoadingMyCcore(false);
        }
      };
      fetchData();
    }
  }, [indexTabs, id, token, isLoadingMyCcore]);

  function handleDataListScore(data) {
    var temp = [];
    for (let i = 0; i < data[0].studentListInfos.length; i++) {
      var jsonTemp = {
        id: data[0].studentListInfos[i].studentId,
        name: data[0].studentListInfos[i].name,
      }
      temp.push([jsonTemp]);
    }
    for (let j = 0; j < data.length; j++) {
      for (let k = 0; k < data[j].studentListInfos.length; k++) {
        temp[k].push(data[j].studentListInfos[k].avgScore);
      }
    }
    setDataListScore(temp);
  }

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  const changeTabs = (index) => {
    setIndexTabs(index);
    switch(index) {
      case 0:
        setloadForm(false);
        break;
      case 1:
        setIsLoadingLecture(true);
        break;
      case 2: {
        if(localStorage.role === "SUAdmin" || localStorage.role === "Admin") {
          setIsLoadingAttendance(true);
          setCurrentPageAttendance(1);
          break;
        } else {
          setIsLoadingAssignment(true);
          break;
        }
      }
      case 3:
        setIsLoadingMyCcore(true);
        break;
      default:
        break;
    }
  };

  const onPageChangeAttendance = (event) => {
    setCurrentPageAttendance(event.page + 1);
  };

  const handleGetAttendance = async(attendanceId) =>{
    setIsLoadingAttendanceDetail(true);
    onOpenAttendance();
    const token = localStorage.getItem('token');
    const {isSuccess, res} = await GET_ATTENDANCE(token, attendanceId);
    if(isSuccess) {
      const response = await res.json();
      setAttendanceData(response.data);
      setIsLoadingAttendanceDetail(false);
    } else {
      toast({
        title: "Error",
        description: "Failed to get attendance",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  const handleShowAssignment = async(assignment) => {
    if(now >= new Date(assignment.expiryDate) && !assignment.isOverTime) return;
    setAssignmentDetail(assignment);
    onOpenAssignmentDetail();
  }

  const handleEditAssignment = async(assignment) =>{
    await setAssignmentDetailEdit(assignment);
    onOpenEditAssignment();
  }

  const handleEditLecture = async(lecture, module) =>{
    await setModuleId(module.id);
    await setLectureDataDetail(lecture);
    onOpenEditLecture();
  }

  const handleCreateLecture = async(module) =>{
    await setModuleLectureDetail(module);
    onOpenCreateLecture();
  }

  const handleCreateAssignment = async(module) =>{
    await setModuleAssignmentDetail(module);
    onOpenCreateAssignment();
  }

  const handleDeleteLecture = async(lecture) =>{
    await setLecture(lecture);
    onOpenDeleteLecture();
  }

  const handleShowStudentScore = async(student) =>{
    await setStudent(student);
    onOpenStudentScore();
  }

  const downloadFile = async (filePath, fileName) => {
    try {
      // Get download URL for the file from Firebase Storage
      const fileRef = ref(storage, `${filePath}/${fileName}`); // Create a reference to the file

      // Get download URL for the file from Firebase Storage
      const downloadURL = await getDownloadURL(fileRef);

      // Now download the file using downloadURL
      // For example, you can use fetch API to download the file
      const response = await fetch(downloadURL);
      if (response.ok) {
        // Convert response to blob
        const blob = await response.blob();
        // Create a link element to download the file
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // You can set custom file name here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error("Failed to download file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <Flex marginBottom={"3vh"} color={"rgb(36, 112, 166)"}>
        <Link to={localStorage.role === "SUAdmin" ? `/class` : localStorage.role === "Student" ? `/my-learning` : `/my-class`}>{"<<"} Go back</Link>
      </Flex>
      <div>
        <Tabs variant="enclosed" onChange={changeTabs}>
          <TabList>
            <Tab>Information</Tab>
            <Tab>Lectures</Tab>
            {localStorage.role === "Student" || localStorage.role === "Trainer" ? (
              <Tab>Assignments</Tab>
            ) : null}
            {localStorage.role === "SUAdmin" || localStorage.role === "Admin" ? (
              <Tab>Attendance</Tab>
            ) : null}
            {localStorage.role === "Student" ? (
              <Tab>My scores</Tab>
            ) : (
              <Tab>Report</Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                {/* <ChangePassword isOpenChangePassword={isOpenChangePassword} onOpenChangePassword={onOpenChangePassword} onCloseChangePassword={onCloseChangePassword} initialRefChangePassword={initialRefChangePassword} finalRefChangePassword={finalRefChangePassword} /> */}
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Class detail</Heading>
                  <Flex>
                    {localStorage.role === "SUAdmin" || localStorage.role === "Admin" ? (
                      <div>
                        {loadForm ? (
                          <div>
                            <AddStudentIntoClass
                              isOpenAddStudent={isOpenAddStudent}
                              onOpenAddStudent={onOpenAddStudent}
                              onCloseAddStudent={onCloseAddStudent}
                              initialRefAddStudent={initialRefAddStudent}
                              finalRefAddStudent={finalRefAddStudent}
                              setloadForm={setloadForm}
                            />
                            <DeleteStudentsFromClass
                              isOpenDelete={isOpenDelete}
                              onOpenDelete={onOpenDelete}
                              onCloseDelete={onCloseDelete}
                              initialRefDelete={initialRefDelete}
                              finalRefDelete={finalRefDelete}
                              setloadForm={setloadForm}
                              data={checkedItems}
                              setCheckedItems={setCheckedItems}
                            />
                            <EditClass
                              isOpenEdit={isOpenEdit}
                              onOpenEdit={onOpenEdit}
                              onCloseEdit={onCloseEdit}
                              initialRefEdit={initialRefEdit}
                              finalRefEdit={finalRefEdit}
                              data={data}
                              setloadForm={setloadForm}
                            />
                          </div>
                        ) : null}
                        <Button
                          onClick={onOpenEdit}
                          size="md"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginRight="1vh"
                          isDisabled={!loadForm}
                        >
                          <Icon fontSize="20px" as={FaRegEdit} />
                        </Button>
                      </div>
                    ) : null}
                    {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                            Change password
                                        </Button> */}
                  </Flex>
                </Flex>
                {loadForm ? (
                  <Flex flexWrap="wrap">
                    <Stack className="col-6" marginTop="10" spacing={4}>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Name:</b>
                        </Text>
                        <Text as="span">{data.name}</Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Start at:</b>
                        </Text>
                        <Text as="span">
                          {format(new Date(data.startAt), "dd/MM/yyyy")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>End at:</b>
                        </Text>
                        <Text as="span">
                          {format(new Date(data.endAt), "dd/MM/yyyy")}
                        </Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Location:</b>
                        </Text>
                        <Text as="span">{data.location}</Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Status:</b>
                        </Text>
                        <Text as="span">{data.status}</Text>
                      </Flex>
                    </Stack>
                    {localStorage.role === "SUAdmin" || localStorage.role === "Admin" || localStorage.role === "Trainer" ? (
                      <Stack className="col-6" marginTop="10" spacing={4}>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Created by:</b>
                          </Text>
                          <Link to={`/user/id/${data.createdBy.id}`}>
                            <Text as="span">{data.createdBy.name}</Text>
                          </Link>
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Created at:</b>
                          </Text>
                          <Text as="span">
                            {format(new Date(data.createdAt), "dd/MM/yyyy")}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Updated by:</b>
                          </Text>
                          {data.updatedBy.id === null ? (
                            <Text as="span">N/a</Text>
                          ) : (
                            <Link to={`/user/id/${data.updatedBy.id}`}>
                              <Text as="span">{data.updatedBy.name}</Text>
                            </Link>
                          )}
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Updated at:</b>
                          </Text>
                          {data.updatedAt === null ? (
                            <Text as="span">N/a</Text>
                          ) : (
                            <Text as="span">
                              {format(new Date(data.updatedAt), "dd/MM/yyyy")}
                            </Text>
                          )}
                        </Flex>
                      </Stack>
                    ) : null}
                  </Flex>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </Box>
              <Box marginTop={"5vh"}>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Program Training information</Heading>
                  <Flex>
                    {/* {data.role === "SUAdmin" ? (
                                            <div>
                                                <Button onClick={onOpenEdit} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="1vh">
                                                    <Icon fontSize="20px" as={FaRegEdit} />
                                                </Button>
                                            </div>
                                        ) : null}
                                        <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                            Change password
                                        </Button> */}
                  </Flex>
                </Flex>
                {loadForm ? (
                  <Flex flexWrap="wrap">
                    <Stack className="col-6" marginTop="10" spacing={4}>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Name:</b>
                        </Text>
                        <Link to={`/training-program/id/${data.programDetails.id}`}>
                          <Text as="span">{data.programDetails.name}</Text>
                        </Link>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Code:</b>
                        </Text>
                        <Text as="span">{data.programDetails.code}</Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Duration:</b>
                        </Text>
                        <Text as="span">
                          {data.programDetails.duration} months
                        </Text>
                      </Flex>
                      <Flex alignItems="center" fontSize={"20px"}>
                        <Text as="span" mr="2">
                          <b>Status:</b>
                        </Text>
                        <Text as="span">{data.status}</Text>
                      </Flex>
                    </Stack>
                    {localStorage.role === "SUAdmin" || localStorage.role === "Admin" || localStorage.role === "Trainer" ? (
                      <Stack className="col-6" marginTop="10" spacing={4}>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Created by:</b>
                          </Text>
                          <Link to={`/user/id/${data.createdBy.id}`}>
                            <Text as="span">
                              {data.programDetails.createdBy.name}
                            </Text>
                          </Link>
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Created at:</b>
                          </Text>
                          <Text as="span">
                            {format(
                              new Date(data.programDetails.createdAt),
                              "dd/MM/yyyy"
                            )}
                          </Text>
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Updated by:</b>
                          </Text>
                          {data.programDetails.updatedBy.id === null ? (
                            <Text as="span">N/a</Text>
                          ) : (
                            <Link to={`/user/id/${data.updatedBy.id}`}>
                              <Text as="span">
                                {data.programDetails.updatedBy.name}
                              </Text>
                            </Link>
                          )}
                        </Flex>
                        <Flex alignItems="center" fontSize={"20px"}>
                          <Text as="span" mr="2">
                            <b>Updated at:</b>
                          </Text>
                          {data.programDetails.updatedAt === null ? (
                            <Text as="span">N/a</Text>
                          ) : (
                            <Text as="span">
                              {format(
                                new Date(data.programDetails.updatedAt),
                                "dd/MM/yyyy"
                              )}
                            </Text>
                          )}
                        </Flex>
                      </Stack>
                    ) : null}
                  </Flex>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </Box>
              <Box marginTop={"5vh"}>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Manager</Heading>
                  <Flex>
                    {/* {data.role === "SUAdmin" ? (
                                            <div>
                                                <Button onClick={onOpenEdit} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="1vh">
                                                    <Icon fontSize="20px" as={FaRegEdit} />
                                                </Button>
                                            </div>
                                        ) : null}
                                        <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                            Change password
                                        </Button> */}
                    {localStorage.role === "SUAdmin" || localStorage.role === "Admin" ? (
                      <div>
                        <AddNewTrainerIntoClass isOpenAddTrainer={isOpenAddTrainer} onOpenAddTrainer={onOpenAddTrainer} onCloseAddTrainer={onCloseAddTrainer} initialRefAddTrainer={initialRefAddTrainer} finalRefAddTrainer={finalRefAddTrainer} classId={id} setloadForm={setloadForm}/>
                        <Button
                            onClick={onOpenAddTrainer}
                            size="md"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            marginRight="1vh"
                            isDisabled={!loadForm}
                          >
                            <Icon
                              fontSize="20px"
                              as={MdFormatListBulletedAdd}
                              marginRight={"10px"}
                            />{" "}
                            Add Trainer into class
                        </Button>
                      </div>
                    ) : null}
                  </Flex>
                </Flex>
                {loadForm ? (
                  <Flex mt={"5vh"}>
                    <TableContainer>
                      <Table variant="simple" className="Table">
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Role</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data.classManagerAndTrainers.map((item, index) => (
                            <Tr key={item?.id}>
                              <Td></Td>
                              <Td>
                                <Link to={`/user/id/${item?.id}`}>
                                  <Text
                                    color={"rgb(88, 168, 168)"}
                                    _hover={{
                                      color: "rgb(88, 135, 168)",
                                    }}
                                  >
                                    {item?.name}
                                  </Text>
                                </Link>
                              </Td>
                              <Td>{item?.email}</Td>
                              <Td>
                                <Badge variant="solid" colorScheme={item?.role === "Admin" ? "orange" : item?.role === "Trainer" ? "yellow" : "blue"}>
                                  {item?.role}
                                </Badge>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Flex>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </Box>
              <Box marginTop={"5vh"}>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Students in class</Heading>
                  <Flex>
                    {localStorage.getItem("role") === "SUAdmin" ||
                    localStorage.getItem("role") === "Admin" ? (
                      <div>
                        <Button
                          onClick={onOpenDelete}
                          size="md"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginRight="1vh"
                          isDisabled={countChecked() === 0}
                          bg={"red"}
                          color={"white"}
                        >
                          <Icon
                            fontSize="20px"
                            as={MdFormatListBulletedAdd}
                            marginRight={"10px"}
                          />{" "}
                          Delete
                        </Button>
                      </div>
                    ) : null}
                    {localStorage.getItem("role") === "SUAdmin" ||
                    localStorage.getItem("role") === "Admin" ? (
                      <div>
                        <Button
                          onClick={onOpenAddStudent}
                          size="md"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginRight="1vh"
                          isDisabled={!loadForm}
                        >
                          <Icon
                            fontSize="20px"
                            as={MdFormatListBulletedAdd}
                            marginRight={"10px"}
                          />{" "}
                          Add student into class
                        </Button>
                      </div>
                    ) : null}
                    {/* <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                                            Change password
                                        </Button> */}
                  </Flex>
                </Flex>
                {loadForm ? (
                  <Flex marginTop={"5vh"}>
                    {data.listStudent.length !== 0 ? (
                      <TableContainer minWidth={"90%"}>
                        <Table variant="simple" className="Table">
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th>Name</Th>
                              <Th>Email</Th>
                              <Th>Address</Th>
                              <Th>Dob</Th>
                              <Th>Gender</Th>
                              <Th>Phone</Th>
                              <Th>Status</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {data.listStudent &&
                              data.listStudent.map((item, index) => (
                                <Tr key={index}>
                                  <Td>
                                    <Checkbox
                                      key={item?.id}
                                      value={item?.id}
                                      isChecked={checkedItems[item?.id]}
                                      onChange={handleCheck(item?.id)}
                                    >
                                      {/* Checkbox label */}
                                    </Checkbox>
                                  </Td>
                                  <Td>
                                    <Link to={`/user/id/${item?.id}`}>
                                      <Text
                                        color={"rgb(88, 168, 168)"}
                                        _hover={{
                                          color: "rgb(88, 135, 168)",
                                        }}
                                      >
                                        {item?.fullName}
                                      </Text>
                                    </Link>
                                  </Td>
                                  <Td>{item?.email}</Td>
                                  <Td>{item?.address}</Td>
                                  <Td>
                                    {format(new Date(item?.dob), "dd/MM/yyyy")}
                                  </Td>
                                  <Td>{item?.gender}</Td>
                                  <Td>{item?.phone}</Td>
                                  <Td>
                                    {item?.status === "Active" ? (
                                      <Badge
                                        variant="solid"
                                        colorScheme="green"
                                      >
                                        {item?.status}
                                      </Badge>
                                    ) : (
                                      <Badge variant="solid" colorScheme="red">
                                        {item?.status}
                                      </Badge>
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                        marginBottom={"5vh"}
                      >
                        <Text fontSize={"20px"}>
                          <i>No students in this class</i>
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </Box>
            </TabPanel>
            <TabPanel>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                display="flex"
              >
                <Heading>Lectures</Heading>
                <Flex>
                  <CreateLecture isOpenCreateLecture={isOpenCreateLecture} onOpenCreateLecture={onOpenCreateLecture} onCloseCreateLecture={onCloseCreateLecture} initialRefCreateLecture={initialRefCreateLecture} finalRefCreateLecture={finalRefCreateLecture} ClassData={data} ModuleData={moduleLectureDetail} setIsLoadingLecture={setIsLoadingLecture}/>
                  <EditLecture isOpenEditLecture={isOpenEditLecture} onOpenEditLecture={onOpenEditLecture} onCloseEditLecture={onCloseEditLecture} finalRefEditLecture={finalRefEditLecture} initialRefEditLecture={initialRefEditLecture} ClassData={data} data={lectureDataDetail} setIsLoadingLecture={setIsLoadingLecture} ModuleId={ModuleId} setLectureDetailFromClassComponent={setLectureDataDetail}/>
                  <DeleteLecture isOpenDeleteLecture={isOpenDeleteLecture} onOpenDeleteLecture={onOpenDeleteLecture} onCloseDeleteLecture={onCloseDeleteLecture} finalRefDeleteLecture={finalRefDeleteLecture} initialRefDeleteLecture={initialRefDeleteLecture} lecture={Lecture} setLecture={setLecture} setIsLoadingLecture={setIsLoadingLecture}/>
                </Flex>
              </Flex>
              <Accordion allowMultiple mt={"5vh"}>
                {!isLoadingLecture ? (
                  moduleLectureData.map((item, index) => (
                    <AccordionItem key={index}>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {item.name} - {item.code}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {localStorage.role === "Trainer" ? (
                          <Flex justifyContent={"end"}>
                            <Button onClick={() => handleCreateLecture(item)}>Create</Button>
                          </Flex>
                        ) : null}
                        {item.lectures.map((lecture, index) => (
                          <Box>
                            <Card key={lecture?.id} mt={4}>
                              <CardHeader>
                              <Flex
                                flexWrap={"wrap"}
                                justifyContent={"space-between"}
                              >
                                <Box>
                                  <Heading size="md">{lecture?.name}</Heading>
                                  <Box mt={"2px"}>
                                    <Flex flexWrap={"wrap"} alignItems={"center"}>
                                      <Icon
                                        as={IoMdPerson}
                                        w={4}
                                        h={4}
                                        color={"#787878"}
                                        mr={"5px"}
                                      />
                                      <Text color={"#787878"}>
                                        <Link
                                          to={`/user/id/${lecture?.createdBy.id}`}
                                        >
                                          <Text as="span" fontSize={"15px"}>
                                            {lecture?.createdBy.name}
                                          </Text>
                                        </Link>{" "}
                                        <span ml={"10px"}>•</span>{" "}
                                        {format(
                                          new Date(lecture?.createdAt),
                                          "dd/MM/yyyy"
                                        )}
                                      </Text>
                                    </Flex>
                                  </Box>
                                </Box>
                                {localStorage.role === "Trainer" ? (
                                  <Box>
                                    <Button onClick={() => {handleEditLecture(lecture, item)}} mr={"5px"}>
                                      <Icon fontSize="20px" as={FaRegEdit} />
                                    </Button>
                                    <Button onClick={() => {handleDeleteLecture(lecture)}}>
                                      <Icon fontSize="20px" as={FiTrash2} />
                                    </Button>
                                  </Box>
                                ) : null}
                              </Flex>
                                
                                
                              </CardHeader>
                              <CardBody>
                                {lecture.documents.length > 0 ? (
                                  <Box
                                    border={"1px"}
                                    borderRadius={"10px"}
                                    borderColor={"#EDF2F7"}
                                  >
                                    <Table>
                                      <Tbody>
                                        {lecture.documents.map(
                                          (document, index) => (
                                            <Tr key={document.id}>
                                              <Td>
                                                <Flex
                                                  alignItems={"center"}
                                                  onClick={() =>
                                                    downloadFile(
                                                      `class_${id}/documents/module_${item.id}/lecture_${lecture.id}`,
                                                      document.fileName
                                                    )
                                                  }
                                                  color={"#548ed1"}
                                                  _hover={{
                                                    color: "#787878",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <Icon
                                                    as={FaRegFileLines}
                                                    mr={"5px"}
                                                  />
                                                  {document.fileName}
                                                </Flex>
                                              </Td>
                                            </Tr>
                                          )
                                        )}
                                      </Tbody>
                                    </Table>
                                  </Box>
                                ) : null}
                              </CardBody>
                            </Card>
                          </Box>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </Accordion>
            </TabPanel>
            {localStorage.role === "Student" || localStorage.role === "Trainer" ? (
              <TabPanel>
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Assignments</Heading>
                  <Flex>
                    <AssignmentDetail isOpenAssignmentDetail={isOpenAssignmentDetail} onOpenAssignmentDetail={onOpenAssignmentDetail} onCloseAssignmentDetail={onCloseAssignmentDetail} initialRefAssignmentDetail={initialRefAssignmentDetail} finalRefAssignmentDetail={finalRefAssignmentDetail} data={assignmentDetail} setIsLoadingAssignment={setIsLoadingAssignment} setAssignmentDetailFromClassComponent={setAssignmentDetail}/>
                    <CreateAssignment isOpenCreateAssignment={isOpenCreateAssignment} onOpenCreateAssignment={onOpenCreateAssignment} onCloseCreateAssignment={onCloseCreateAssignment} initialRefCreateAssignment={initialRefCreateAssignment} finalRefCreateAssignment={finalRefCreateAssignment} ClassData={data} ModuleData={moduleAssignmentDetail} setIsLoadingAssignment={setIsLoadingAssignment}/>
                    <EditAssignment isOpenEditAssignment={isOpenEditAssignment} onOpenEditAssignment={onOpenEditAssignment} onCloseEditAssignment={onCloseEditAssignment} finalRefEditAssignment={finalRefEditAssignment} initialRefEditAssignment={initialRefEditAssignment} ClassData={data} data={assignmentDetailEdit} setAssignmentDetailFromClassComponent={setAssignmentDetailEdit} setIsLoadingAssignment={setIsLoadingAssignment} AssignmentData={assignmentDetailEdit}/>
                    {/* {indexTabs === 2 && localStorage.role === "Trainer" && !isLoadingAssignment ? (
                      <div> */}
                      {/* </div>
                    ) : null} */}
                  </Flex>
                </Flex>
                <Accordion allowMultiple mt={"5vh"}>
                  {!isLoadingAssignment ? (
                    assignmentListData.map((item, index) => (
                      <AccordionItem key={item.id}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              {item.name} - {item.code}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {localStorage.role === "Trainer" ? (
                            <Flex justifyContent={"end"}>
                              <Button onClick={() => handleCreateAssignment(item)}>Create</Button>
                            </Flex>
                          ) : null}
                          {item.assignments.map((assignment, index) => (
                              <Card key={assignment.id} mt={4}>
                                <CardHeader>
                                  <Flex
                                    flexWrap={"wrap"}
                                    justifyContent={"space-between"}
                                  >
                                    <Box>
                                      <Flex 
                                        flexWrap={"wrap"}
                                        alignItems={"center"}
                                      >
                                        {now >= new Date(assignment?.expiryDate) && !assignment?.isOverTime ? (
                                          <Icon as={FaLock} w={4} h={4} color={"#787878"} mr={"5px"} />
                                        ) : null}
                                          <Heading 
                                            size="md"
                                            color={"#548ed1"}
                                            _hover={{
                                              color: now >= new Date(assignment?.expiryDate) && !assignment?.isOverTime ? "#548ed1" : "#787878",
                                              cursor: now >= new Date(assignment?.expiryDate) && !assignment?.isOverTime ? "not-allowed" : "pointer",
                                            }}
                                            pointerEvents={now >= new Date(assignment?.expiryDate) && !assignment?.isOverTime ? "not-allowed" : "auto"}
                                            onClick={() => {handleShowAssignment(assignment)}}
                                          >
                                            {assignment?.title}
                                          </Heading>
                                        {now >= new Date(assignment?.expiryDate) && !assignment?.isOverTime ? (
                                          <div>
                                            {localStorage.role === "Trainer" ? (
                                              <Box 
                                              ml={"10px"}
                                              borderRadius={"5px"}
                                              border={"1px"}
                                              paddingRight={"5px"}
                                              paddingLeft={"5px"}
                                              color={"white"}
                                              bg={"red.300"}
                                              borderColor={"red.300"}
                                            >
                                              <Text>Closed</Text>
                                            </Box>
                                            ) : (
                                              <Box 
                                                ml={"10px"}
                                                borderRadius={"5px"}
                                                border={"1px"}
                                                paddingRight={"5px"}
                                                paddingLeft={"5px"}
                                                color={"white"}
                                                bg={"red.300"}
                                                borderColor={"red.300"}
                                              >
                                                <Text>Expired</Text>
                                              </Box>
                                            )}
                                          </div>
                                        ) : (
                                          <div>
                                            {localStorage.role === "Trainer" ? (
                                              <Box 
                                                ml={"10px"}
                                                borderRadius={"5px"}
                                                border={"1px"}
                                                paddingRight={"5px"}
                                                paddingLeft={"5px"}
                                                color={"white"}
                                                bg={"green.300"}
                                                borderColor={"green.300"}
                                              >
                                                <Text>Open</Text>
                                              </Box>
                                            ) : (
                                              <Box 
                                                ml={"10px"}
                                                borderRadius={"5px"}
                                                border={"1px"}
                                                paddingRight={"5px"}
                                                paddingLeft={"5px"}
                                                color={"white"}
                                                bg={assignment?.submissionStatus === "Submitted" ? "green.300" : "orange.300"}
                                                borderColor={assignment?.submissionStatus === "Submitted" ? "green.300" : "orange.300"}
                                              >
                                                <Text>{assignment?.submissionStatus}</Text>
                                              </Box>
                                            )}
                                          </div>
                                        )}
                                      </Flex>
                                      <Box mt={"2px"}>
                                        <Flex flexWrap={"wrap"} alignItems={"center"}>
                                          <Icon
                                            as={IoMdPerson}
                                            w={4}
                                            h={4}
                                            color={"#787878"}
                                            mr={"5px"}
                                          />
                                          <Text color={"#787878"}>
                                            <Link
                                              to={`/user/id/${assignment?.createdBy.id}`}
                                            >
                                              <Text as="span" fontSize={"15px"}>
                                                {assignment?.createdBy.name}
                                              </Text>
                                            </Link>{" "}
                                            <span ml={"10px"}>•</span>{" "}
                                            {format(
                                              new Date(assignment?.createdAt),
                                              "dd/MM/yyyy"
                                            )}
                                          </Text>
                                        </Flex>
                                      </Box>
                                      <Box mt={"2px"}>
                                        <Flex flexWrap={"wrap"} alignItems={"center"}>
                                          <Icon
                                            as={PiTimer}
                                            w={4}
                                            h={4}
                                            color={"#787878"}
                                            mr={"5px"}
                                          />
                                          <Text color={"#787878"} mt={"5px"}>
                                            {now >= new Date(assignment?.expiryDate) && assignment?.isOverTime ? (
                                              <span>
                                                <Text as="span" fontSize={"15px"}>
                                                  {format(new Date(assignment?.expiryDate),"dd/MM/yyyy, HH:mm")}
                                                </Text>
                                                {" "}
                                                <span ml={"10px"}>•</span>{" "}
                                                <Badge colorScheme="orange" alignItems={"center"}>OverDue</Badge>
                                              </span>
                                            ) : (
                                              <span>
                                                <Text as="span" fontSize={"15px"}>
                                                  {format(new Date(assignment?.expiryDate),"dd/MM/yyyy, HH:mm")}
                                                </Text>
                                              </span>
                                            )}
                                          </Text>
                                        </Flex>
                                      </Box>
                                    </Box>
                                    {localStorage.role === "Trainer" ? (
                                      <Box>
                                        <Button onClick={() => {handleEditAssignment(assignment)}} mr={"5px"}>
                                          <Icon fontSize="20px" as={FaRegEdit} />
                                        </Button>
                                        <Button onClick={() => {}}>
                                          <Icon fontSize="20px" as={FiTrash2} />
                                        </Button>
                                      </Box>
                                    ) : null}
                                  </Flex>
                                </CardHeader>
                                <CardBody>
                                  <Text>{assignment?.content}</Text>
                                </CardBody>
                              </Card>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    ))
                  ) : (
                    <Flex
                      flexWrap="wrap"
                      justifyContent="space-between"
                      display="flex"
                      marginTop={"3vh"}
                    >
                      <Icon as={LoadingIcon} w={50} h={50} />
                    </Flex>
                  )}
                </Accordion>
              </TabPanel>
            ) : null}
            {localStorage.role === "SUAdmin" || localStorage.role === "Admin" ? (
              <TabPanel>
                <ShowAttendance
                  isOpenAttendance={isOpenAttendance}
                  onOpenAttendance={onOpenAttendance}
                  onCloseAttendance={onCloseAttendance}
                  initialRefAttendance={initialRefAttendance}
                  finalRefAttendance={finalRefAttendance}
                  data={attendanceData}
                  isLoadingAttendanceDetail={isLoadingAttendanceDetail}
                  id={id}
                />
                <Flex
                  flexWrap="wrap"
                  justifyContent="space-between"
                  display="flex"
                >
                  <Heading>Attendance</Heading>
                  <Flex>
                    <CreateAttendance isOpenCreateAttendance={isOpenCreateAttendance} onOpenCreateAttendance={onOpenCreateAttendance} onCloseCreateAttendance={onCloseCreateAttendance} initialRefCreateAttendance={initialRefCreateAttendance} finalRefCreateAttendance={finalRefCreateAttendance} setAttendanceData={setAttendanceData} onOpenAttendance={onOpenAttendance} id={id} />
                    <Button onClick={onOpenCreateAttendance}>Create</Button>
                  </Flex>
                </Flex>
                
                {!isLoadingAttendance ? (
                  <Flex
                    justifyContent={attendanceListData.length === 0 ? "center" : "none"} 
                  >
                      {attendanceListData.length !== 0 ? (
                        <Box>
                          <Flex marginTop={"5vh"}>  
                            <TableContainer minWidth={"90%"}>
                              <Table variant="simple" className="Table">
                                <Thead>
                                  <Tr>
                                    <Th></Th>
                                    <Th>Date</Th>
                                    <Th>Total Student</Th>
                                    <Th>Present Student</Th>
                                    <Th>Status</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {attendanceListData &&
                                    attendanceListData.map((item, index) => (
                                      <Tr key={item?.attendanceId}>
                                        <Td>
                                        </Td>
                                        <Td>
                                        <Link
                                          to="#"
                                          onClick={() => handleGetAttendance(item?.attendanceId)}
                                        >
                                          <Text
                                            color={"rgb(88, 168, 168)"}
                                            _hover={{
                                              color: "rgb(88, 135, 168)",
                                            }}
                                          >
                                            {format(new Date(item?.date), "dd/MM/yyyy")}
                                          </Text>
                                        </Link>
                                        </Td>
                                        <Td>{item?.numberOfStudents}</Td>
                                        <Td>{item?.numberOfPresentStudent}</Td>
                                        <Td>
                                          <Badge
                                            variant="solid"
                                            colorScheme={item?.status === "Done" ? "green" : item?.status === "Progressing" ? "yellow" : "orange"}
                                          >
                                            {item?.status}
                                          </Badge>
                                        </Td>
                                      </Tr>
                                    ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Flex>
                          <Flex
                            flexWrap="wrap"
                            justifyContent="space-between"
                          >
                            <Flex></Flex>
                            <Flex>
                              <Paginator
                                first={currentPageAttendance}
                                rows={10}
                                totalRecords={totalRecordsAttendance}
                                onPageChange={onPageChangeAttendance}
                                template={{
                                  layout: "PrevPageLink PageLinks NextPageLink",
                                }}
                                className="p-mt-4"
                              />
                            </Flex>
                            <Flex>
                              <Box
                                marginTop={"2vh"}
                                marginRight={"15vh"}
                                bg="rgb(225, 232, 237)"
                                w="100px"
                                display={"flex"}
                                borderRadius={20}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Text>
                                  {currentPageAttendance} of {totalPageAttendance}
                                </Text>
                              </Box>
                            </Flex>
                          </Flex>
                        </Box>
                      ) : (
                        <Flex>
                          <Text fontSize={"20px"} mt={"10px"}>
                            <i>No attendances in class</i>
                          </Text>
                        </Flex>
                      )}
                  </Flex>
                  ) : (
                    <Flex
                      flexWrap="wrap"
                      justifyContent="center"
                      display="flex"
                      marginTop={"3vh"}
                    >
                      <Icon as={LoadingIcon} w={50} h={50} />
                    </Flex>
                  )}
              </TabPanel>
            ) : null}
            {localStorage.role === "Student" ? (
              <Box>
                <TabPanel>
                  <Flex 
                    flexWrap="wrap"
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                  >
                    <Heading>My Scores</Heading>
                    <Box>
                      {!isLoadingMyCcore ? (
                        <Heading size={"lg"} mr={"30px"}>
                          GPA: {myScoreData.gpa}
                        </Heading>
                      ) : null}
                    </Box>
                  </Flex>
                  <Accordion allowMultiple mt={"5vh"}>
                    {!isLoadingMyCcore ? (
                      myScoreData.studentScores.map((item, index) => (
                        <AccordionItem key={index}>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                {item.name} - {item.code}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Heading size={"lg"}>
                              Avg. Score: {item.moduleAvgScore}/10
                            </Heading>
                            {item.assignments.map((assignment, index) => (
                              <Box>
                                <Card key={assignment?.id} mt={4}>
                                  <CardHeader>
                                    <Flex
                                      flexWrap={"wrap"}
                                      justifyContent={"space-between"}
                                    >
                                      <Box>
                                        <Flex 
                                          flexWrap={"wrap"}
                                          alignItems={"center"}
                                        >
                                          <Heading 
                                            size="md"
                                            color={"#548ed1"}
                                            _hover={{
                                              color: "#787878",
                                              cursor: "pointer"
                                            }}
                                          >
                                            {assignment?.title}
                                          </Heading>
                                        </Flex>
                                      </Box>
                                    </Flex>
                                    <Box mt={"2px"}>
                                      <Flex flexWrap={"wrap"} alignItems={"center"}>
                                        <Icon
                                          as={PiTimer}
                                          w={4}
                                          h={4}
                                          color={"#787878"}
                                          mr={"5px"}
                                        />
                                        <Box mt={"5px"}>
                                          <Text as="span" fontSize={"15px"}>
                                            {format(new Date(assignment?.createdAt),"dd/MM/yyyy")}
                                          </Text>
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </CardHeader>
                                  <CardBody>
                                    <Text>{assignment?.content}</Text>
                                    <Flex flexWrap={"wrap"} alignItems={"center"}>
                                      <TableContainer>
                                          <Table variant="simple" style={{width: "100%", tableLayout: "fixed"}}>
                                              <Tbody>
                                                <Tr
                                                  bg={assignment.status === "Graded" ? assignment?.score >= 4 ? "green.100" : "red.100" : assignment.status === "Not graded" ? "yellow.100" : "red.100"}
                                                >
                                                    <Td>
                                                        Grade
                                                    </Td>
                                                    <Td>
                                                        {assignment.status === "Graded" ? `${assignment.score}/10` : assignment.status}
                                                    </Td>
                                                </Tr>
                                              </Tbody>
                                          </Table>
                                      </TableContainer>
                                    </Flex>
                                  </CardBody>
                                </Card>
                              </Box>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      ))
                    ) : (
                      <Flex
                        flexWrap="wrap"
                        justifyContent="space-between"
                        display="flex"
                        marginTop={"3vh"}
                      >
                        <Icon as={LoadingIcon} w={50} h={50} />
                      </Flex>
                    )}
                  </Accordion>
                </TabPanel>
              </Box>
            ) : (
              <TabPanel>
                <Flex 
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="center"
                  display="flex"
                > 
                  <Heading>Report</Heading>
                </Flex>
                {!isLoadingMyCcore ? (
                  <div>
                    {myScoreData != null ? (
                      <Flex>
                        <StudentScoreDetail isOpenStudentScore={isOpenStudentScore} onOpenStudentScore={onOpenStudentScore} onCloseStudentScore={onCloseStudentScore} initialRefStudentScore={initialRefStudentScore} finalRefStudentScore={finalRefStudentScore} student={Student}/>
                        <Table mt={"5vh"}>
                          <Thead>
                            <Th>
                              Student
                            </Th>
                            {myScoreData.map((item, index) => (
                              <Th key={index}>
                                Avg. {item.code}
                              </Th>
                            ))}
                          </Thead>
                          <Tbody>
                            {DataListScore.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {row.map((element, columnIndex) => {
                                  if(columnIndex === 0){
                                    return (
                                      <Td key={columnIndex}>
                                        <Text
                                          color={"rgb(88, 168, 168)"}
                                          _hover={{
                                            color: "rgb(88, 135, 168)",
                                            cursor: "pointer"
                                          }}
                                          onClick={() => {handleShowStudentScore(element)}}
                                        >
                                          {element.name}
                                        </Text>
                                      </Td>
                                    )
                                  } else {
                                    return <Td key={columnIndex}>{element != null ? element : "-"}</Td>
                                  }
                                })}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Flex>
                    ) : (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        height="100%"
                        marginBottom={"5vh"}
                      >
                        <Text fontSize={"20px"}>
                          <i>No records</i>
                        </Text>
                      </Flex>
                    )}
                  </div>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    justifyContent="space-between"
                    display="flex"
                    marginTop={"3vh"}
                  >
                    <Icon as={LoadingIcon} w={50} h={50} />
                  </Flex>
                )}
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </div>
    </Box>
  );
}
