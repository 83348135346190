import {
    Box,
    Flex,
    CircularProgress,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    useToast,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Grid,
    FormControl,
    FormLabel,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    useDisclosure,
    Progress,
    SkeletonText,
    Skeleton,
    ListItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import Pusher from "pusher-js";
import { format, set } from "date-fns";
import { Paginator } from "primereact/paginator";
import { Icon } from "@chakra-ui/icons";
import { IoSearchOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { HiLockOpen, HiLockClosed } from "react-icons/hi2";
import { MdDeleteForever } from "react-icons/md";
import { FaDivide, FaFileImport } from "react-icons/fa6";
import { FaRegFileLines, FaLock } from "react-icons/fa6";
import { MdAdd } from "react-icons/md";
import { ReactComponent as LoadingIcon } from "../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../icons/loading-logo.svg";
import { ref, uploadBytesResumable, getDownloadURL, getMetadata, listAll } from "firebase/storage";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";
import logoutAccount from "./FnGeneral";
import { CREATE_NOTIFICATION, DELETE_NOTIFICATION, EDIT_NOTIFICATION, GET_NOTIFICATION } from "../api/api";
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import '../css/NotificationDescription.css';
import { ContentState, convertFromHTML } from 'draft-js';
import storage from '../config/firebaseConfig';
import htmlToDraft from 'html-to-draftjs';
import { FiTrash2 } from "react-icons/fi";
import { FaRegEdit } from "react-icons/fa";

export default function Home(){
    const [NotificationData, setNotificationData] = useState([{}, {}, {}, {}, {}, {}]);
    const [NotificationDetail, setNotificationDetail] = useState({});
    const [isLoadingNotification, setIsLoadingNotification] = useState(true);
    const { isOpen: isOpenEditNotification, onOpen: onOpenEditNotification, onClose: onCloseEditNotification } = useDisclosure();
    const { initialRef: initialRefEditNotification, finalRef: finalRefEditNotification } = useRef(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen: isOpenCreateNotification, onOpen: onOpenCreateNotification, onClose: onCloseCreateNotification } = useDisclosure();
    const { initialRef: initialRefCreateNotification, finalRef: finalRefCreateNotification } = useRef(null);
    const { isOpen: isOpenNotificationDetail, onOpen: onOpenNotificationDetail, onClose: onCloseNotificationDetail } = useDisclosure();
    const { initialRef: initialRefNotificationDetail, finalRef: finalRefNotificationDetail } = useRef(null);
    const { isOpen: isOpenDeleteNotification, onOpen: onOpenDeleteNotification, onClose: onCloseDeleteNotification } = useDisclosure();
    const { initialRef: initialRefDeleteNotification, finalRef: finalRefDeleteNotification } = useRef(null);

    useEffect(() => {
        if(isLoadingNotification){
            setNotificationData([{}, {}, {}, {}, {}, {}]);
            const fetchData = async () => {
                const request = `page=${currentPage}`;
                try {
                    const token = localStorage.getItem("token");
                    const {isSuccess, res} = await GET_NOTIFICATION(token, request);
                    if(isSuccess){
                        const response = await res.json();
                        setNotificationData(response.data.listData);
                        setTotalRecords(response.data.totalRecords);
                        setTotalPage(response.data.totalPage);
                        setIsLoadingNotification(false);
                    } else {
                        if(res.status === 401){
                            logoutAccount();
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            fetchData();
        }
    }, [isLoadingNotification, currentPage]);

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength - 3) + '...';
        } else {
            return text;
        }
    }
    function extractTextFromHTML(htmlString) {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.childNodes[0].textContent || " ";
    }

    const handleEditNotification = (item) => {
        setNotificationDetail(item);
        onOpenEditNotification();
    }

    const handleNotificationDetail = (item) => {
        setNotificationDetail(item);
        onOpenNotificationDetail();
    }

    const handleDeleteNotification = (item) => {
        setNotificationDetail(item);
        onOpenDeleteNotification();
    }

    const onPageChange = (event) => {
        setCurrentPage(event.page + 1);
        setIsLoadingNotification(true);
    };

    return (
        <Flex
            display={{ base: "flex", md: "block" }}
            marginTop={"15vh"}
            justifyContent="center" // Để căn giữa theo chiều ngang
            alignItems="center" // Để căn giữa theo chiều dọc
        >
            <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
                <Heading as="h1" size="lg">
                    Welcome to AcademiX Learning Management System
                </Heading>
                <ViewNotificationDetail isOpenNotificationDetail={isOpenNotificationDetail} onOpenNotificationDetail={onOpenNotificationDetail} onCloseNotificationDetail={onCloseNotificationDetail} initialRefNotificationDetail={initialRefNotificationDetail} finalRefNotificationDetail={finalRefNotificationDetail} NotificationData={NotificationDetail}/>
                {localStorage.getItem("role") === "Admin" || localStorage.getItem("role") === "SUAdmin" ? (
                    <Flex>
                        <CreateNotification isOpenCreateNotification={isOpenCreateNotification} onOpenCreateNotification={onOpenCreateNotification} onCloseCreateNotification={onCloseCreateNotification} initialRefCreateNotification={initialRefCreateNotification} finalRefCreateNotification={finalRefCreateNotification} setIsLoadingNotification={setIsLoadingNotification}/>
                        <EditNotification isOpenEditNotification={isOpenEditNotification} onOpenEditNotification={onOpenEditNotification} onCloseEditNotification={onCloseEditNotification} initialRefEditNotification={initialRefEditNotification} finalRefEditNotification={finalRefEditNotification} NotificationData={NotificationDetail} setIsLoadingNotification={setIsLoadingNotification}/>
                        <DeleteNotification isOpenDeleteNotification={isOpenDeleteNotification} onOpenDeleteNotification={onOpenDeleteNotification} onCloseDeleteNotification={onCloseDeleteNotification} initialRefDeleteNotification={initialRefDeleteNotification} finalRefDeleteNotification={finalRefDeleteNotification} NotificationDetail={NotificationDetail} setIsLoadingNotification={setIsLoadingNotification}/>
                        <Button
                            onClick={onOpenCreateNotification}
                            mr={10}
                        >
                            Create notification
                        </Button>
                    </Flex>
                ) : null}
            </Flex>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                marginRight={20}
                marginTop={10}
            >
                {NotificationData && NotificationData.length > 0 ? (
                    <Box>
                        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
                            {NotificationData.map((item, index) => (
                                <Card
                                    width="500px"
                                    minHeight="250px"
                                    size={"md"}
                                    transition="transform 0.3s ease"
                                    _hover={{ transform: "scale(1.02)" }}
                                    key={index}
                                >
                                    <CardHeader>
                                            <SkeletonText
                                                isLoaded={!isLoadingNotification}
                                                height='40px'
                                                color='black'
                                                noOfLines={1}
                                                skeletonHeight='38px'
                                                fadeDuration={1}
                                            >
                                                <Flex
                                                    flexWrap={"wrap"}
                                                    justifyContent={"space-between"}
                                                    alignItems={"center"}
                                                >
                                                    <Heading size="md">
                                                        {!isLoadingNotification ? parse(truncateText(extractTextFromHTML(item.title), 30)) : null}
                                                    </Heading>
                                                    <Box>
                                                        {!isLoadingNotification ? (
                                                            <div>
                                                                {localStorage.getItem("id") === item.createdBy.id ? (
                                                                    <div>
                                                                        <Button onClick={() => {handleEditNotification(item)}} mr={"5px"}>
                                                                            <Icon fontSize="20px" as={FaRegEdit} />
                                                                        </Button>
                                                                        <Button onClick={() => {handleDeleteNotification(item)}}>
                                                                            <Icon fontSize="20px" as={FiTrash2} />
                                                                        </Button>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ): null}
                                                    </Box>
                                                </Flex>
                                            </SkeletonText>
                                    </CardHeader>
                                    <CardBody>
                                        <SkeletonText
                                            noOfLines={2}
                                            isLoaded={!isLoadingNotification}
                                            height='40px'
                                            skeletonHeight='18px'
                                            color='black'
                                            fadeDuration={1}
                                        >
                                            <Text>
                                                {!isLoadingNotification ? parse(truncateText(extractTextFromHTML(item.description), 90)) : null}
                                            </Text>
                                            <Text
                                                color={"blue.500"}
                                                _hover={{ textDecoration: "underline", color: "blue.900", cursor: "pointer"}}
                                                onClick={() => handleNotificationDetail(item)}
                                            >
                                                Read more
                                            </Text>
                                        </SkeletonText>
                                    </CardBody>
                                    <CardFooter>
                                        <SkeletonText isLoaded={!isLoadingNotification}>
                                            <Text
                                                color={"gray.600"}
                                            >
                                                {!isLoadingNotification ? (<div>{format(new Date(item.createdAt), "dd/MM/yyyy, HH:mm")} <span>•</span><b>{" "}{item?.createdBy.name}</b></div>): null}
                                            </Text>
                                        </SkeletonText>
                                    </CardFooter>
                                </Card>
                            ))}
                        </Grid>
                        {!isLoadingNotification ? (
                            <Flex
                                flexWrap="wrap"
                                justifyContent="space-between"
                                display="flex"
                                alignItems={"center"}
                            >
                                <Box></Box>
                                <Flex
                                    marginTop={"4vh"}
                                >
                                    <Paginator
                                    first={currentPage}
                                    rows={6}
                                    totalRecords={totalRecords}
                                    onPageChange={onPageChange}
                                    template={{
                                        layout: "PrevPageLink PageLinks NextPageLink",
                                    }}
                                    className="p-mt-4"
                                    />
                                </Flex>
                                <Flex>
                                    <Box
                                    marginTop={"3vh"}
                                    marginRight={"15vh"}
                                    bg="rgb(225, 232, 237)"
                                    w="100px"
                                    h="50px"
                                    display={"flex"}
                                    borderRadius={20}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    >
                                    <Text>
                                        {currentPage} of {totalPage}
                                    </Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        ) : null}
                    </Box>
                ) : (
                    <Text>
                        No notifications
                    </Text>
                )}
            </Box>
        </Flex>
    );
}

function ViewNotificationDetail({ isOpenNotificationDetail, onOpenNotificationDetail, onCloseNotificationDetail, initialRefNotificationDetail, finalRefNotificationDetail, NotificationData }) {
    const toast = useToast();
    const quillRef = useRef(null);
    const [description, setDescription] = useState('');
    const downloadFile = async (filePath, fileName) => {
        try {
          // Get download URL for the file from Firebase Storage
          const fileRef = ref(storage, `${filePath}/${fileName}`); // Create a reference to the file
    
          // Get download URL for the file from Firebase Storage
          const downloadURL = await getDownloadURL(fileRef);
    
          // Now download the file using downloadURL
          // For example, you can use fetch API to download the file
          const response = await fetch(downloadURL);
          if (response.ok) {
            // Convert response to blob
            const blob = await response.blob();
            // Create a link element to download the file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName; // You can set custom file name here
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            console.error("Failed to download file:", response.statusText);
          }
        } catch (error) {
          console.error("Error downloading file:", error);
        }
    };
    return (
        <Modal
            initialFocusRef={initialRefNotificationDetail}
            finalFocusRef={finalRefNotificationDetail}
            isOpen={isOpenNotificationDetail}
            onClose={onCloseNotificationDetail}
            size="3xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><Heading size="lg" mt={"20px"} ml={"10px"} maxWidth={"600px"}>{isOpenNotificationDetail ? NotificationData.title : null}</Heading></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} minHeight={"50vh"}>
                    <Box className="NotificationDescription">{isOpenNotificationDetail ? parse(NotificationData.description) : null}</Box>
                    {isOpenNotificationDetail ? (
                        <div>
                            {NotificationData.attachment != null ? (
                                <Box
                                    border={"1px"}
                                    borderRadius={"10px"}
                                    borderColor={"#EDF2F7"}
                                    mt={"20px"}
                                >
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td>
                                                    <Flex
                                                        alignItems={"center"}
                                                        onClick={() =>
                                                            downloadFile(
                                                                `notification/${NotificationData.id}`,
                                                                NotificationData.attachment
                                                            )
                                                        }
                                                        color={"#548ed1"}
                                                        _hover={{
                                                        color: "#787878",
                                                        cursor: "pointer",
                                                        }}
                                                    >
                                                        <Icon
                                                        as={FaRegFileLines}
                                                        mr={"10px"}
                                                        />
                                                        <Text
                                                            mt={"5px"}
                                                        >
                                                            {NotificationData.attachment}
                                                        </Text>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </Box>
                            ) : null}
                        </div>
                    ) : null}
                    <ModalFooter
                        mt={"25vh"}
                        display="flex"
                        justifyContent="end"
                    >
                        <Button onClick={onCloseNotificationDetail}>Close</Button>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function CreateNotification({ isOpenCreateNotification, onOpenCreateNotification, onCloseCreateNotification, initialRefCreateNotification, finalRefCreateNotification, setIsLoadingNotification}) {
    const toast = useToast();
    const quillRef = useRef(null);
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().min(20, "Must be at least 20 characters").required("Required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            if (token) {
                try {
                    const formData = new FormData();
                    formData.append("title", values["title"]);
                    formData.append("description", description);
                    if(selectedFile){
                        formData.append("file", selectedFile);
                    }
                    const {isSuccess, res} = await CREATE_NOTIFICATION(token, formData);
                    if(isSuccess){
                        const response = await res.json();
                        setIsSubmitting(false);
                        toast({
                            title: `Create notification successfully`,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        setIsLoadingNotification(true);
                        handleClose();
                    } else {
                        toast({
                            title: `Error: ${res.statusText}`,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                        if(res.status === 401){
                            logoutAccount();
                        }
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsSubmitting(false);
                }
            }
        },
    });

    const handleEditorChange = (content, delta, source, editor) => {
        setDescription(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],[{ 'align': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ]
    };

    const handleClose = () => {
        formik.resetForm();
        setSelectedFile(null);
        onCloseCreateNotification();
    }

    const handleFileChange = (event) => {
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = () => {
        setSelectedFile(null);
        inputRef.current.value = null;
    };
    
    const customCSS = `
    .ql-editor {
        font-size: 18px !important;
    }`;

    return (
        <Modal
            initialFocusRef={initialRefCreateNotification}
            finalFocusRef={finalRefCreateNotification}
            isOpen={isOpenCreateNotification}
            onClose={onCloseCreateNotification}
            size="3xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create Notification</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <FormControl id="title" isInvalid={formik.touched.title && formik.errors.title}>
                                <FormLabel>Title</FormLabel>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="description">
                                <FormLabel>Description</FormLabel>
                                <style>{customCSS}</style>
                                <ReactQuill
                                    ref={quillRef}
                                    theme="snow"
                                    modules={modules}
                                    placeholder="Write here..."
                                    style={{height: "28vh", width: "100%"}}
                                    onChange={handleEditorChange}
                                />
                                <Input type="hidden" name="description" value={description} /> {/* Trường input ẩn để lưu nội dung của Quill */}
                            </FormControl>
                            <FormControl id="attachment" mt="5vh">
                                <FormLabel>Attachment</FormLabel>
                                <Box display="flex" justifyContent="center">
                                    <Input
                                        type="file"
                                        ref={inputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <Button className="file-btn" onClick={onChooseFile} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                        <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                            <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                        </span>{" "}
                                            Upload File
                                    </Button>
                                </Box>
                                {selectedFile && (
                                    <div className="selected-file">
                                    <p>{selectedFile.name}</p>

                                    <Button onClick={removeFile}>
                                        <span class="material-symbols-rounded">
                                        <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                                        </span>
                                    </Button>
                                    </div>
                                )}
                            </FormControl>
                            <Box marginTop={"10px"}>
                                <Box marginTop={"10px"}>
                                    {isSubmitting ? (
                                        <Progress borderRadius={"full"} hasStripe value={100} isAnimated/>
                                    ) : null}
                                </Box>
                            </Box>
                        </Stack>
                        <ModalFooter
                            display="flex"
                            justifyContent="end"
                        >
                            <Button colorScheme='green' mr={3} type="submit" isDisabled={isSubmitting}>
                                {isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Create"}
                            </Button>
                            <Button onClick={handleClose} isDisabled={isSubmitting}>Cancel</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    );
}

function EditNotification({ isOpenEditNotification, onOpenEditNotification, onCloseEditNotification, initialRefEditNotification, finalRefEditNotification, NotificationData, setIsLoadingNotification}) {
    const toast = useToast();
    const quillRef = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [description, setDescription] = useState('');
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().min(20, "Must be at least 20 characters").required("Required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            if (token) {
                try {
                    const formData = new FormData();
                    formData.append("title", values["title"]);
                    formData.append("description", description);
                    if(selectedFile){
                        formData.append("file", selectedFile);
                    }
                    const {isSuccess, res} = await EDIT_NOTIFICATION(token, NotificationData.id, formData);
                    if(isSuccess){
                        const response = await res.json();
                        setIsSubmitting(false);
                        toast({
                            title: `Update notification successfully`,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        setIsLoadingNotification(true);
                        handleClose();
                    } else {
                        toast({
                            title: `Error: ${res.statusText}`,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                        if(res.status === 401){
                            logoutAccount();
                        }
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsSubmitting(false);
                }
            }
        },
    });

    const handleEditorChange = (content, delta, source, editor) => {
        // Cập nhật giá trị của trường input ẩn khi nội dung của Quill thay đổi
        setDescription(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],[{ 'align': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link']
        ]
    };

    const handleClose = () => {
        formik.resetForm();
        onCloseEditNotification();
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = () => {
        setSelectedFile(null);
        inputRef.current.value = null;
    };
    
    const customCSS = `
    .ql-editor {
        font-size: 18px !important;
    }`;

    useEffect(() => {
        if(isOpenEditNotification){
            fetchFile();
            formik.setFieldValue("title", NotificationData.title);
            setDescription(NotificationData.description);
        }
    }, [isOpenEditNotification]);

    const fetchFile = async () => {
        setIsFetchingFiles(true);
        try {
            const folderRef = ref(storage, `notification/${NotificationData.id}`);
            const listResult = await listAll(folderRef);
            const files = [];
            await Promise.all(listResult.items.map(async (itemRef) => {
                const metadata = await getMetadata(itemRef);
                const downloadURL = await getDownloadURL(itemRef);
                const response = await fetch(downloadURL);
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: metadata.contentType });
                const file = new File([blob], metadata.name, { type: metadata.contentType });
                files.push(file);
            }));
            setIsFetchingFiles(false);
            setSelectedFile(files[0]);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch files",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal
            initialFocusRef={initialRefEditNotification}
            finalFocusRef={finalRefEditNotification}
            isOpen={isOpenEditNotification}
            onClose={onCloseEditNotification}
            size="3xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Notification</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <FormControl id="title" isInvalid={formik.touched.title && formik.errors.title}>
                                <FormLabel>Title</FormLabel>
                                <Input
                                    id="title"
                                    name="title"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                />
                                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="description">
                                <FormLabel>Description</FormLabel>
                                <style>{customCSS}</style>
                                <ReactQuill
                                    ref={quillRef}
                                    theme="snow"
                                    modules={modules}
                                    value={description}
                                    placeholder="Write here..."
                                    style={{height: "28vh", width: "100%"}}
                                    onChange={handleEditorChange}
                                />
                                <Input type="hidden" name="description" value={description} /> {/* Trường input ẩn để lưu nội dung của Quill */}
                            </FormControl>
                            <FormControl id="attachment" mt="5vh">
                                <FormLabel>Attachment</FormLabel>
                                <Box display="flex" justifyContent="center">
                                    <Input
                                        type="file"
                                        ref={inputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <Button className="file-btn" onClick={onChooseFile} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                        <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                            <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                        </span>{" "}
                                            Upload File
                                    </Button>
                                </Box>
                                {selectedFile && (
                                    <div className="selected-file">
                                        <p>{selectedFile.name}</p>

                                        <Button onClick={removeFile}>
                                            <span class="material-symbols-rounded">
                                            <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </FormControl>
                            <Box marginTop={"10px"}>
                                <Box marginTop={"10px"}>
                                    {isSubmitting ? (
                                        <Progress borderRadius={"full"} hasStripe value={100} isAnimated/>
                                    ) : null}
                                </Box>
                            </Box>
                        </Stack>
                        <ModalFooter
                            display="flex"
                            justifyContent="end"
                        >
                            <Button colorScheme='green' mr={3} type="submit" isDisabled={isSubmitting}>
                                {isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Save"}
                            </Button>
                            <Button onClick={handleClose} isDisabled={isSubmitting}>Cancel</Button>
                        </ModalFooter>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    );
}

function DeleteNotification({ isOpenDeleteNotification, onOpenDeleteNotification, onCloseDeleteNotification, initialRefDeleteNotification, finalRefDeleteNotification, NotificationDetail, setIsLoadingNotification}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleDeleteNotification = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await DELETE_NOTIFICATION(token, NotificationDetail.id);
                if (response.isSuccess) {
                    onCloseDeleteNotification();
                    toast({
                        title: `Delete ${NotificationDetail.title} successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoadingNotification(true);
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    return (
        <Modal
            initialFocusRef={initialRefDeleteNotification}
            finalFocusRef={finalRefDeleteNotification}
            isOpen={isOpenDeleteNotification}
            onClose={onCloseDeleteNotification}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Notification</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    Are you sure to delete <strong>{isOpenDeleteNotification ? NotificationDetail.title : null }</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={handleDeleteNotification} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Delete"}</Button>
                    <Button onClick={onCloseDeleteNotification} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}