import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
  useDisclosure,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  ButtonGroup,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { format, set } from "date-fns";
import { Paginator } from "primereact/paginator";
import { Icon } from "@chakra-ui/icons";
import { IoSearchOutline } from "react-icons/io5";
import { HiLockOpen, HiLockClosed } from "react-icons/hi2";
import "../../css/Text.css";
import { MdAdd } from "react-icons/md";
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ActiveUser, DeactiveUser } from "../User/Function/UserFunction";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";
import { GET_LIST_USER, CREATE_ACCOUNT, GET_LIST_CLASS_NO_TRAINER, ASSIGN_TRAINER_TO_CLASS, GET_LIST_TRAINER } from "../../api/api";

function AddNewUser({
  isOpenAdd,
  onOpenAdd,
  onCloseAdd,
  initialRef,
  finalRef,
  setloadForm,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      dob: "",
      address: "",
      gender: "",
      role: "",
      phone: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      dob: Yup.date().required("Required"),
      address: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
      role: Yup.string().required("Required"),
      phone: Yup.string()
        .matches("^(0|\\+84)(\\d{8,9})$", "Wrong phone format")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        console.log(values);
        const response = await CREATE_ACCOUNT(values, token);
        if (response.isSuccess) {
          toast({
            title: "Create account successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCloseAdd();
          formik.resetForm();
          setloadForm(false);
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  function handleCloseModal() {
    formik.resetForm();
    onCloseAdd();
  }
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpenAdd}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New user</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Fullname</FormLabel>
              <Input
                ref={initialRef}
                name="fullName"
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.fullName && formik.touched.fullName && (
              <p style={{ color: "red" }}>{formik.errors.fullName}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input type="text" name="email" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.email && formik.touched.email && (
              <p style={{ color: "red" }}>{formik.errors.email}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Date of birth</FormLabel>
              <Input type="date" name="dob" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.dob && formik.touched.dob && (
              <p style={{ color: "red" }}>{formik.errors.dob}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                type="text"
                onChange={formik.handleChange}
              />
            </FormControl>
            {formik.errors.address && formik.touched.address && (
              <p style={{ color: "red" }}>{formik.errors.address}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                placeholder="Select gender"
                onChange={formik.handleChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </FormControl>
            {formik.errors.gender && formik.touched.gender && (
              <p style={{ color: "red" }}>{formik.errors.gender}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Select
                name="role"
                placeholder="Select role"
                onChange={formik.handleChange}
              >
                <option value="Admin">Admin</option>
                <option value="Trainer">Trainer</option>
              </Select>
            </FormControl>
            {formik.errors.role && formik.touched.role && (
              <p style={{ color: "red" }}>{formik.errors.role}</p>
            )}
            <FormControl mt={4}>
              <FormLabel>Phone</FormLabel>
              <Input name="phone" type="text" onChange={formik.handleChange} />
            </FormControl>
            {formik.errors.phone && formik.touched.phone && (
              <p style={{ color: "red" }}>{formik.errors.phone}</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseAdd} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function AddNewTrainerIntoClass({isOpenAddTrainer, onOpenAddTrainer, onCloseAddTrainer, initialRefAddTrainer, finalRefAddTrainer, classId, setloadForm}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("token");
  const [listTrainer, setListTrainer] = useState([]);
  const toast = useToast();
  const [checkedItems, setCheckedItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
    formik.setFieldValue("trainerId", itemId);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsLoading(true);
  };

  const formik = useFormik({
    initialValues: {
      classId: classId,
      trainerId: "",
    },
    validationSchema: Yup.object({
      classId: Yup.string().required("Required"),
      trainerId: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const jsonValue = {
        trainerId: values["trainerId"],
      }
      try {
        console.log(values);
        const response = await ASSIGN_TRAINER_TO_CLASS(token, values["classId"], jsonValue);
        if (response.isSuccess) {
          toast({
            title: "Add trainer into class successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setloadForm(false);
          setCheckedItems({});
          onCloseAddTrainer();
          formik.resetForm();
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Error",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if(isLoading){
      const fetchData = async () => {
        const {isSuccess, res} = await GET_LIST_TRAINER(token);
        if (isSuccess) {
          const response = await res.json();
          setIsLoading(false);
          setListTrainer(response.data.listData);
          setTotalRecords(response.data.totalRecords);
          setTotalPage(response.data.totalPage);
        } else {
          const response = await res.json();
          if (response.responseFailed != null) {
            toast({
              title: "Error",
              description: response.responseFailed,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else if (response.message != null) {
            toast({
              title: "Error",
              description: response.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      }
      fetchData();
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isOpenAddTrainer]);

  function handleCloseModal() {
    formik.resetForm();
    onCloseAddTrainer();
  }

  return (
    <Modal
      initialFocusRef={initialRefAddTrainer}
      finalFocusRef={finalRefAddTrainer}
      isOpen={isOpenAddTrainer}
      onClose={handleCloseModal}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Trainer into Class</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          {!isLoading ? (
            <ModalBody pb={6}>
              <TableContainer>
                <Table variant="simple" className="Table">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>Name</Th>
                      <Th>Email</Th>
                      <Th>Phone</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {listTrainer &&
                      listTrainer.map((item, index) => (
                        <Tr key={item?.id}>
                          <Td>
                            <Checkbox 
                              key={item?.id}
                              value={item?.id}
                              isChecked={checkedItems[item?.id]}
                              onChange={handleCheck(item?.id)}
                              isDisabled={countChecked() === 1 && !checkedItems[item?.id]}
                            />
                          </Td>
                          <Td>
                            <Link to={`/user/id/${item?.id}`}>
                              <Text
                                color={"#2980a5"}
                                _hover={{
                                  color: "rgb(6, 61, 100)",
                                }}
                              >
                                {item?.fullName}
                              </Text>
                            </Link>
                          </Td>
                          <Td>{item?.email}</Td>
                          <Td>{item?.phone}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Flex
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <Flex></Flex>
                <Flex
                  marginLeft={"15vh"}
                >
                  <Paginator
                    first={currentPage}
                    rows={10}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                    template={{
                      layout: "PrevPageLink PageLinks NextPageLink",
                    }}
                    className="p-mt-4"
                  />
                </Flex>
                <Flex>
                  <Box
                    marginTop={"2vh"}
                    marginRight={"5vh"}
                    bg="rgb(225, 232, 237)"
                    w="100px"
                    display={"flex"}
                    borderRadius={20}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text>
                      {currentPage} of {totalPage}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </ModalBody>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={"1vh"}
            >
              <Icon as={LoadingIcon} w={50} h={50} />
            </Box>
          )}
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isDisabled={isSubmitting || countChecked() !== 1}
            >
              {isSubmitting ? (
                <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
              ) : (
                "Save"
              )}
            </Button>
            <Button onClick={onCloseAddTrainer} isDisabled={isSubmitting}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export function UserList() {
  const location = useLocation();
  const filterParams = new URLSearchParams(location.search);
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure();
  const {
    isOpen: isOpenDeactive,
    onOpen: onOpenDeactive,
    onClose: onCloseDeactive,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const { initialRefFilter, finalRefFilter } = useRef(null);
  const { initialRef: initialRefAdd, finalRef: finalRefAdd } = useRef(null);
  const { initialRef: initialRefActive, finalRef: finalRefActive } =
    useRef(null);
  const { initialRef: initialRefDeactive, finalRef: finalRefDeactive } =
    useRef(null);
  const [UsersData, setUsersData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [indexTab, setIndexTab] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [loadForm, setloadForm] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [roleFilter, setRoleFilter] = useState("Admin");
  const roleArray = ["Admin", "Trainer"];
  const navigate = useNavigate();
  const toast = useToast();
  const formikFilter = useFormik({
    initialValues: {
      gender: filterParams.get("gender") || "",
      status: filterParams.get("status") || "",
    },
    validationSchema: Yup.object().shape({
      gender: Yup.string().nullable(),
      status: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      for (const param in values) {
        if (values[param]) {
          // Kiểm tra giá trị filterParams[param] có tồn tại không
          filterParams.set(param, values[param]);
        } else {
          filterParams.delete(param);
        }
      }
      if (Object.keys(filterParams.toString()).length === 0) {
        filterParams.forEach((value, key) => {
          filterParams.delete(key);
        });
        formikFilter.setFieldValue("gender", "");
        formikFilter.setFieldValue("status", "");
        onCloseFilter();
        setIsFilter(false);
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
        navigate(`${location.pathname}`);
      } else {
        navigate(`${location.pathname}?${filterParams.toString()}`);
        onCloseFilter();
        setIsFilter(true);
        setIsLoading(true);
        setloadForm(false);
        setCurrentPage(1);
      }
    },
  });

  const countChecked = () => {
    return Object.values(checkedItems).filter((value) => value).length;
  };

  useEffect(() => {
    if(isLoading){
      let request = `role=${roleFilter}`;
      if (Object.keys(filterParams.toString()).length !== 0) {
        setIsFilter(true);
        request += `&${filterParams.toString()}&page=${currentPage}`;
      } else {
        request += `&page=${currentPage}`;
      }
      const token = localStorage.getItem("token");
      const fetchData = async () => {
        try {
          const response = await GET_LIST_USER(token, request);
          if (response.isSuccess) {
            setTotalRecords(response.data.totalRecords);
            setUsersData(response.data.listData);
            setTotalPage(response.data.totalPage);
            setCurrentPage(response.data.page);
            setIsLoading(false);
          } else if (response.responseFailed != null) {
            toast({
              title: "Error",
              description: response.responseFailed,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            filterParams.forEach((value, key) => {
              filterParams.delete(key);
            });
            formikFilter.setFieldValue("gender", "");
            formikFilter.setFieldValue("status", "");
            onCloseFilter();
            setIsFilter(false);
            setIsLoading(true);
            setloadForm(false);
            setCurrentPage(1);
            navigate(`${location.pathname}`);
          } else if (response.message != null && response.data == null) {
            setUsersData([]);
            setTotalRecords(0);
            setCurrentPage(1);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      
      fetchData();
    }
  }, [roleFilter, isLoading]);

  function handleClearFilter() {
    filterParams.forEach((value, key) => {
      filterParams.delete(key);
    });
    formikFilter.setFieldValue("gender", "");
    formikFilter.setFieldValue("status", "");
    console.log(filterParams.get("gender"));
    navigate(`${location.pathname}`);
    setIsLoading(true);
    setCurrentPage(1);
    onCloseFilter();
    setIsFilter(false);
    setloadForm(false);
  }

  const handleCheck = (itemId) => (event) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: event.target.checked,
    }));
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    setIsLoading(true);
    setloadForm(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleTabChange = (index) => {
    setRoleFilter(roleArray[index]);
    setCheckedItems({});
    setIndexTab(index);
    setIsLoading(true);
    // Bất kỳ logic bổ sung nào bạn muốn thực hiện khi tab thay đổi
  };

  return (
    <Box
      display={{ base: "flex", md: "block" }}
      marginTop={"15vh"}
      justifyContent="flex"
      alignItems="left"
    >
      <div>
        <AddNewUser
          isOpenAdd={isOpenAdd}
          onOpenAdd={onOpenAdd}
          onCloseAdd={onCloseAdd}
          initialRefAdd={initialRefAdd}
          finalRefAdd={finalRefAdd}
          setloadForm={setloadForm}
        />
        <ActiveUser
          isOpenActive={isOpenActive}
          onOpenActive={onOpenActive}
          onCloseActive={onCloseActive}
          initialRefActive={initialRefActive}
          finalRefActive={finalRefActive}
          data={checkedItems}
          setIsLoading={setIsLoading}
          setloadForm={setloadForm}
          setCheckedItems={setCheckedItems}
        />
        <DeactiveUser
          isOpenDeactive={isOpenDeactive}
          onOpenDeactive={onOpenDeactive}
          onCloseDeactive={onCloseDeactive}
          initialRefDeactive={initialRefDeactive}
          finalRefDeactive={finalRefDeactive}
          data={checkedItems}
          setIsLoading={setIsLoading}
          setloadForm={setloadForm}
          setCheckedItems={setCheckedItems}
        />
        <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
          <Heading>Manager</Heading>
          <Flex
            style={{
              marginRight:
                localStorage.getItem("role") === "SUAdmin" ? "0" : "86px",
            }}
          >
            <InputGroup>
              <Input
                type="text"
                border="1px"
                placeholder="Search..."
                borderColor="#3BABE0"
                value={searchTerm}
                onChange={handleSearchChange}
                width={"30vh"}
                height={"41.5px"}
                marginRight={"1.1vh"}
                borderRadius={"6.5px"}
              />
              <InputRightElement h={"full"}>
                <Button
                  display={"flex"}
                  variant={"ghost"}
                  marginRight={"33px"}
                  marginBottom={"2px"}
                >
                  <Icon
                    borderRadius="1px"
                    fontSize="20px"
                    color={"#3BABE0"}
                    as={IoSearchOutline}
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
            <Popover
              initialFocusRef={initialRefFilter}
              finalFocusRef={finalRefFilter}
              isOpen={isOpenFilter}
              // onClose={handleCloseModal}
            >
              <PopoverTrigger>
                <Button
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"5vh"}
                  bg={"#3BABE0"}
                  _hover={{
                    boxShadow: "xl",
                  }}
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                >
                  {isFilter ? (
                    <Icon fontSize="20px" as={RiFilterFill} />
                  ) : (
                    <Icon fontSize="20px" as={RiFilterLine} />
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton
                  onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
                />
                <PopoverHeader>Filter option</PopoverHeader>
                <PopoverBody>
                  <form onSubmit={formikFilter.handleSubmit}>
                    <FormControl>
                      <FormLabel>Gender</FormLabel>
                      <Select
                        name="gender"
                        value={formikFilter.values.gender}
                        onChange={formikFilter.handleChange}
                        placeholder="Select gender"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Status</FormLabel>
                      <Select
                        name="status"
                        value={formikFilter.values.status}
                        onChange={formikFilter.handleChange}
                        placeholder="Select status"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Select>
                    </FormControl>
                    <PopoverFooter
                      border="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      pt={4}
                    >
                      <Box></Box>
                      <ButtonGroup size="sm">
                        {/* <FilterButton filterParams={{ gender: genderFilter, status: statusFilter }} setloadForm={setloadForm} setGenderFilter={setGenderFilter} setStatusFilter={setStatusFilter}/> */}
                        {isFilter ? (
                          <Button
                            colorScheme="red"
                            color={"white"}
                            size={"md"}
                            onClick={handleClearFilter}
                            marginRight={"10px"}
                          >
                            Clear
                          </Button>
                        ) : null}
                        <Button
                          bgColor={"rgb(99, 122, 138);"}
                          color={"white"}
                          size={"md"}
                          type="submit"
                        >
                          Apply
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </form>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {localStorage.role === "SUAdmin" ? (
              <Flex>
                <Button
                  onClick={onOpenActive}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"15vh"}
                  bgColor={"green.400"}
                  _hover={{
                    bgColor: "green.300",
                    boxShadow: "xl",
                  }}
                  isDisabled={countChecked() === 0}
                >
                  <Icon fontSize="20px" as={HiLockOpen} marginRight={"10px"} />{" "}
                  Activate
                </Button>
                <Button
                  onClick={onOpenDeactive}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="1vh"
                  color={"white"}
                  minWidth={"15vh"}
                  bgColor={"red.400"}
                  _hover={{
                    bgColor: "red.300",
                    boxShadow: "xl",
                  }}
                  isDisabled={countChecked() === 0}
                >
                  <Icon
                    fontSize="20px"
                    as={HiLockClosed}
                    marginRight={"10px"}
                  />{" "}
                  Deactivate
                </Button>
                <Button
                  onClick={onOpenAdd}
                  size="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight="10vh"
                  _hover={{
                    boxShadow: "xl",
                  }}
                >
                  <Icon fontSize="20px" as={MdAdd} /> Add new user
                </Button>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Tabs
          isFitted
          variant="enclosed"
          onChange={handleTabChange}
          marginTop={"3vh"}
        >
          <TabList mb="1em">
            <Tab id={"Admin"}>Admin</Tab>
            <Tab value={"Trainer"}>Trainer</Tab>
          </TabList>
          <TabPanels>
            {!isLoading ? (
              <div>
                {UsersData.length !== 0 ? (
                  <Box>
                    <Flex marginTop={"5vh"}>
                      <TableContainer minWidth={"90%"}>
                        <Table variant="simple" className="Table">
                          <Thead>
                            <Tr>
                              <Th></Th>
                              <Th>Name</Th>
                              <Th>Email</Th>
                              <Th>Address</Th>
                              <Th>Dob</Th>
                              <Th>Gender</Th>
                              <Th>Phone</Th>
                              <Th>Status</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {UsersData &&
                              UsersData.map((item, index) => (
                                <Tr key={item?.id}>
                                  <Td>
                                    <Checkbox
                                      key={item?.id}
                                      value={item?.id}
                                      isChecked={checkedItems[item?.id]}
                                      onChange={handleCheck(item?.id)}
                                    >
                                      {/* Checkbox label */}
                                    </Checkbox>
                                  </Td>
                                  <Td>
                                    <Link to={`/user/id/${item?.id}`}>
                                      <Text
                                        color={"#2980a5"}
                                        _hover={{
                                          color: "rgb(6, 61, 100)",
                                        }}
                                      >
                                        {item?.fullName}
                                      </Text>
                                    </Link>
                                  </Td>
                                  <Td>{item?.email}</Td>
                                  <Td>{item?.address}</Td>
                                  <Td>
                                    {format(new Date(item?.dob), "dd/MM/yyyy")}
                                  </Td>
                                  <Td>{item?.gender}</Td>
                                  <Td>{item?.phone}</Td>
                                  <Td>
                                    {item?.status === "Active" ? (
                                      <Badge
                                        variant="solid"
                                        colorScheme="green"
                                      >
                                        {item?.status}
                                      </Badge>
                                    ) : (
                                      <Badge variant="solid" colorScheme="red">
                                        {item?.status}
                                      </Badge>
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Flex>
                    <Flex
                      flexWrap="wrap"
                      justifyContent="space-between"
                    >
                      <Flex></Flex>
                      <Flex>
                        <Paginator
                          first={currentPage}
                          rows={10}
                          totalRecords={totalRecords}
                          onPageChange={onPageChange}
                          template={{
                            layout: "PrevPageLink PageLinks NextPageLink",
                          }}
                          className="p-mt-4"
                        />
                      </Flex>
                      <Flex>
                        <Box
                          marginTop={"2vh"}
                          marginRight={"15vh"}
                          bg="rgb(225, 232, 237)"
                          w="100px"
                          display={"flex"}
                          borderRadius={20}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Text>
                            {currentPage} of {totalPage}
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                ) : (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    marginBottom={"5vh"}
                    marginTop={"5vh"}
                  >
                    <Text fontSize={"20px"}>
                      <i>No record match</i>
                    </Text>
                  </Flex>
                )}
              </div>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={"5vh"}
                marginRight={"18vh"}
              >
                <Icon as={LoadingIcon} w={100} h={100} />
              </Box>
            )}
          </TabPanels>
        </Tabs>
      </div>
      {/* </div> */}
    </Box>
  );
}
