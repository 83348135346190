import { useEffect, useState } from "react";
import HeaderCompoent from "./Header/Header";
import { Box, chakra, SimpleGrid, Button, Stack } from "@chakra-ui/react";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import SideBarComponent from "./SideBar/SideBar";
import { jwtDecode } from 'jwt-decode';
import logoutAccount from "./FnGeneral";
import { set } from "date-fns";

export default function FrameComponent() {
  const [loadForm, setLoadForm] = useState(false);
  const navigate = useNavigate();

  function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    if (!decodedToken.hasOwnProperty('exp')) {
        return false; // JWT không có trường exp, không xác định được hạn của token
    }

    const expirationTime = new Date(decodedToken.exp).getTime();
    const currentTime = new Date().getTime();

    return expirationTime < currentTime;
  }
  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("token");
      if (!isTokenExpired(token) || token === null) {
        logoutAccount();
        navigate("/");
        setLoadForm(true);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [loadForm]);

  return (
    <>
      <HeaderCompoent />
      <div className="grid">
        <div className="col-2">
          <SideBarComponent />
        </div>
        <div className="col">
          <Outlet />
        </div>
      </div>
      <style jsx="true">
        {`
          .grid {
            display: grid;
            grid-template-columns: 200px 1fr; /* Fixed size for sidebar and auto-adjust for the rest */
            gap: 20px; /* Spacing between columns */
          }

          .col {
            padding-left: 80px; /* Spacing between Sidebar and Outlet */
          }
          @media (max-width: 768px) {
            .grid {
              grid-template-columns: 100%; /* When screen size is less than 768px, switch to single-column display */
            }
          }
        `}
      </style>
    </>
  );
}
