import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    useToast,
    Box,
    Input,
    Progress,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    color,
    background,
} from "@chakra-ui/react";

import { useState, useRef, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL, listAll, getMetadata } from "firebase/storage";
import { Icon } from "@chakra-ui/icons";
import { format, set } from 'date-fns';
import { FiUpload } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { ReactComponent as LoadingIcon } from '../../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../../icons/loading-logo.svg";
import { ADD_LECTURE, DELETE_LECTURE, UPDATE_LECTURE } from "../../../api/api";
import { json, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import storage from '../../../config/firebaseConfig';

export function CreateLecture({ isOpenCreateLecture, onOpenCreateLecture, onCloseCreateLecture, initialRefCreateLecture, finalRefCreateLecture, ClassData, ModuleData, setIsLoadingLecture}) {
    const toast = useToast();
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [ClassLoadedData, setClassLoadedData] = useState([]);
    const [ModuleLoadedData, setModuleLoadedData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: {
            Order: 0,
            ModuleId: ModuleLoadedData.id,
            ClassId: ClassLoadedData.id,
            Name: "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("Name is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            try{
                const token = localStorage.getItem("token");
                var formData = new FormData();
                formData.append("ClassId", ClassLoadedData.id);
                formData.append("ModuleId", ModuleLoadedData.id);
                for (const param in values) {
                    if (values[param]) {
                        formData.append(param, values[param]);
                    }
                }
                selectedFile.forEach((file, index) => {
                    formData.append(`Files`, file);
                });
                const {isSuccess, res} = await ADD_LECTURE(token, formData);
                if (isSuccess) {
                    const response = await res.json();
                    toast({
                        title: `Add lecture successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setIsLoadingLecture(true);
                    closeModal();
                } else {
                    toast({
                        title: `Add lecture failed`,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsSubmitting(false);
            }
        }
    });

    useEffect(() => {
        setClassLoadedData(ClassData);
        setModuleLoadedData(ModuleData);
    }, [isOpenCreateLecture, ClassData, ModuleData]);

    const handleFileChange = event => {
        const files = Array.from(event.target.files);
        setSelectedFile(files);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = (indexToRemove) => {
        setSelectedFile(prevFiles => {
            // Lọc ra các phần tử khác phần tử có index bằng indexToRemove
            const updatedFiles = prevFiles.filter((file, index) => index !== indexToRemove);
            return updatedFiles;
        });
    };

    const closeModal = () => {
        onCloseCreateLecture();
        formik.resetForm();
        setSelectedFile([]);
        inputRef.current.value = null;
    };

    return (
        <Modal
            initialFocusRef={initialRefCreateLecture}
            finalFocusRef={finalRefCreateLecture}
            isOpen={isOpenCreateLecture}
            onClose={onCloseCreateLecture}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add lecture</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Class</FormLabel>
                            <InputGroup>
                                <Input type="text" value={ClassData.name} isDisabled/>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Module</FormLabel>
                            <InputGroup>
                                <Input type="text" value={ModuleData.name} isDisabled/>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Name</FormLabel>
                            <InputGroup>
                                <Input type="text" name="Name" value={formik.values.Name} onChange={formik.handleChange}/>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.Name && formik.touched.Name && (
                            <p style={{ color: "red" }}>{formik.errors.Name}</p>
                        )}
                        <Box display="flex" justifyContent="center">
                            <Input
                                type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button className="file-btn" onClick={onChooseFile} mt={"5vh"} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                    <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                </span>{" "}
                                    Upload File
                            </Button>
                        </Box>
                        {selectedFile.length > 0 && (
                            <div>
                                {selectedFile.map((file, index) => (
                                    <div className="selected-file" key={index}>
                                        <p>{file.name}</p>
                                        <Button onClick={() => removeFile(index)}> {/* Truyền index vào hàm removeFile */}
                                            <span className="material-symbols-rounded">
                                                <Icon as={MdDeleteForever} fontSize="20px" />
                                            </span>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <Box marginTop={"10px"}>
                            <Box marginTop={"10px"}>
                                {isSubmitting ? (
                                    <Progress borderRadius="full" hasStripe value={100} isAnimated/>
                                ) : null}
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="green"
                            mr={3}
                            type="submit"
                            isDisabled={isSubmitting || selectedFile == null}
                        >
                            {isSubmitting ? (
                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                            ) : (
                            "Upload"
                            )}
                        </Button>
                        <Button onClick={closeModal} isDisabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}

export function DeleteLecture({isOpenDeleteLecture, onOpenDeleteLecture, onCloseDeleteLecture, initialRefDeleteLecture, finalRefDeleteLecture, lecture, setLecture, setIsLoadingLecture}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    var jsonData = [];
    useEffect(() => {
        if(isOpenDeleteLecture){
            jsonData.push(lecture.id);
        }
    }, [isOpenDeleteLecture]);

    const handleActive = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await DELETE_LECTURE(token, jsonData);
                if (response.isSuccess) {
                    setIsLoadingLecture(true);
                    toast({
                        title: `Delete ${lecture.name} successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    handleCloseDeleteLecture();
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    const handleCloseDeleteLecture = () => {
        onCloseDeleteLecture();
        setLecture(null);
    }

    return (
        <Modal
            initialFocusRef={initialRefDeleteLecture}
            finalFocusRef={finalRefDeleteLecture}
            isOpen={isOpenDeleteLecture}
            onClose={handleCloseDeleteLecture}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete lectures</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    Are you sure to delete <strong>{isOpenDeleteLecture ? `${lecture.name}?` : null}</strong>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={handleActive} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Delete"}</Button>
                    <Button onClick={handleCloseDeleteLecture} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export function EditLecture({ isOpenEditLecture, onOpenEditLecture, onCloseEditLecture, initialRefEditLecture, finalRefEditLecture, data, setIsLoadingLecture, ClassData, ModuleId, setLectureDetailFromClassComponent }) {
    const [fetchFiles, setFetchFiles] = useState([]);
    const inputRef = useRef(null);
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const toast = useToast();
    const formik = useFormik({
        initialValues: {
            Name: "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("Name is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            try{
                var formData = new FormData();
                for (const param in values) {
                    if (values[param]) {
                        formData.append(param, values[param]);
                    }
                }
                selectedFiles.forEach((file, index) => {
                    formData.append(`Files`, file);
                });
                const {isSuccess, res} = await UPDATE_LECTURE(token, data.id, formData);
                if(isSuccess){
                    const resource = await res.json();
                    toast({
                        title: "Success",
                        description: resource.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsLoadingLecture(true);
                    handleCloseEditLecture();
                } else {
                    const resource = await res.json();
                    toast({
                        title: "Error",
                        description: resource.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsSubmitting(false);
            }
        },
    });
    const fetchFile = async () => {
        setIsFetchingFiles(true);
        try {
            const folderRef = ref(storage, `class_${ClassData.id}/documents/module_${ModuleId}/lecture_${data.id}`);
            const listResult = await listAll(folderRef);
            const files = [];
            await Promise.all(listResult.items.map(async (itemRef) => {
                const metadata = await getMetadata(itemRef);
                const downloadURL = await getDownloadURL(itemRef);
                const response = await fetch(downloadURL);
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: metadata.contentType });
                const file = new File([blob], metadata.name, { type: metadata.contentType });
                files.push(file);
            }));
            setIsFetchingFiles(false);
            setFetchFiles(files);
            setSelectedFiles(files);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch files",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleFileChange = event => {
        const uploadFiles = Array.from(event.target.files);
        let filesToSelect = [...selectedFiles];
    
        uploadFiles.forEach(file => {
            const isDuplicate = filesToSelect.some(selectedFile => selectedFile.name === file.name);
            console.log(isDuplicate);
            if (isDuplicate) {
                toast({
                    title: "Warning",
                    description: "Replace the duplicate file with the new one",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                filesToSelect = filesToSelect.filter(selectedFile => selectedFile.name !== file.name);
            }
            filesToSelect.push(file);
        });
    
        setSelectedFiles(filesToSelect);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => {
            // Lọc ra các phần tử khác phần tử có index bằng indexToRemove
            const updatedFiles = prevFiles.filter((file, index) => index !== indexToRemove);
            return updatedFiles;
        });
    };

    const handleCloseEditLecture = () => {
        onCloseEditLecture();
        formik.resetForm();
        setSelectedFiles([]);
        inputRef.current.value = null;
        setFetchFiles([]);
        setIsSubmitting(false);
        setLectureDetailFromClassComponent({});
    };

    useEffect(() => {
        if(isOpenEditLecture){
            fetchFile();
            formik.setFieldValue("Name", data.name);
        }
    }, [isOpenEditLecture]);

    return (
        <Modal 
            isOpen={isOpenEditLecture}
            onClose={handleCloseEditLecture}
            initialFocusRef={initialRefEditLecture}
            finalFocusRef={finalRefEditLecture}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Assignment</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Name</FormLabel>
                            <InputGroup>
                                <Input type="text" name="Name" value={formik.values.Name} onChange={formik.handleChange}/>
                            </InputGroup>
                        </FormControl>
                        <Box display="flex" justifyContent="center">
                            <Input
                                type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button className="file-btn" onClick={onChooseFile} mt={"5vh"} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                    <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                </span>{" "}
                                    Upload File
                            </Button>
                        </Box>
                        {selectedFiles.length > 0 && (
                            <div>
                                {selectedFiles.map((file, index) => (
                                    <div className="selected-file" key={index}>
                                        <p>{file.name}</p>
                                        <Button onClick={() => removeFile(index)}> {/* Truyền index vào hàm removeFile */}
                                            <span className="material-symbols-rounded">
                                                <Icon as={MdDeleteForever} fontSize="20px" />
                                            </span>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <Box mt={"10px"}>
                            <Box mt={"10px"}>
                                {isSubmitting ? (
                                    <Progress borderRadius="full" hasStripe value={100} isAnimated/>
                                ) : null}
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="green"
                            mr={3}
                            type="submit"
                            isDisabled={isSubmitting}
                        >
                            {isSubmitting ? (
                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                            ) : (
                            "Update"
                            )}
                        </Button>
                        <Button onClick={handleCloseEditLecture} isDisabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}