import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    Flex,
    Text,
    Tabs,
    TabList,
    TabPanels,
    useToast,
    Box,
    Table,
    TableContainer,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    TabPanel,
    Link,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Badge,
    Tab,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { format, set } from 'date-fns';
import { Paginator } from 'primereact/paginator';
import { ReactComponent as LoadingIcon } from '../../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../../icons/loading-logo.svg";
import { GET_LIST_OTHER_MODULE, CLONE_MODULE_TO_TRAINING_PROGRAM, CREATE_MODULE_AND_ADD_INTO_TRAINING_PROGRAM } from "../../../api/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

export function AddModuleIntoTrainingProgram({ isOpenAddModuleIntoTP, onOpenAddModuleIntoTP, onCloseAddModuleIntoTP, initialRefAddModuleIntoTP, finalRefAddModuleIntoTP, setIsPageLoading}) {
    const toast = useToast();
    const id = useParams().id;
    const [isFilter, setIsFilter] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [orderAdd, setOrderAdd] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [ModulesData, setModulesData] = useState([]);
    const [checkedCloneItems, setCheckedCloneItems] = useState({});
    const [checkedCloneOrderItems, setCheckedCloneOrderItems] = useState({});
    const [checkedCreateOrderItems, setCheckedCreateOrderItems] = useState({});
    const [tempCreateNewData, setTempCreateNewData] = useState([]);
    const [tempCloneData, setTempCloneData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    // const jsonData = [];
    // for(const key in data) {
    //     if(data[key]) {
    //         const id = key.split(':')[0].trim();
    //         const temp = {
    //             id: id,
    //             status: "Active"
    //         }
    //         jsonData.push(temp);
    //     }
    // }

    const handleTabChange = (index) => {
        setTabIndex(index);
        setIsLoading(true);
        // Bất kỳ logic bổ sung nào bạn muốn thực hiện khi tab thay đổi
    };

    const handleCheck = (itemId) => (event) => {
        setCheckedCloneItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: event.target.checked,
        }));
    };

    const handleCheckCloneOrder = (itemId) => (event) => {
        setCheckedCloneOrderItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: event.target.checked,
        }));
    };

    const handleCheckCreateOrder = (itemId) => (event) => {
        setCheckedCreateOrderItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [itemId]: event.target.checked,
        }));
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            code: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            code: Yup.string().required("Required")
        }),
        onSubmit: async (values) => {
            let array = [];
            tempCreateNewData.forEach((item, index) => {
                array.push(item);
            });
            array.push(values);
            setTempCreateNewData(array);
            formik.resetForm();
        }
    });

    useEffect(() => {
        if(isOpenAddModuleIntoTP){
            const token = localStorage.getItem("token");
            const fetchData = async () => {
                const { isSuccess, res } = await GET_LIST_OTHER_MODULE(token, id, `page=${currentPage}`);
                if (isSuccess) {
                    const resource = await res.json();
                    setModulesData(resource.data.listData);
                    setTotalRecords(resource.data.totalRecords);
                    setTotalPage(resource.data.totalPage);
                    setIsLoading(false);
                }
            
            };
            fetchData();
        }
    }, [isLoading, isOpenAddModuleIntoTP]);

    const onPageChange = event => {
        setCurrentPage(event.page + 1);
        setIsLoading(true);
    };

    const handleAddToOrderClone = async () => {
        const array = [];
        ModulesData.forEach((item, index) => {
            if(checkedCloneItems[item.id] && !tempCloneData.find(x => x.id === item.id)) {
                array.push(item);
            }
            setCheckedCloneItems((prevCheckedItems) => ({
                ...prevCheckedItems,
                [item.id]: false,
            }));
        });
        setTempCloneData((prevTempCloneData) => {
            return [...prevTempCloneData, ...array];
        });
    };

    const handleRemoveClone = () => {
        tempCloneData.forEach((item, index) => {
            if(checkedCloneOrderItems[item.id]) {
                setTempCloneData((prevTempCloneData) => {
                    return prevTempCloneData.filter(x => x.id !== item.id);
                });
                setCheckedCloneOrderItems((prevCheckedItems) => ({
                    ...prevCheckedItems,
                    [item.id]: false,
                }));
            }
        });
    }
    
    const handleRemoveCreateNew = () => {
        setTempCreateNewData((prevTempCreateData) => {
            const newArray = prevTempCreateData.filter((item, index) => {
                return !checkedCreateOrderItems[index];
            });
    
            const newCheckedItems = { ...checkedCreateOrderItems };
            prevTempCreateData.forEach((item, index) => {
                if (checkedCreateOrderItems[index]) {
                    newCheckedItems[index] = false;
                }
            });
    
            setCheckedCreateOrderItems(newCheckedItems);
    
            return newArray;
        });
    };

    const handleSave = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if(tabIndex === 0) {
            const jsonData = [];
            for(const key in tempCloneData) {
                if(tempCloneData[key]) {
                    const id = tempCloneData[key].id;
                    jsonData.push(id);
                }
            }
            if (token) {
                try {
                    const {isSuccess, res} = await CLONE_MODULE_TO_TRAINING_PROGRAM(token, id, jsonData);
                    if (isSuccess) {
                        onCloseAddModuleIntoTP();
                        toast({
                            title: `Successfully`,
                            description: `Clone ${jsonData.length} module(s) successfully`,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        setCheckedCloneItems({});
                        setTempCreateNewData([]);
                        setTempCloneData([]);
                        setIsPageLoading(true);
                        setIsLoading(true);
                    } else if (res.status === 400) {
                        toast({
                            title: "Error",
                            description: await res.json().responseFailed,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                } catch (error) {
                    toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } finally {
                    setIsSubmitting(false);
                }
            }
        } else if(tabIndex === 1) {
            if (token) {
                try {
                    const {isSuccess, res} = await CREATE_MODULE_AND_ADD_INTO_TRAINING_PROGRAM(token, id, tempCreateNewData);
                    if (isSuccess) {
                        onCloseAddModuleIntoTP();
                        toast({
                            title: `Successfully`,
                            description: `Create and add ${tempCreateNewData.length} module(s) successfully`,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                        setCheckedCloneItems({});
                        setTempCreateNewData([]);
                        setTempCloneData([]);
                        setIsPageLoading(true);
                        setIsLoading(true);
                    } else if (res.status === 400) {
                        toast({
                            title: "Error",
                            description: await res.json().responseFailed,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                } catch (error) {
                    toast({
                        title: "Error",
                        description: error.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } finally {
                    setIsSubmitting(false);
                }
            }
        }
    }
    const countChecked = () => {
        return Object.values(checkedCloneItems).filter((value) => value).length;
    };

    const countCheckedCloneOrder = () => {
        return Object.values(checkedCloneOrderItems).filter((value) => value).length;
    };

    const countCheckedCreateOrder = () => {
        return Object.values(checkedCreateOrderItems).filter((value) => value).length;
    }

    return (
        <Modal
            initialFocusRef={initialRefAddModuleIntoTP}
            finalFocusRef={finalRefAddModuleIntoTP}
            isOpen={isOpenAddModuleIntoTP}
            onClose={onCloseAddModuleIntoTP}
            size={"5xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Module Into Program</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Tabs isFitted variant='enclosed' onChange={handleTabChange} marginTop={"3vh"}>
                        <TabList mb='1em'>
                            <Tab id={"Clone"}>Clone</Tab>
                            <Tab value={"Create"}>Create new</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Box
                                    mb={4}
                                >
                                    {!isLoading ? (
                                        <div>
                                            {ModulesData.length !== 0 ? (
                                                <Box>
                                                    <Flex marginTop={"5vh"}>
                                                        <TableContainer
                                                            minWidth={"90%"}
                                                        >
                                                            <Table variant='simple' className="Table">
                                                                <Thead>
                                                                    <Tr>
                                                                        <Th></Th>
                                                                        <Th>Name</Th>
                                                                        <Th>Code</Th>
                                                                        <Th>Usage</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {ModulesData && ModulesData.map((item, index) =>(
                                                                        <Tr key={item?.id}>
                                                                            <Td>
                                                                                <Checkbox
                                                                                    key={item?.id}
                                                                                    value={item?.id}
                                                                                    isChecked={checkedCloneItems[item?.id]}
                                                                                    onChange={handleCheck(item?.id)}
                                                                                    >
                                                                                    {/* Checkbox label */}
                                                                                </Checkbox>
                                                                            </Td>
                                                                            <Td>
                                                                                <Link to={`/module/id/${item?.id}`}>
                                                                                    <Text
                                                                                            color={"rgb(88, 168, 168)"}
                                                                                            _hover={{
                                                                                                color: "rgb(88, 135, 168)",
                                                                                            }}  
                                                                                    >
                                                                                        {item?.name}
                                                                                    </Text>
                                                                                </Link>
                                                                            </Td>
                                                                            <Td>{item?.code}</Td>
                                                                            <Td>{item?.numOfProgram}</Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Flex>
                                                    <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
                                                        <Box></Box>
                                                        <Flex
                                                            marginTop={"1vh"}
                                                            marginLeft={"10vh"}
                                                        >
                                                            <Paginator
                                                                first={currentPage}
                                                                rows={10}
                                                                totalRecords={totalRecords}
                                                                onPageChange={onPageChange}
                                                                template={{ layout: 'PrevPageLink PageLinks NextPageLink' }}
                                                                className="p-mt-4"
                                                            />
                                                        </Flex>
                                                        <Flex>
                                                            <Box marginTop={"2vh"} marginRight={"1vh"} bg="rgb(225, 232, 237)" w="100px" display={"flex"} borderRadius={20} alignItems={"center"} justifyContent={"center"}>
                                                                <Text>{currentPage} of {totalPage}</Text>
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex justifyContent={"end"} mt={"10px"}>
                                                        <Button colorScheme='green' mr={3} onClick={handleAddToOrderClone} isDisabled={countChecked() === 0}>Add</Button>
                                                    </Flex>
                                                </Box>
                                            ) : (
                                                <Flex justifyContent="center" alignItems="center" width="100%" height="100%" marginBottom={"5vh"} marginTop={"5vh"}>
                                                    <Text
                                                        fontSize={"20px"}
                                                    >
                                                        <i>No record match</i>
                                                    </Text>
                                                </Flex>
                                            )}
                                        </div>
                                    ) : (
                                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={"5vh"} mb={"5vh"}>
                                            <Icon as={LoadingIcon} w={20} h={20} />
                                        </Box>
                                    )}
                                </Box>
                                <Divider />
                                <Box
                                    mt={4}
                                >
                                    <TableContainer>
                                        <Table variant='simple' className="Table">
                                            <Thead>
                                                <Tr>
                                                    <Th></Th>
                                                    <Th>Name</Th>
                                                    <Th>Code</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {tempCloneData.length !== null && tempCloneData.map((item, index) =>(
                                                    <Tr key={item?.id}>
                                                        <Td>
                                                            <Checkbox
                                                                key={item?.id}
                                                                value={item?.id}
                                                                isChecked={checkedCloneOrderItems[item?.id]}
                                                                onChange={handleCheckCloneOrder(item?.id)}
                                                            >
                                                                {/* Checkbox label */}
                                                            </Checkbox>
                                                        </Td>
                                                        <Td>
                                                            <Link to={`/module/id/${item?.id}`}>
                                                                <Text
                                                                    color={"rgb(88, 168, 168)"}
                                                                    _hover={{
                                                                        color: "rgb(88, 135, 168)",
                                                                    }}  
                                                                >
                                                                    {item?.name}
                                                                </Text>
                                                            </Link>
                                                        </Td>
                                                        <Td>{item?.code}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box
                                    mt={4}
                                    display={"flex"}
                                    justifyContent={"end"}
                                >
                                    <Button
                                        colorScheme='red'
                                        mr={3}
                                        onClick={handleRemoveClone}
                                        isDisabled={countCheckedCloneOrder() === 0}
                                    >Remove</Button>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box
                                    mb={4}
                                >
                                    <form onSubmit={formik.handleSubmit}>
                                        <FormControl mt={4}>
                                            <FormLabel>Name</FormLabel>
                                            <Input ref={initialRefAddModuleIntoTP} type="text" name="name" value={formik.values.name} onChange={formik.handleChange}/>
                                        </FormControl>
                                        {formik.errors.name && formik.touched.name && (
                                            <p style={{ color: "red" }}>{formik.errors.name}</p>
                                        )}
                                        <FormControl mt={4}>
                                            <FormLabel>Code</FormLabel>
                                            <Input type="text" name="code" value={formik.values.code} onChange={formik.handleChange}/>
                                        </FormControl>
                                        {formik.errors.code && formik.touched.code && (
                                            <p style={{ color: "red" }}>{formik.errors.code}</p>
                                        )}
                                        <Flex justifyContent={"end"}>
                                            <Button colorScheme='green' mt={4} type="submit" isDisabled={isSubmitting}>Add</Button>
                                        </Flex>
                                    </form>
                                </Box>
                                <Divider />
                                <Box
                                    mt={4}
                                >
                                    <TableContainer>
                                        <Table variant='simple' className="Table">
                                            <Thead>
                                                <Tr>
                                                    <Th></Th>
                                                    <Th>Name</Th>
                                                    <Th>Code</Th>
                                                </Tr>
                                            </Thead>
                                            {tempCreateNewData ? (
                                                <Tbody>
                                                    {tempCreateNewData && tempCreateNewData.map((item, index) =>(
                                                        <Tr key={index}>
                                                            <Td>
                                                                <Checkbox
                                                                    key={index}
                                                                    value={index}
                                                                    isChecked={checkedCreateOrderItems[index]}
                                                                    onChange={handleCheckCreateOrder(index)}
                                                                >
                                                                    {/* Checkbox label */}
                                                                </Checkbox>
                                                            </Td>
                                                            <Td>{item?.name}</Td>
                                                            <Td>{item?.code}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            ) : null}
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box
                                    mt={4}
                                    display={"flex"}
                                    justifyContent={"end"}
                                >
                                    <Button
                                        colorScheme='red'
                                        onClick={handleRemoveCreateNew}
                                        isDisabled={countCheckedCreateOrder() === 0}
                                    >Remove</Button>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='green' mr={3} onClick={handleSave} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Save"}</Button>
                    <Button onClick={onCloseAddModuleIntoTP} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}