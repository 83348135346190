function logoutAccount() {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("fullName");
    localStorage.removeItem("email");
    localStorage.removeItem("dob");
    localStorage.removeItem("address");
    localStorage.removeItem("gender");
    localStorage.removeItem("role");
    localStorage.removeItem("phone");
    localStorage.removeItem("status");
}

export default logoutAccount;