import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
// import assets logo
import logoutAccount from "../FnGeneral";
  
export default function HeaderComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Box
        position="fixed"
        width="100%"
        padding="0.5%"
        zIndex="1"
        px={4}
        bg={useColorModeValue("white", "gray.900")}
        borderBottom="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={"center"}>
            <Box
              marginTop="15px"
              marginLeft="30px"
            >
            <Image htmlWidth="70px" htmlHeight="30px" src="https://static.vecteezy.com/system/resources/previews/010/210/269/original/agm-triangle-letter-logo-design-with-triangle-shape-agm-triangle-logo-design-monogram-agm-triangle-logo-template-with-red-color-agm-triangular-logo-simple-elegant-and-luxurious-logo-agm-vector.jpg" alt='FSoft Academy' />
            </Box>
          </HStack>
          {/* Set if not found user login => hidden */}
          {localStorage.getItem("id") ? (
            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar size={"md"} src={localStorage.getItem("image")} />
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <Link to={`/user/profile/`}>
                        View Profile
                    </Link>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem>
                    <Button
                      onClick={() => {
                        logoutAccount();
                        navigate("/");
                      }}
                    >
                      Logout
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </>
  );
}