import {
    Box,
    Flex,
    CircularProgress,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    Select,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    FormControl,
    FormLabel,
    InputGroup,
    InputRightElement,
    useToast,
    useDisclosure,
    Checkbox,
    ButtonGroup,
    Badge,
    Progress,
    layout,
    Textarea
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL, listAll, getMetadata } from "firebase/storage";
import { format, set } from 'date-fns';
import { Icon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { Paginator } from 'primereact/paginator';
import { IoSearchOutline } from "react-icons/io5";
import { FiTrash2 } from "react-icons/fi";
import { FaFileImport } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { FaRegEdit  } from 'react-icons/fa';
import { MdAdd } from "react-icons/md";
import { FaRegFileLines, FaLock } from "react-icons/fa6";
import { ReactComponent as LoadingIcon } from '../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../icons/loading-logo.svg";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { PiTimer } from "react-icons/pi";
import { Form, Link, json, useNavigate, useParams, useLocation } from "react-router-dom";
// Import Yup && formik
import * as Yup from "yup";
import { useFormik } from "formik";
import storage from '../../config/firebaseConfig';
import { ADD_ASSIGNMENT, GET_ASSIGNMENT_DETAIL, GET_LIST_SUBMISSION, GET_SCORE_FILE_TEMPLATE, GET_SUBMISSION, GRADE_SUBMISSION, SUBMIT_SUBMISSION, UPDATE_SUBMISSION, IMPORT_SCORE, UPDATE_ASSIGNMENT } from "../../api/api";
import logoutAccount from "../FnGeneral";
import ReactECharts from 'echarts-for-react';

function formatDateForInput(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

export function EditAssignment({ isOpenEditAssignment, onOpenEditAssignment, onCloseEditAssignment, initialRefEditAssignment, finalRefEditAssignment, data, setIsLoadingAssignment, ClassData, AssignmentData, setAssignmentDetailFromClassComponent }) {
    const [fetchFiles, setFetchFiles] = useState([]);
    const inputRef = useRef(null);
    const [isFetchingFiles, setIsFetchingFiles] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const toast = useToast();
    const formik = useFormik({
        initialValues: {
            Title: "",
            Content: "",
            ExpiryDate: "",
            IsOverTime: ""
        },
        validationSchema: Yup.object({
            Title: Yup.string().min(10, "Must be at least 10 characters").required("Title is required"),
            Content: Yup.string().min(20, "Must be at least 20 characters").required("Content is required"),
            ExpiryDate: Yup.date().required("Expiry date is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            try{
                var formData = new FormData();
                for (const param in values) {
                    if (values[param]) {
                        formData.append(param, values[param]);
                    }
                }
                selectedFiles.forEach((file, index) => {
                    formData.append(`Files`, file);
                });
                const {isSuccess, res} = await UPDATE_ASSIGNMENT(token, data.id, formData);
                if(isSuccess){
                    const resource = await res.json();
                    toast({
                        title: "Success",
                        description: resource.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsLoadingAssignment(true);
                    handleCloseEditAssignment();
                } else {
                    const resource = await res.json();
                    toast({
                        title: "Error",
                        description: resource.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsSubmitting(false);
            }
        },
    });
    const fetchFile = async () => {
        setIsFetchingFiles(true);
        try {
            const folderRef = ref(storage, `class_${ClassData.id}/assignment_${AssignmentData.id}`);
            const listResult = await listAll(folderRef);
            const files = [];
            await Promise.all(listResult.items.map(async (itemRef) => {
                const metadata = await getMetadata(itemRef);
                const downloadURL = await getDownloadURL(itemRef);
                const response = await fetch(downloadURL);
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], { type: metadata.contentType });
                const file = new File([blob], metadata.name, { type: metadata.contentType });
                files.push(file);
            }));
            setIsFetchingFiles(false);
            setFetchFiles(files);
            setSelectedFiles(files);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to fetch files",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleFileChange = event => {
        const uploadFiles = Array.from(event.target.files);
        let filesToSelect = [...selectedFiles];
    
        uploadFiles.forEach(file => {
            const isDuplicate = filesToSelect.some(selectedFile => selectedFile.name === file.name);
            console.log(isDuplicate);
            if (isDuplicate) {
                toast({
                    title: `File: ${file.name}`,
                    description: "Replace the duplicate with the new one",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                filesToSelect = filesToSelect.filter(selectedFile => selectedFile.name !== file.name);
            }
            filesToSelect.push(file);
        });
    
        setSelectedFiles(filesToSelect);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => {
            // Lọc ra các phần tử khác phần tử có index bằng indexToRemove
            const updatedFiles = prevFiles.filter((file, index) => index !== indexToRemove);
            return updatedFiles;
        });
    };

    const handleCloseEditAssignment = () => {
        onCloseEditAssignment();
        formik.resetForm();
        setSelectedFiles([]);
        inputRef.current.value = null;
        setFetchFiles([]);
        setIsSubmitting(false);
        setAssignmentDetailFromClassComponent({});
    };

    useEffect(() => {
        if(isOpenEditAssignment){
            fetchFile();
            formik.setFieldValue("Title", data.title);
            formik.setFieldValue("Content", data.content);
            formik.setFieldValue("ExpiryDate", format(new Date(data.expiryDate), "yyyy-MM-dd'T'HH:mm"));
            formik.setFieldValue("IsOverTime", data.isOverTime);
        }
    }, [isOpenEditAssignment]);

    return (
        <Modal 
            isOpen={isOpenEditAssignment}
            onClose={handleCloseEditAssignment}
            initialFocusRef={initialRefEditAssignment}
            finalFocusRef={finalRefEditAssignment}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit Assignment</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                            <FormControl mt={4} isRequired>
                                <FormLabel>Title</FormLabel>
                                <InputGroup>
                                    <Input type="text" value={formik.values.Title} onChange={(e) => formik.setFieldValue("Title", e.target.value)}/>
                                </InputGroup>
                            </FormControl>
                            <FormControl mt={4} isRequired>
                                <FormLabel>Content</FormLabel>
                                <InputGroup>
                                    <Textarea type="text" value={formik.values.Content} onChange={(e) => formik.setFieldValue("Content", e.target.value)}/>
                                </InputGroup>
                            </FormControl>
                            <FormControl mt={4} isRequired>
                                <FormLabel>Expiry Date</FormLabel>
                                <InputGroup>
                                    <Input type="datetime-local" value={formik.values.ExpiryDate} onChange={(e) => formik.setFieldValue("ExpiryDate", e.target.value)}/>
                                </InputGroup>
                            </FormControl>
                            <Flex
                                alignItems={"center"}
                                mt={5}
                            >
                                <FormLabel>Is Overdue?</FormLabel>
                                <Checkbox size={"lg"} mb={3} name="IsOverTime" isChecked={formik.values.IsOverTime} onChange={formik.handleChange}/>
                            </Flex>
                            <Box display="flex" justifyContent="center">
                                <Input
                                    type="file"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    multiple
                                />
                                <Button className="file-btn" onClick={onChooseFile} mt={"5vh"} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                    <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                        <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                    </span>{" "}
                                        Upload File
                                </Button>
                            </Box>
                            {selectedFiles.length > 0 && (
                                <div>
                                    {selectedFiles.map((file, index) => (
                                        <div className="selected-file" key={index}>
                                            <p>{file.name}</p>
                                            <Button onClick={() => removeFile(index)}> {/* Truyền index vào hàm removeFile */}
                                                <span className="material-symbols-rounded">
                                                    <Icon as={MdDeleteForever} fontSize="20px" />
                                                </span>
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <Box mt={"10px"}>
                                <Box mt={"10px"}>
                                    {isSubmitting ? (
                                        <Progress borderRadius="full" hasStripe value={100} isAnimated/>
                                    ) : null}
                                </Box>
                            </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="green"
                            mr={3}
                            type="submit"
                            isDisabled={isSubmitting}
                        >
                            {isSubmitting ? (
                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                            ) : (
                            "Update"
                            )}
                        </Button>
                        <Button onClick={handleCloseEditAssignment} isDisabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
}

export function GradeSubmission({ isOpenGradeSubmission, onCloseGradeSubmission, initialRefGradeSubmission, finalRefGradeSubmission, data, setIsLoadingAssignment, setIsLoadingSubmission}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadSubmission, setIsLoadSubmission] = useState(true);
    const [SubmissionData, setSubmissionData] = useState({});
    const formik = useFormik({
        initialValues: {
            score: "",
            studentId: "",
            assignmentId: ""
        },
        validationSchema: Yup.object({
            score: Yup.number().min(0, "Must be at least 0").max(10, "Must be at most 10").required("Score is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            const jsonData = {
                studentId: values.studentId,
                score: values.score
            }
            try{
                const {isSuccess, res} = await GRADE_SUBMISSION(token, values["assignmentId"], jsonData);
                if(isSuccess){
                    const resource = await res.json();
                    toast({
                        title: "Success",
                        description: resource.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsLoadingAssignment(true);
                    closeGradeSubmission();
                    setIsLoadingAssignment(true);
                } else {
                    const resource = await res.json();
                    toast({
                        title: "Error",
                        description: resource.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    const closeGradeSubmission = () => {
        data = {};
        onCloseGradeSubmission();
        setIsLoadSubmission(true);
        setIsLoadingSubmission(true);
        formik.resetForm();
        setSubmissionData({});
    }

    function isJsonEmpty(jsonData) {
        return Object.keys(jsonData).length === 0 && jsonData.constructor === Object;
    }

    useEffect(() => {
        if(!isJsonEmpty(data) && isOpenGradeSubmission){
            setSubmissionData(data);
            setIsLoadSubmission(false);
            formik.setFieldValue("score", data.submissionSpecificResModel.score);
            formik.setFieldValue("studentId", data.authorOfSubmission.id);
            formik.setFieldValue("assignmentId", data.submissionSpecificResModel.assignmentId);
        }
    }, [isOpenGradeSubmission, data]);

    return (
        <div>
            {!isLoadSubmission ? (
                <Modal
                    initialFocusRef={initialRefGradeSubmission}
                    finalFocusRef={finalRefGradeSubmission}
                    isOpen={isOpenGradeSubmission}
                    onClose={onCloseGradeSubmission}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                        <Box>
                            <Flex 
                                flexWrap={"wrap"}
                                alignItems={"center"}
                            >
                                <Heading 
                                    size="md"
                                    color={"#548ed1"}
                                >
                                    Grade submission
                                </Heading>
                                <Box mt={"2px"}>
                                    <Flex flexWrap={"wrap"} alignItems={"center"}>
                                        <Icon
                                            as={IoMdPerson}
                                            w={4}
                                            h={4}
                                            color={"#787878"}
                                            mr={"5px"}
                                        />
                                        <Text color={"#787878"} fontSize={"15px"}>
                                        <Link
                                            to={`/user/id/${SubmissionData.authorOfSubmission.id}`}
                                        >
                                            <Text as="span">
                                            {SubmissionData.authorOfSubmission.name}
                                            </Text>
                                        </Link>{" "}
                                        <span ml={"10px"}>•</span>{" "}
                                        {format(
                                            new Date(SubmissionData.submissionSpecificResModel.createdAt),
                                            "dd/MM/yyyy"
                                        )}
                                        </Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        </ModalHeader>
                        <ModalCloseButton />
                        <form onSubmit={formik.handleSubmit}>
                            <ModalBody pb={6}>
                                <FormControl mt={4} isRequired>
                                    <FormLabel>Score</FormLabel>
                                    <InputGroup>
                                        <Input type="number" name="score" value={formik.values.score} onChange={formik.handleChange} isDisable={isSubmitting}/>
                                    </InputGroup>
                                </FormControl>
                                {formik.errors.score && formik.touched.score && (
                                    <p style={{ color: "red" }}>{formik.errors.score}</p>
                                )}
                                <Box marginTop={"10px"}>
                                    <Box marginTop={"10px"}>
                                        {isSubmitting ? (
                                            <Progress borderRadius="full" hasStripe value={100} isAnimated/>
                                        ) : null}
                                    </Box>
                                </Box>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    colorScheme="green"
                                    mr={3}
                                    type="submit"
                                    isDisabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                    <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                                    ) : (
                                    "Grade"
                                    )}
                                </Button>
                                <Button onClick={onCloseGradeSubmission} isDisabled={isSubmitting}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            ) : null}
        </div>
    );
}

export function CreateAssignment({ isOpenCreateAssignment, onOpenCreateAssignment, onCloseCreateAssignment, initialRefCreateAssignment, finalRefCreateAssignment, ClassData, ModuleData, setIsLoadingAssignment}) {
    const toast = useToast();
    const inputRef = useRef(null);
    const now = new Date();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [ClassLoadedData, setClassLoadedData] = useState([]);
    const [ModuleLoadedData, setModuleLoadedData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: {
            ModuleId: ModuleLoadedData.id,
            ClassId: ClassLoadedData.id,
            Title: "",
            Content: "",
            ExpiryDate: format(now, "yyyy-MM-dd'T'HH:mm"),
            IsOverTime: false
        },
        validationSchema: Yup.object({
            Title: Yup.string().min(10, "Must be at least 10 characters").required("Title is required"),
            Content: Yup.string().min(20, "Must be at least 20 characters").required("Content is required"),
            ExpiryDate: Yup.date().required("Expiry date is required"),
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            try{
                var formData = new FormData();
                formData.append("ClassId", ClassLoadedData.id);
                formData.append("ModuleId", ModuleLoadedData.id);
                for (const param in values) {
                    if (values[param]) {
                        formData.append(param, values[param]);
                    }
                }
                selectedFiles.forEach((file, index) => {
                    formData.append(`Files`, file);
                });
                const {isSuccess, res} = await ADD_ASSIGNMENT(token, formData);
                if(isSuccess){
                    const resource = await res.json();
                    toast({
                        title: "Success",
                        description: resource.message,
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsLoadingAssignment(true);
                    onCloseCreateAssignment();
                } else {
                    const resource = await res.json();
                    toast({
                        title: "Error",
                        description: resource.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsSubmitting(false);
            }
        },
    });

    useEffect(() => {
        setClassLoadedData(ClassData);
        setModuleLoadedData(ModuleData);
    }, [isOpenCreateAssignment, ClassData, ModuleData]);

    const handleFileChange = event => {
        // const files = Array.from(event.target.files);
        // setSelectedFile(files);
        const uploadFiles = Array.from(event.target.files);
        let filesToSelect = [...selectedFiles];
    
        uploadFiles.forEach(file => {
            const isDuplicate = filesToSelect.some(selectedFile => selectedFile.name === file.name);
            console.log(isDuplicate);
            if (isDuplicate) {
                toast({
                    title: `File: ${file.name}`,
                    description: "Replace the duplicate with the new one",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                filesToSelect = filesToSelect.filter(selectedFile => selectedFile.name !== file.name);
            }
            filesToSelect.push(file);
        });
    
        setSelectedFiles(filesToSelect);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = (indexToRemove) => {
        setSelectedFiles(prevFiles => {
            // Lọc ra các phần tử khác phần tử có index bằng indexToRemove
            const updatedFiles = prevFiles.filter((file, index) => index !== indexToRemove);
            return updatedFiles;
        });
    };

    const closeModal = () => {
        onCloseCreateAssignment();
        formik.resetForm();
        setSelectedFiles([]);
        inputRef.current.value = null;
    };

    useEffect(() => {
        if (ClassData) {
            setClassLoadedData(ClassData);
        }
        if (ModuleData) {
            setModuleLoadedData(ModuleData);
        }
    }, [ClassData, ModuleData]);

    return (
        <Modal
            initialFocusRef={initialRefCreateAssignment}
            finalFocusRef={finalRefCreateAssignment}
            isOpen={isOpenCreateAssignment}
            onClose={onCloseCreateAssignment}
            size={"2xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Assignment</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody pb={6}>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Class</FormLabel>
                            <InputGroup>
                                <Input type="text" value={ClassData.name} isDisabled/>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Module</FormLabel>
                            <InputGroup>
                                <Input type="text" value={ModuleData.name} isDisabled/>
                            </InputGroup>
                        </FormControl>
                        <FormControl mt={4} isRequired>
                            <FormLabel>Title</FormLabel>
                            <InputGroup>
                                <Input type="text" name="Title" value={formik.values.Title} onChange={formik.handleChange}/>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.Title && formik.touched.Title && (
                            <p style={{ color: "red" }}>{formik.errors.Title}</p>
                        )}
                        <FormControl mt={4} isRequired>
                            <FormLabel>Content</FormLabel>
                            <InputGroup>
                                <Textarea type="text" name="Content" value={formik.values.Content} onChange={formik.handleChange}/>
                            </InputGroup>
                        </FormControl>
                        {formik.errors.Content && formik.touched.Content && (
                            <p style={{ color: "red" }}>{formik.errors.Content}</p>
                        )}
                        <FormControl mt={4} isRequired>
                            <FormLabel>Expiry Date</FormLabel>
                            <InputGroup>
                                <Input type="datetime-local" name="ExpiryDate" value={formik.values.ExpiryDate} onChange={formik.handleChange}/>
                            </InputGroup>
                        </FormControl>
                        <Flex
                            alignItems={"center"}
                            mt={5}
                        >
                            <FormLabel>Is Overdue?</FormLabel>
                            <Checkbox size={"lg"} mb={3} name="IsOverTime" value={formik.values.IsOverTime} onChange={formik.handleChange}/>
                        </Flex>
                        <Box display="flex" justifyContent="center">
                            <Input
                                type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button className="file-btn" onClick={onChooseFile} mt={"5vh"} pt={"7vh"} pb={"7vh"} bg={"#edf2f7"}>
                                <span class="material-symbols-rounded" style={{padding: "14px", background: "#dae3ed"}}>
                                    <Icon as={FiUpload} fontSize="20px" color={"rgb(99, 122, 138)"}></Icon>
                                </span>{" "}
                                    Upload File
                            </Button>
                        </Box>
                        {selectedFiles.length > 0 && (
                            <div>
                                {selectedFiles.map((file, index) => (
                                    <div className="selected-file" key={index}>
                                        <p>{file.name}</p>
                                        <Button onClick={() => removeFile(index)}> {/* Truyền index vào hàm removeFile */}
                                            <span className="material-symbols-rounded">
                                                <Icon as={MdDeleteForever} fontSize="20px" />
                                            </span>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <Box marginTop={"10px"}>
                            <Box marginTop={"10px"}>
                                {isSubmitting ? (
                                    <Progress borderRadius="full" hasStripe value={100} isAnimated/>
                                ) : null}
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="green"
                            mr={3}
                            type="submit"
                            isDisabled={isSubmitting || selectedFiles == null}
                        >
                            {isSubmitting ? (
                            <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                            ) : (
                            "Upload"
                            )}
                        </Button>
                        <Button onClick={closeModal} isDisabled={isSubmitting}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );      
}

export function AssignmentDetail({ isOpenAssignmentDetail, onCloseAssignmentDetail, initialRefAssignmentDetail, finalRefAssignmentDetail, data, setIsLoadingAssignment, setAssignmentDetailFromClassComponent}) {
    const [assignmentDetail, setAssignmentDetail] = useState({});
    const { isOpen: isOpenSubmit, onOpen: onOpenSubmit, onClose: onCloseSubmit } = useDisclosure();
    const { initialRef: initialRefSubmit, finalRef: finalRefSubmit } = useDisclosure();
    const { isOpen: isOpenGradeSubmission, onOpen: onOpenGradeSubmission, onClose: onCloseGradeSubmission } = useDisclosure();
    const { initialRef: initialRefGradeSubmission, finalRef: finalRefGradeSubmission } = useDisclosure();
    const { isOpen: isOpenImportScore, onOpen: onOpenImportScore, onClose: onCloseImportScore } = useDisclosure();
    const { initialRef: initialRefImportScore, finalRef: finalRefImportScore } = useDisclosure();
    const [SubmissionData, setSubmissionData] = useState({});
    const [isLoadingSubmission, setIsLoadingSubmission] = useState(true);
    const [isSubitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [SubmissionDetails, setSubmissionDetails] = useState([]);
    const now = new Date();
    const [dataChart, setDataChart] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    var option = {
        title: {
            text: 'Overall score of students',
            left: 'center'
        },
        color: ["#3398DB"],
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            }
        },
        grid: {},
        xAxis: [{
            type: "category",
            data: ['0.00 - 1.00', '1.00 - 2.00', '2.00 - 3.00', '3.00 - 4.00', '4.00 - 5.00', '5.00 - 6.00', '6.00 - 7.00', '7.00 - 8.00', '8.00 - 9.00', '9.00 - 10.00'],
            axisLabel: {
                rotate: 45 
            }
        }],
        yAxis: [{
            type: "value"
        }],
        series: [{
            name: "Students",
            type: "bar",
            barWidth: "50%",
            data: dataChart
        }]
    }
    
    const id  = useParams().id;

    useEffect(() => {
        if(isLoadingSubmission && isOpenAssignmentDetail){
            if(localStorage.role === "Student"){
                const fetchAssignmentDetail = async () => {
                    const token = localStorage.getItem("token");
                    const {isSuccess, res} = await GET_SUBMISSION(token, data.id);
                    if(isSuccess){
                        const resource = await res.json();
                        setSubmissionData(resource.data);
                        setIsSubmitted(true);
                    } else {
                        setSubmissionData({});
                        setIsSubmitted(false);
                    }
                    setIsLoadingSubmission(false);
                };
                fetchAssignmentDetail();
            } else {
                const fetchAssignmentDetail = async () => {
                    const token = localStorage.getItem("token");
                    const {isSuccess, res} = await GET_LIST_SUBMISSION(token, data.id);
                    if(isSuccess){
                        const resource = await res.json();
                        setSubmissionData(resource.data);
                        countStudentForChart(resource.data);
                        setIsSubmitted(true);
                    } else {
                        setSubmissionData({});
                        setIsSubmitted(false);
                    }
                    setIsLoadingSubmission(false);
                };
                fetchAssignmentDetail();
            }
        }
    }, [isLoadingSubmission, isOpenAssignmentDetail]);

    function countStudentForChart(dataSubmission) {
        var dataChartTemp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        dataSubmission.forEach((data) => {
            console.log(data);
            if(data.submissionSpecificResModel == null){
                dataChartTemp[0] += 1;
            } else if(data.submissionSpecificResModel.score === 10) {
                dataChartTemp[9] += 1;
            } else {
                dataChartTemp[Math.floor(data.submissionSpecificResModel.score)] += 1;
            }
            console.log(dataChart);
        });
        setDataChart(dataChartTemp);
    }

    useEffect(() => {
        setIsLoadingSubmission(true);
    }, [isOpenAssignmentDetail])
    
    function isJsonEmpty(jsonData) {
        return Object.keys(jsonData).length === 0 && jsonData.constructor === Object;
    }

    const downloadFileTemplate = async (assignmentId, assignmentName) => {
        setIsSubmitting(true);
        try {
            const token = localStorage.getItem("token");
            const {isSuccess, res} = await GET_SCORE_FILE_TEMPLATE(token, assignmentId);
            const now = new Date();
            if (isSuccess) {
                const response = await res;
                // Convert response to blob
                const blob = await response.blob();
                // Create a link element to download the file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${assignmentName}-${format(now, "dd-MM-yyyy-HH-mm-ss")}`; 
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error("Failed to download file:", res.statusText);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        } finally {
            setIsSubmitting(false);
        }
    }

    const downloadFile = async (filePath, fileName) => {
        try {
            // Get download URL for the file from Firebase Storage
            const fileRef = ref(storage, `${filePath}/${fileName}`); // Create a reference to the file

            // Get download URL for the file from Firebase Storage
            const downloadURL = await getDownloadURL(fileRef);

            // Now download the file using downloadURL
            // For example, you can use fetch API to download the file
            const response = await fetch(downloadURL);
            if (response.ok) {
                // Convert response to blob
                const blob = await response.blob();
                // Create a link element to download the file
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName; // You can set custom file name here
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error("Failed to download file:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const handleGrade = async(dataSubmission) => {
        await setSubmissionDetails(dataSubmission);
        onOpenGradeSubmission();
    };

    const handleCloseModal = () => {
        onCloseAssignmentDetail();
        setAssignmentDetailFromClassComponent({});
    };

    return(
        <div>
            {isOpenAssignmentDetail ? (
                <Modal
                    initialFocusRef={initialRefAssignmentDetail}
                    finalFocusRef={finalRefAssignmentDetail}
                    isOpen={isOpenAssignmentDetail}
                    onClose={handleCloseModal}
                    size={localStorage.role === "Student" ? "3xl" : "6xl"}
                >
                <SubmitSubmission isOpenSubmit={isOpenSubmit} onCloseSubmit={onCloseSubmit} initialRefSubmit={initialRefSubmit} finalRefSubmit={finalRefSubmit} id={data.id} setIsLoadingAssignment={setIsLoadingAssignment} setIsLoadingSubmission={setIsLoadingSubmission} onCloseAssignmentDetail={onCloseAssignmentDetail} isSubitted={isSubitted}/>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton onClick={onCloseAssignmentDetail}/>
                        <ModalHeader>
                        <Box>
                            <Flex 
                            flexWrap={"wrap"}
                            alignItems={"center"}
                            >
                            {now >= new Date(data.expiryDate) && !data.isOverTime ? (
                                <Icon as={FaLock} w={4} h={4} color={"#787878"} mr={"5px"} />
                            ) : null}
                            <Heading 
                                size="md"
                                color={"#548ed1"}
                            >
                                {data.title}
                            </Heading>
                            {now >= new Date(data.expiryDate) && !data.isOverTime ? (
                                <div>
                                    {localStorage.role === "Trainer" ? (
                                        <Box 
                                        ml={"10px"}
                                        borderRadius={"5px"}
                                        border={"1px"}
                                        paddingRight={"5px"}
                                        paddingLeft={"5px"}
                                        color={"white"}
                                        bg={"red.300"}
                                        borderColor={"red.300"}
                                    >
                                        <Text>Closed</Text>
                                    </Box>
                                    ) : (
                                        <Box 
                                        ml={"10px"}
                                        borderRadius={"5px"}
                                        border={"1px"}
                                        paddingRight={"5px"}
                                        paddingLeft={"5px"}
                                        color={"white"}
                                        bg={"red.300"}
                                        borderColor={"red.300"}
                                        >
                                        <Text>Expired</Text>
                                        </Box>
                                    )}
                                    </div>
                                ) : (
                                    <div>
                                    {localStorage.role === "Trainer" ? (
                                        <Box 
                                        ml={"10px"}
                                        borderRadius={"5px"}
                                        border={"1px"}
                                        paddingRight={"5px"}
                                        paddingLeft={"5px"}
                                        color={"white"}
                                        bg={"green.300"}
                                        borderColor={"green.300"}
                                        >
                                        <Text>Open</Text>
                                        </Box>
                                    ) : (
                                        <Box 
                                        ml={"10px"}
                                        borderRadius={"5px"}
                                        border={"1px"}
                                        paddingRight={"5px"}
                                        paddingLeft={"5px"}
                                        color={"white"}
                                        bg={data.submissionStatus === "Submitted" ? "green.300" : "orange.300"}
                                        borderColor={data.submissionStatus === "Submitted" ? "green.300" : "orange.300"}
                                        >
                                        <Text>{data.submissionStatus}</Text>
                                        </Box>
                                    )}
                                    </div>
                            )}
                            </Flex>
                            <Box mt={"2px"}>
                                <Flex flexWrap={"wrap"} alignItems={"center"}>
                                    <Icon
                                    as={IoMdPerson}
                                    w={4}
                                    h={4}
                                    color={"#787878"}
                                    mr={"5px"}
                                    mb={"5px"}
                                    />
                                    <Text color={"#787878"} fontSize={"15px"}>
                                    <Link
                                        to={`/user/id/${data.createdBy.id}`}
                                    >
                                        <Text as="span">
                                        {data.createdBy.name}
                                        </Text>
                                    </Link>{" "}
                                    <span ml={"10px"}>•</span>{" "}
                                    {format(
                                        new Date(data.createdAt),
                                        "dd/MM/yyyy"
                                    )}
                                    </Text>
                                </Flex>
                                </Box>
                                <Box mt={"2px"}>
                                <Flex flexWrap={"wrap"} alignItems={"center"}>
                                    <Icon
                                    as={PiTimer}
                                    w={4}
                                    h={4}
                                    color={"#787878"}
                                    mr={"5px"}
                                    mb={"5px"}
                                    />
                                    <Text color={"#787878"} mb={"5px"}>
                                    {now >= new Date(data.expiryDate) && data.isOverTime ? (
                                        <Box>
                                        <Text as="span" fontSize={"15px"}>
                                            {format(new Date(data.expiryDate),"dd/MM/yyyy")}
                                        </Text>
                                        {" "}
                                        <span ml={"10px"}>•</span>{" "}
                                        <Badge colorScheme="orange" alignItems={"center"}>OverDue</Badge>
                                        </Box>
                                    ) : (
                                        <Box>
                                        <Text as="span" fontSize={"15px"}>
                                            {format(new Date(data.expiryDate),"dd/MM/yyyy")}
                                        </Text>
                                        </Box>
                                    )}
                                    </Text>
                                </Flex>
                            </Box>
                        </Box>
                        </ModalHeader>
                        <ModalBody pb={6}>
                            <Text>{data.content}</Text>
                            <Box mt={"10vh"}>
                                <Heading as='h4' size='md'>Attachments</Heading>
                                {data.details.length > 0 ? (
                                    <Box
                                        border={"1px"}
                                        borderRadius={"10px"}
                                        borderColor={"#EDF2F7"}
                                        mt={"10px"}
                                    >
                                        <Table>
                                            <Tbody>
                                            {data.details.map(
                                                (attachment, index) => (
                                                <Tr key={attachment.id}>
                                                    <Td>
                                                    <Flex
                                                        alignItems={"center"}
                                                        onClick={() =>
                                                            downloadFile(
                                                                `class_${id}/assignment_${data.id}`,
                                                                attachment.fileName
                                                            )
                                                        }
                                                        color={"#548ed1"}
                                                        _hover={{
                                                            color: "#787878",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Icon
                                                            as={FaRegFileLines}
                                                            mr={"5px"}
                                                        />
                                                        {attachment.fileName}
                                                    </Flex>
                                                    </Td>
                                                </Tr>
                                                )
                                            )}
                                            </Tbody>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Flex
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <Text><i>No attachments</i></Text>
                                    </Flex>
                                )}
                                <Box mt={"5vh"}>
                                    <Flex flexWrap={"wrap"} justifyContent={"space-between"}>
                                        <Heading as='h4' size='md'>Submission detail</Heading>
                                        {localStorage.role === "Trainer" ? (
                                            <Flex>
                                                <Button
                                                    colorScheme="green"
                                                    onClick={onOpenImportScore}
                                                    isDisabled={isSubmitting}
                                                    marginRight={"10px"}
                                                >
                                                    <Icon
                                                        fontSize="20px"
                                                        as={FaFileImport}
                                                        marginRight={"10px"}
                                                    />{" "}
                                                    Import score
                                                </Button>
                                                <Button
                                                    colorScheme="blue"
                                                    onClick={() => downloadFileTemplate(data.id, data.title)}
                                                    isDisabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                                                    ) : (
                                                        <Flex alignItems={"center"} alignContent={"center"}>
                                                            <Icon
                                                                fontSize="20px"
                                                                as={FaFileImport}
                                                                marginRight={"10px"}
                                                            />{" "}
                                                            Download template score
                                                        </Flex>
                                                    )}
                                                </Button>
                                            </Flex>
                                        ) : null}
                                    </Flex>
                                    {!isLoadingSubmission ? (
                                        <div>
                                            {!isJsonEmpty(SubmissionData) ? (
                                            <Box>
                                                <GradeSubmission isOpenGradeSubmission={isOpenGradeSubmission} onCloseGradeSubmission={onCloseGradeSubmission} initialRefGradeSubmission={initialRefGradeSubmission} finalRefGradeSubmission={finalRefGradeSubmission} data={SubmissionDetails} setIsLoadingAssignment={setIsLoadingAssignment} setIsLoadingSubmission={setIsLoadingSubmission}/>
                                                <ImportScore isOpenImportScore={isOpenImportScore} onCloseImportScore={onCloseImportScore} initialRefImportScore={initialRefImportScore} finalRefImportScore={finalRefImportScore} id={data.id} setIsLoadingSubmission={setIsLoadingSubmission}/>
                                                {localStorage.role === "Student" ? (
                                                    <div>
                                                        <Flex flexWrap={"wrap"} alignItems={"center"} mt={"10px"}>
                                                            <TableContainer>
                                                                <Table variant="simple" style={{width: "100%", tableLayout: "fixed"}}>
                                                                    <Tbody>
                                                                        <Tr>
                                                                            <Td>
                                                                                Grading Status
                                                                            </Td>
                                                                            <Td>
                                                                                {SubmissionData.isGrade ? "Graded" : "Not Graded"}
                                                                            </Td>
                                                                        </Tr>
                                                                        {SubmissionData.isGrade ? (
                                                                            <Tr>
                                                                                <Td>
                                                                                    Grade
                                                                                </Td>
                                                                                <Td>
                                                                                    {SubmissionData.score}/10
                                                                                </Td>
                                                                            </Tr>
                                                                        ) : null}
                                                                        <Tr>
                                                                            <Td>
                                                                                Last modified
                                                                            </Td>
                                                                            <Td>
                                                                                {format(new Date(SubmissionData.createdAt),"dd/MM/yyyy, HH:mm")}
                                                                            </Td>
                                                                        </Tr>
                                                                        <Tr>
                                                                            <Td>
                                                                                File submission
                                                                            </Td>
                                                                            <Td>
                                                                                <Flex
                                                                                    alignItems={"center"}
                                                                                    onClick={() =>
                                                                                        downloadFile(
                                                                                            `class_${id}/assignment_${data.id}/${SubmissionData.student.id}`,
                                                                                            SubmissionData.fileName
                                                                                        )
                                                                                    }
                                                                                    color={"#548ed1"}
                                                                                    _hover={{
                                                                                        color: "#787878",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                <Icon
                                                                                    as={FaRegFileLines}
                                                                                    mr={"5px"}
                                                                                />
                                                                                {SubmissionData.fileName}
                                                                                </Flex>
                                                                            </Td>
                                                                        </Tr>
                                                                    </Tbody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Flex>
                                                        <Flex justifyContent={"center"} mt={"20px"}>
                                                            <Button colorScheme="blue" onClick={onOpenSubmit} isDisabled={SubmissionData.isGrade}>Edit submission</Button>
                                                        </Flex>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Box 
                                                            mt={"5vh"}
                                                        >
                                                            <ReactECharts
                                                                option={option}
                                                                style={{ height: '400px', width: '100%' }}
                                                                opts={{ renderer: 'canvas', useDirtyRect: false }}
                                                            />
                                                        </Box>
                                                        <Flex flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                                                        <TableContainer mt={"5vh"}>
                                                            <Table variant="simple">
                                                                <Thead w="200px">
                                                                    <Tr>
                                                                        <Th style={{width: "20vh"}}>Student</Th>
                                                                        <Th>Submission file</Th>
                                                                        <Th>Is grade</Th>
                                                                        <Th>Score</Th>
                                                                        <Th>Submitted at</Th>
                                                                        <Th>Action</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {SubmissionData.map((submission, index) => (
                                                                        <Tr key={submission.authorOfSubmission.id}>
                                                                            <Td style={{width: "20vh"}}>
                                                                                <Link to={`/user/id/${submission.authorOfSubmission.id}`}>
                                                                                    {submission.authorOfSubmission.name}
                                                                                </Link>
                                                                            </Td>
                                                                            <Td>
                                                                                {submission.submissionSpecificResModel ? (
                                                                                    <div>
                                                                                        {submission.submissionSpecificResModel.fileName !== '-' ? (
                                                                                            <Flex
                                                                                                alignItems={"center"}
                                                                                                onClick={() =>
                                                                                                    downloadFile(
                                                                                                        `class_${id}/assignment_${data.id}/${submission.authorOfSubmission.id}`,
                                                                                                        submission.submissionSpecificResModel.fileName
                                                                                                    )
                                                                                                }
                                                                                                color={"#548ed1"}
                                                                                                _hover={{
                                                                                                    color: "#787878",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                            >
                                                                                                <Icon as={FaRegFileLines} mr={"5px"} />
                                                                                                {submission.submissionSpecificResModel.fileName}
                                                                                            </Flex>
                                                                                        ) : (
                                                                                            <Text>-</Text>
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    <Text>-</Text>
                                                                                )}
                                                                            </Td>
                                                                            <Td>
                                                                                {submission.submissionSpecificResModel
                                                                                    ? submission.submissionSpecificResModel.isGrade
                                                                                        ? "Graded"
                                                                                        : "Not graded"
                                                                                    : "Not graded"}
                                                                            </Td>
                                                                            <Td>
                                                                                {submission.submissionSpecificResModel
                                                                                    ? submission.submissionSpecificResModel.score
                                                                                    : "0"}
                                                                                /10
                                                                            </Td>
                                                                            <Td>
                                                                                {submission.submissionSpecificResModel
                                                                                    ? format(new Date(submission.submissionSpecificResModel.createdAt), "dd/MM/yyyy, HH:mm")
                                                                                    : "-"}
                                                                            </Td>
                                                                            <Td>
                                                                                <Button
                                                                                    onClick={() => handleGrade(submission)}
                                                                                    isDisabled={submission.submissionSpecificResModel == null}
                                                                                >
                                                                                    Grade
                                                                                </Button>
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                            </Table>
                                                        </TableContainer>
                                                        </Flex>
                                                    </div>
                                                )}
                                            </Box>
                                        ) : (
                                            <Flex justifyContent={"center"} mt={"5vh"} flexDirection={"column"} alignItems={"center"}>
                                                <Flex>
                                                    <Text><i>Not submit yet</i></Text>
                                                </Flex>
                                                <Flex mt={"5vh"}>
                                                    <Button colorScheme="blue" onClick={onOpenSubmit} isDisabled={SubmissionData.isGrade}>Add submission</Button>
                                                </Flex>
                                            </Flex>
                                        )}
                                        </div>
                                    ) : (
                                        <Flex
                                            flexWrap="wrap"
                                            justifyContent="space-between"
                                            display="flex"
                                            marginTop={"3vh"}
                                        >
                                            <Icon as={LoadingIcon} w={50} h={50} />
                                        </Flex>
                                    )}
                                </Box>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            ) : null}
        </div>
    )
}

export function ImportScore({ isOpenImportScore, onCloseImportScore, initialRefImportScore, finalRefImportScore, id, setIsLoadingSubmission}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const inputRef = useRef(null);
    const handleUpload = async () => {
        if(selectedFile){
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("excelFile", selectedFile);
            const uploadFile = async () => {
                try {
                    const {isSuccess, res} = await IMPORT_SCORE(token, id, formData);
                    if (isSuccess) {
                        const resource = await res.json();
                        toast({
                            title: "Success",
                            description: resource.message,
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                        setIsLoadingSubmission(true);
                        onCloseImportScore();
                    } else {
                        const resource = await res.json();
                        toast({
                            title: "Error",
                            description: resource.message,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsSubmitting(false);
                    removeFile();
                }
            };
            uploadFile();
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = () => {
        setSelectedFile(null);
        inputRef.current.value = null;
    };

    const closeModal = () => {
        onCloseImportScore();
        setSelectedFile(null);
        inputRef.current.value = null;
    };

    return (
        <Modal
            initialFocusRef={initialRefImportScore}
            finalFocusRef={finalRefImportScore}
            isOpen={isOpenImportScore}
            onClose={onCloseImportScore}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Import score</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <Box display="flex" justifyContent="center">
                    <Input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    />
                    <button className="file-btn" onClick={onChooseFile}>
                    <span class="material-symbols-rounded">
                        <Icon as={FiUpload} fontSize="20px"></Icon>
                    </span>{" "}
                    Upload File
                    </button>
                </Box>
                {selectedFile && (
                    <div className="selected-file">
                    <p>{selectedFile.name}</p>

                    <button onClick={removeFile}>
                        <span class="material-symbols-rounded">
                        <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                        </span>
                    </button>
                    </div>
                )}
                <Box marginTop={"10px"}>
                    <Box marginTop={"10px"}>
                        {isSubmitting ? (
                            <Progress borderRadius={"full"} hasStripe value={100} isAnimated/>
                        ) : null}
                    </Box>
                </Box>
                </ModalBody>
                <ModalFooter>
                <Button
                    colorScheme="green"
                    mr={3}
                    onClick={handleUpload}
                    isDisabled={isSubmitting || selectedFile == null}
                >
                    {isSubmitting ? (
                    <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                    ) : (
                    "Upload"
                    )}
                </Button>
                <Button onClick={closeModal} isDisabled={isSubmitting}>
                    Cancel
                </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
    );
}

export function SubmitSubmission({isOpenSubmit, onCloseSubmit, initialRefSubmit, finalRefSubmit, id, setIsLoadingAssignment, onCloseAssignmentDetail, isSubitted}) {
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const toast = useToast();
    const handleUpload = async () => {
        if(selectedFile){
            setIsSubmitting(true);
            const token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("attachment", selectedFile);
            const uploadFile = async () => {
                try {
                    if(!isSubitted){
                        const {isSuccess, res} = await SUBMIT_SUBMISSION(token, id, formData);
                        if (isSuccess) {
                            const resource = await res.json();
                            toast({
                                title: "Success",
                                description: resource.message,
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                            });
                            setIsLoadingAssignment(true);
                            onCloseSubmit();
                            onCloseAssignmentDetail();
                        } else {
                            const resource = await res.json();
                            toast({
                                title: "Error",
                                description: resource.message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                        }
                    } else {
                        const {isSuccess, res} = await UPDATE_SUBMISSION(token, id, formData);
                        if (isSuccess) {
                            const resource = await res.json();
                            toast({
                                title: "Success",
                                description: resource.message,
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                            });
                            setIsLoadingAssignment(true);
                            onCloseSubmit();
                            onCloseAssignmentDetail();
                        } else {
                            const resource = await res.json();
                            toast({
                                title: "Error",
                                description: resource.message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                            });
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsSubmitting(false);
                    removeFile();
                }
            };
            uploadFile();
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onChooseFile = () => {
        inputRef.current.click();
    };
    
    const removeFile = () => {
        setSelectedFile(null);
        inputRef.current.value = null;
    };

    const closeModal = () => {
        onCloseSubmit();
        setSelectedFile(null);
        inputRef.current.value = null;
    };

    return (
        <Modal
            initialFocusRef={initialRefSubmit}
            finalFocusRef={finalRefSubmit}
            isOpen={isOpenSubmit}
            onClose={onCloseSubmit}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload submission</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                <Box display="flex" justifyContent="center">
                    <Input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    />
                    <button className="file-btn" onClick={onChooseFile}>
                    <span class="material-symbols-rounded">
                        <Icon as={FiUpload} fontSize="20px"></Icon>
                    </span>{" "}
                    Upload File
                    </button>
                </Box>
                {selectedFile && (
                    <div className="selected-file">
                    <p>{selectedFile.name}</p>

                    <button onClick={removeFile}>
                        <span class="material-symbols-rounded">
                        <Icon as={MdDeleteForever} fontSize="20px"></Icon>
                        </span>
                    </button>
                    </div>
                )}
                <Box marginTop={"10px"}>
                    <Box marginTop={"10px"}>
                        {isSubmitting ? (
                            <Progress borderRadius={"full"} hasStripe value={100} isAnimated/>
                        ) : null}
                    </Box>
                </Box>
                </ModalBody>
                <ModalFooter>
                <Button
                    colorScheme="green"
                    mr={3}
                    onClick={handleUpload}
                    isDisabled={isSubmitting || selectedFile == null}
                >
                    {isSubmitting ? (
                    <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                    ) : (
                    "Upload"
                    )}
                </Button>
                <Button onClick={closeModal} isDisabled={isSubmitting}>
                    Cancel
                </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
    );
}