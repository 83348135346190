import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    useToast,
    Box,
    Flex,
    PinInput,
    PinInputField,
    HStack,
    Text,
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { json, useParams, useNavigate, useLocation, Form } from "react-router-dom";
import { CREATE_ATTENDANCE } from "../../../api/api";
import { ReactComponent as LoadingIconButton } from "../../../icons/loading-logo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

export function CreateAttendance({ isOpenCreateAttendance, onOpenCreateAttendance, onCloseCreateAttendance, initialRefCreateAttendance, finalRefCreateAttendance, setAttendanceData, id, onOpenAttendance }) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formik = useFormik({
        initialValues: {
          addMinute: "",
          addLateTime: ""
        },
        validationSchema: Yup.object({
            addMinute: Yup.number().required("Minute is required").min(1, "Minimum is 1").max(60, "Maximum is 60"),
            addLateTime: Yup.number().required("Late time is required").min(1, "Minimum is 1").max(60, "Maximum is 60")
        }),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            await handleCreateAttendance(values);
        },
    })
    const handleCreateAttendance = async (body) => {
        const token = localStorage.getItem("token");
        if (token) {
          try {
            const { isSuccess, res } = await CREATE_ATTENDANCE(token, id, body);
            console.log(body);
            if (isSuccess) {
              const response = await res.json();
              setIsSubmitting(false);
              toast({
                title: `Create attendance students(s) successfully`,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setAttendanceData(response.data);
              formik.resetForm();
              onCloseCreateAttendance();
              onOpenAttendance();
            } else {
              const response = await res.json();
              if (response.responseFailed != null) {
                toast({
                  title: "Error",
                  description: response.responseFailed,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              } else if (response.message != null) {
                toast({
                  title: "Error",
                  description: response.message,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
            }
          } catch (error) {
            toast({
              title: "Error",
              description: error.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } finally {
            setIsSubmitting(false);
          }
        }
      };
    return (
        <Modal
            initialFocusRef={initialRefCreateAttendance}
            finalFocusRef={finalRefCreateAttendance}
            isOpen={isOpenCreateAttendance}
            onClose={onCloseCreateAttendance}
        >
            <ModalOverlay />
            <form onSubmit={formik.handleSubmit}>
                <ModalContent>
                    <ModalHeader>Attendance</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                      <FormControl mt={4}>
                        <FormLabel>Expire in</FormLabel>
                        <Input
                            name="addMinute"
                            value={formik.values.addMinute}
                            onChange={formik.handleChange}
                            maxW={"30vh"}
                        />
                        <Text as="span" ml={"2vh"}>
                            minute(s)
                        </Text>
                      </FormControl>
                      {formik.errors.addMinute && formik.touched.addMinute && (
                          <p style={{ color: "red" }}>{formik.errors.addMinute}</p>
                      )}
                      <FormControl mt={4}>
                        <FormLabel>Late in</FormLabel>
                        <Input
                            name="addLateTime"
                            value={formik.values.addLateTime}
                            onChange={formik.handleChange}
                            maxW={"30vh"}
                        />
                        <Text as="span" ml={"2vh"}>
                            minute(s)
                        </Text>
                      </FormControl>
                      {formik.errors.addLateTime && formik.touched.addLateTime && (
                          <p style={{ color: "red" }}>{formik.errors.addLateTime}</p>
                      )}
                    </ModalBody>
                    <ModalFooter>
                            <Button colorScheme={"blue"} type="submit" mr={"5px"} isDisabled={isSubmitting}>
                              {isSubmitting ? (
                                  <Icon as={LoadingIconButton} w={"20px"} h={"20px"} />
                                ) : (
                                  "Create attendance"
                              )}
                            </Button>
                        <Button onClick={onCloseCreateAttendance}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>
    );
}