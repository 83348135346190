import React, {  useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import "../css/Pagination.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logoutAccount from "../components/FnGeneral";

//Import components
import Auth from "../components/Auth";
import Login from "../components/Login";
import FrameComponent from "../components/Frame";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import { Recovery, ResetPasswordForm } from "../components/Recovery";
import { ClassList, ClassDetail} from "../components/Class/Class";
import { StudentsList, StudentDetail } from "../components/Student/Student";
import Demo from "../components/Demo";
import { Profile, GetUser } from "../components/User/Profile";
import Home from "../components/Home";
import { UserList } from "../components/Manager/Manager";
import { AssignmentDetail } from "../components/Assignment/Assignment";
import { TrainingProgramList, TrainingProgramDetail } from "../components/TrainingProgam/TrainingProgram";
import { ModuleDetail, ModuleList } from "../components/Module/Module";
import { RedirectAttendance } from "../components/Attendance/Attendance";
import { MyLearning } from "../components/MyLearning/MyLearning";
import { AllTicket } from "../components/Ticket/Ticket";

export default function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Auth />} />
                <Route path="/login" element={<Login />} />
                <Route path="/recovery" element={<Recovery />} />
                <Route path="/recovery/reset" element={<ResetPasswordForm />} />
                <Route path="/home" element={<FrameComponent />} >
                    <Route path="" element={<Home />} />
                </Route>
                <Route path="/student" element={<FrameComponent />} >
                    <Route path="" element={<StudentsList />} />
                </Route>
                <Route path="/class" element={<FrameComponent />} >
                    <Route path="" element={<ClassList />} />
                    <Route path="id/:id" element={<ClassDetail />} />
                </Route>
                <Route path="/attendance" element={<FrameComponent />}>
                    <Route path="id/:attendanceid/class/id/:id" element={<RedirectAttendance />} />
                </Route>
                <Route path="/user" element={<FrameComponent />} >
                    <Route path="profile" element={<Profile />} />
                    <Route path="id/:id" element={<GetUser />}/>
                </Route>
                <Route path="/training-program" element={<FrameComponent />} >
                    <Route path="" element={<TrainingProgramList />} />
                    <Route path="id/:id" element={<TrainingProgramDetail />}/>
                </Route>
                <Route path="/module" element={<FrameComponent />} >
                    <Route path="" element={<ModuleList />} />
                    <Route path="id/:id" element={<ModuleDetail />}/>
                </Route>
                <Route path="/my-learning" element={<FrameComponent />} >
                    <Route path="" element={<MyLearning />} />
                </Route>
                <Route path="/my-class" element={<FrameComponent />} >
                    <Route path="" element={<MyLearning />} />
                </Route>
                <Route path="/manager" element={<FrameComponent />} >
                    <Route path="" element={<UserList />} />
                </Route>
                <Route path="/assignment" element={<FrameComponent />} >
                    <Route path="id/:id" element={<AssignmentDetail />} />
                </Route>
                <Route path="/ticket" element={<FrameComponent />} >
                    <Route path="" element={<AllTicket />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
