import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalCloseButton,
    ModalBody,
    Button,
    useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { ReactComponent as LoadingIcon } from '../../../icons/loading-icon-gray.svg';
import { ReactComponent as LoadingIconButton } from "../../../icons/loading-logo.svg";
import { DELETE_STUDENTS_FROM_CLASS } from "../../../api/api";
import { json, useParams } from "react-router-dom";

export function DeleteStudentsFromClass({ isOpenDelete, onOpenDelete, onCloseDelete, initialRefDelete, finalRefDelete, data, setloadForm, setCheckedItems}) {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const jsonData = [];
    const id = useParams().id;
    for(const key in data) {
        if(data[key]) {
            const id = key.split(':')[0].trim();
            jsonData.push(id);
        }
    }

    console.log(jsonData);
    const handleDelete = async () => {
        setIsSubmitting(true);
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await DELETE_STUDENTS_FROM_CLASS(token, id, jsonData);
                if (response.isSuccess) {
                    onCloseDelete();
                    toast({
                        title: `Delete ${jsonData.length} students(s) successfully`,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setCheckedItems({});
                    setloadForm(false);
                } else if (response.responseFailed != null) {
                    toast({
                        title: "Error",
                        description: response.responseFailed,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                } else if (response.message != null) {
                    toast({
                        title: "Error",
                        description: response.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            } catch (error) {
                toast({
                    title: "Error",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    return (
        <Modal
            initialFocusRef={initialRefDelete}
            finalFocusRef={finalRefDelete}
            isOpen={isOpenDelete}
            onClose={onCloseDelete}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete students from Class</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    Are you sure to delete {jsonData.length} student(s) from Class?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={handleDelete} isDisabled={isSubmitting}>{isSubmitting ? <Icon as={LoadingIconButton} w={"20px"} h={"20px"}/> : "Delete"}</Button>
                    <Button onClick={onCloseDelete} isDisabled={isSubmitting}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}