import {
  Box,
  Flex,
  CircularProgress,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  Icon,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon, EmailIcon, LockIcon } from "@chakra-ui/icons";
import { ReactComponent as LoadingIcon } from "../icons/loading-logo.svg";
import { Link, json, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LOGIN } from "../api/api";

export default function Login() {
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setSubmitted(true);
      try {
        const response = await LOGIN(values);
        if (response.isSuccess) {
          toast({
            title: "Login success",
            description: "Welcome to AXLM",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("fullname", response.data.user.fullName);
          localStorage.setItem("email", response.data.user.email);
          localStorage.setItem("dob", response.data.user.dob);
          localStorage.setItem("address", response.data.user.address);
          localStorage.setItem("gender", response.data.user.gender);
          localStorage.setItem("role", response.data.user.role);
          localStorage.setItem("phone", response.data.user.phone);
          localStorage.setItem("status", response.data.user.status);
          if(localStorage.getItem("from") !== null) {
            navigate(localStorage.getItem("from"));
            localStorage.removeItem("from");
          } else {
            navigate("/home");
          }
        } else if (response.responseFailed != null) {
          toast({
            title: "Error",
            description: response.responseFailed,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (response.message != null) {
          toast({
            title: "Login failed",
            description: response.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setSubmitted(false);
      }
    },
  });

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      <Box
        bg="white"
        p={12}
        rounded="xl"
        shadow="2xl"
        w={{ base: "95%", md: "600px" }}
        transition="all 0.3s"
        _hover={{ transform: "scale(1.02)" }}
      >
        <Stack spacing={8}>
          <Stack align="center" spacing={4}>
            <Heading
              fontSize={{ base: "3xl", md: "4xl" }}
              bgGradient="linear(to-r, teal.500, blue.500)"
              bgClip="text"
            >
              Welcome Back
            </Heading>
            <Text fontSize="lg" color="gray.600">
              Sign in to continue to AXLM
            </Text>
          </Stack>

          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={6}>
              <FormControl isRequired>
                <InputGroup size="lg">
                  <Input
                    pl={12}
                    type="email"
                    placeholder="Email"
                    bg="gray.50"
                    border="1px"
                    borderColor="gray.200"
                    _hover={{ borderColor: "teal.400" }}
                    _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    fontSize="lg"
                  />
                  <Box position="absolute" left={4} top={4} color="gray.400">
                    <EmailIcon w={5} h={5} />
                  </Box>
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <InputGroup size="lg">
                  <Input
                    pl={12}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    bg="gray.50"
                    border="1px"
                    borderColor="gray.200"
                    _hover={{ borderColor: "teal.400" }}
                    _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    fontSize="lg"
                  />
                  <Box position="absolute" left={4} top={4} color="gray.400">
                    <LockIcon w={5} h={5} />
                  </Box>
                  <InputRightElement width="4.5rem" top="4px">
                    <Button
                      variant="ghost"
                      size="lg"
                      onClick={() => setShowPassword(!showPassword)}
                      _hover={{ color: "teal.500" }}
                    >
                      {showPassword ? <ViewIcon w={5} h={5} /> : <ViewOffIcon w={5} h={5} />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                type="submit"
                isDisabled={submitted}
                size="lg"
                fontSize="lg"
                height="60px"
                bgGradient="linear(to-r, teal.500, blue.500)"
                color="white"
                _hover={{
                  bgGradient: "linear(to-r, teal.600, blue.600)",
                  transform: "translateY(-2px)",
                  boxShadow: "lg",
                }}
                _active={{
                  transform: "translateY(0)",
                }}
              >
                {submitted ? (
                  <LoadingIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  "Sign In"
                )}
              </Button>
            </Stack>
          </form>

          <Link 
            to="/recovery"
            style={{ textAlign: "center" }}
          >
            <Text
              color="teal.500"
              fontSize="md"
              fontWeight="medium"
              _hover={{ textDecoration: "underline" }}
            >
              Forgot your password?
            </Text>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}