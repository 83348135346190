import {
    Box,
    Flex,
    CircularProgress,
    useDisclosure,
    Button,
    Stack,
    Heading,
    Text,
    Container,
    HStack,
    Image,
    Icon,
    Card,
    CardBody,
} from "@chakra-ui/react";
import { format, set } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { MdAdd } from "react-icons/md";
import { GET_CLASS_DETAILS_FOR_STUDENT, GET_CLASS_FOR_MANAGER } from "../../api/api";
import { useNavigate, Link } from "react-router-dom";
import pattern from 'patternomaly';
import { ReactComponent as LoadingIcon } from "../../icons/loading-icon-gray.svg";
import { AddNewClass } from "../Class/Class";

function generatePatternImage(canvasRef, amount, setPatternImage){
    if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        const colors = generateRandomColor(amount);
        const patterns = pattern.generate(colors);
        const images = [];

        for (let i = 0; i < amount; i++) {
            const patternData = patterns[i]; 
            ctx.fillStyle = patternData;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            images.push(canvas.toDataURL());
        }
        console.log(images);
        return setPatternImage(images);
    }
};

const generateRandomColor = (amount) => {
    const colorArray = [];
    for(var i = 0; i < amount; i++){
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        colorArray.push(`#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`);
    }
    return colorArray;
};

export function MyLearning(){
    const [data, setData] = useState({});
    const [loadForm, setloadForm] = useState(false);
    const token = localStorage.getItem("token");
    const {isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd} = useDisclosure();
    const {initialRef: initialRefAdd, finalRef: finalRefAdd} = useRef();
    const [patternImage, setPatternImage] = useState([]);
    const canvasRef = useRef(null);
    useEffect(() => {
        if(!loadForm){
            const fetchData = async () => {
                if(localStorage.role === "Student"){
                    const {isSuccess, res} = await GET_CLASS_DETAILS_FOR_STUDENT(token);
                    if (isSuccess) {
                        const response = await res.json();
                        setData(response.data);
                        setloadForm(true);
                    }
                } else {
                    const {isSuccess, res} = await GET_CLASS_FOR_MANAGER(token);
                    if (isSuccess) {
                        const response = await res.json();
                        setData(response.data);
                        setloadForm(true);
                    } else {
                        if(res.status === 404){
                            setData([]);
                            setloadForm(true)
                        }
                    }
                }
            };
            fetchData();
            if(!loadForm){
                // Check if patternImage is already populated
                if (patternImage.length === 0) {
                    let numPatterns = 1; // Default number of patterns
                    if (localStorage.role === "Student") {
                        numPatterns = 1;
                    } 
                    // else if (data.classInformationOfOthers != null) {
                    //     numPatterns = data.classInformationOfOthers.length;
                    // }
                    generatePatternImage(canvasRef, numPatterns, setPatternImage);
                }
            }
        }
    }, [loadForm]);

    return (
        <Container maxW="container.xl" marginLeft={"-1vw"}>
            <Box
                display={{ base: "fixed", md: "block" }}
                marginTop={"15vh"}
                justifyContent= "flex" 
                alignItems="left" 
            >
                <canvas ref={canvasRef} style={{ display: 'none' }} />
                <Flex flexWrap="wrap" justifyContent="space-between" display="flex">
                    {localStorage.role === "Student" ? (
                        <Heading>My learning</Heading>
                    ) : (
                        <Heading>My classes</Heading>
                    )}
                    <Flex>
                        {localStorage.role === "Admin" ? (
                            <div>
                                <AddNewClass
                                isOpenAdd={isOpenAdd}
                                onOpenAdd={onOpenAdd}
                                onCloseAdd={onCloseAdd}
                                initialRefAdd={initialRefAdd}
                                finalRefAdd={finalRefAdd}
                                setloadForm={setloadForm}
                                />
                                <Button
                                onClick={onOpenAdd}
                                size="md"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                marginRight="10vh"
                                >
                                <Icon fontSize="20px" as={MdAdd} /> Add new class
                                </Button>
                            </div>
                        ) : null}
                        {/* {data.role === "SUAdmin" ? (
                            <div>
                                <Button onClick={onOpenEdit} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="1vh">
                                    <Icon fontSize="20px" as={FaRegEdit} />
                                </Button>
                            </div>
                        ) : null}
                        <Button onClick={onOpenChangePassword} size="md" display="flex" justifyContent="center" alignItems="center" marginRight="20vh">
                            Change password
                        </Button> */}
                    </Flex>
                </Flex>
                {loadForm ? (
                    <div>
                        {localStorage.role === "Student" ? (
                            <Box marginTop={"20px"}>
                                <HStack spacing='10px'>
                                    <Link to={`/class/id/${data.id}`}>
                                        <Card 
                                            maxW='sm'
                                            bg={"rgb(240, 240, 240)"}
                                            transition="transform 0.3s ease"
                                            _hover={{ transform: "scale(1.02)" }}
                                        >
                                            <CardBody>
                                                <Image 
                                                    src={patternImage}
                                                    borderRadius='lg'
                                                />
                                                <Stack mt='6' spacing='3'>
                                                    <Heading size='md'>{data.name}
                                                    </Heading>
                                                    <Text>
                                                        {data.programDetails.name} <span>•</span> {data.location}
                                                    </Text>
                                                    <Text>
                                                        {format(new Date(data.startAt), "dd/MM/yyyy")} - {format(new Date(data.endAt), "dd/MM/yyyy")}
                                                    </Text>
                                                    <Text fontSize={"12px"}>Managed by <b>{data.createdBy.name}</b></Text>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </HStack>
                            </Box>
                        ) : data.length > 0 ? (
                            <Box marginTop={"20px"} maxW="1000px"> {/* Thay đổi giá trị của maxW cho phù hợp */}
                                <Box overflowX="auto" overflowY="hidden" mt="10px" mb="10px" width="80vw">
                                    <HStack spacing='30px' maxH="100%">
                                        {data.map((item, index) => (
                                            <Box key={index} minW='320px'>
                                                <Link to={`/class/id/${item?.classId}`}>
                                                    <Card 
                                                        maxW='sm'
                                                        bg={"rgb(240, 240, 240)"}
                                                        transition="transform 0.3s ease"
                                                        _hover={{ transform: "scale(1.02)" }}
                                                    >
                                                        <CardBody>
                                                            <Image 
                                                                src={patternImage[index % patternImage.length]}
                                                                borderRadius='lg'
                                                            />
                                                            <Stack mt='6' spacing='3'>
                                                                <Heading size='md'>{item?.className}</Heading>
                                                                <Text>
                                                                    {item?.program} <span>•</span> {item?.location}
                                                                </Text>
                                                                <Text>
                                                                    {format(new Date(item?.startAt), "dd/MM/yyyy")} - {format(new Date(item?.endAt), "dd/MM/yyyy")}
                                                                </Text>
                                                                {item?.trainerId != null ? (
                                                                    <Text fontSize={"12px"}>Managed by <b>{item?.admin}</b> <span>•</span> Trained by <b>{item?.trainer}</b></Text>
                                                                ) : (
                                                                    <Text fontSize={"12px"}>Managed by <b>{item?.admin}</b></Text>
                                                                )}
                                                            </Stack>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </Box>
                                        ))}
                                    </HStack>
                                </Box>
                            </Box>
                        ) : (
                            <Flex justifyContent="center" alignItems="center" width="100%" height="100%" marginTop={"5vh"}>
                                <Text
                                    fontSize={"20px"}
                                >
                                    <i>No class information</i>
                                </Text>
                            </Flex>
                        )}
                    </div>
                ) : (
                    <Flex
                      flexWrap="wrap"
                      justifyContent="space-between"
                      display="flex"
                      marginTop={"3vh"}
                    >
                      <Icon as={LoadingIcon} w={50} h={50} />
                    </Flex>
                )}
            </Box>
        </Container>
    );
}