import React from 'react';
import QRCode from 'qrcode'; // Import thư viện tạo mã QR

class QRCodeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qrDataURL: '' // Khởi tạo state để lưu trữ dữ liệu URL của mã QR
    };
  }

  componentDidMount() {
    // Tạo mã QR khi component được render
    this.generateQRCode();
  }

  generateQRCode() {
    const { qrText } = this.props; // Nhận văn bản để tạo mã QR từ props
    // Sử dụng thư viện tạo mã QR để tạo mã QR từ văn bản
    QRCode.toDataURL(qrText, (err, url) => {
      if (err) {
        console.error(err);
      } else {
        // Cập nhật state với URL của mã QR đã tạo
        this.setState({ qrDataURL: url });
      }
    });
  }

  render() {
    const { qrDataURL } = this.state;
    return (
      <div>
        {qrDataURL && <img src={qrDataURL} alt="QR Code" />}
      </div>
    );
  }
}

export default QRCodeComponent;