export const API_URL = "http://160.30.44.65:9090/APIGateway/";
export function LOGIN(body){
    return fetch(API_URL + "auth/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function SEND_OTP(body){
    return fetch(API_URL + "otp-management/send", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function VERIFY_OTP(body){
    return fetch(API_URL + "otp-management/verify", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function RESET_PASSWORD(body){
    return fetch(API_URL + "auth/reset-password", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function GET_USER_INFO(token){
    return fetch(API_URL + "user-management/users/me", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.json());
}

export function CHANGE_PASSWORD(body, token){
    return fetch(API_URL + "auth/change-password", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export async function GET_CLASSES(token, content){
    const res = await fetch(API_URL + `class-management/classes?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ALL_STUDENTS(token, content){
    const res = await fetch(API_URL + `student-management/students?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ACCOUNT_DETAIL(token, id){
    const res = await fetch(API_URL + `user-management/users/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export function CREATE_ACCOUNT(body, token){
    return fetch(API_URL + "user-management/users", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export async function GET_CLASS_DETAIL(token, id){
    const res = await  fetch(API_URL + `class-management/classes/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export function DELETE_CLASSES(token, id){
    return fetch(API_URL + `class-management/classes/status`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(id),
    }).then(res => res.json());
}

export function UPDATE_USER_PROFILE(token, body, id){
    return fetch(API_URL + `student-management/students/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function UPDATE_STATUS_USERS_IN_BATCH(token, body){
    return fetch(API_URL + "user-management/users/status", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function GET_LIST_TRAINING_PROGRAM(token){
    return fetch(API_URL + "training-program-management/training-programs", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.json());
}

export function GET_LIST_DROP_DOWN_TRAINING_PROGRAM(token){
    return fetch(API_URL + "training-program-management/training-programs/preview", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.json());
}

export function CREATE_CLASS(token, body){
    return fetch(API_URL + "class-management/classes", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function DELETE_TRAINING_PROGRAM(token, body){
    return fetch(API_URL + `training-program-management/training-programs/status`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function GET_LIST_USER(token, content){
    return fetch(API_URL + `user-management/users?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.json());
}

export function GET_UNASSIGN_STUDENTS(token, content){
    return fetch(API_URL + `student-management/students/unassigned?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.json());
}

export function ADD_STUDENTS_TO_CLASS(token, body, id){
    return fetch(API_URL + `class-management/classes/${id}/students`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function EXPORT_STUDENT_TO_EXCEL(token){
    return fetch(API_URL + `student-management/students/export`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    }).then(res => res.blob());
}

export async function IMPORT_STUDENT_TO_SYSTEM(token, formData){
    const res = await fetch(API_URL + `student-management/students`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    });
    return { isSuccess: res.ok, res };
}

export function UPDATE_CLASS(token, body, id){
    return fetch(API_URL + `class-management/classes/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export function CREATE_TRAINING_PROGRAM(token, body){
    return fetch(API_URL + "training-program-management/training-programs", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export async function GET_TRAINING_PROGRAM_DETAIL(token, id){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_MODULE_BY_TRAINING_PROGRAM(token, id, content){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}/modules?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_OTHER_MODULE(token, id, content){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}/other-modules?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function CLONE_MODULE_TO_TRAINING_PROGRAM(token, id, body){
    const res = await fetch(API_URL + `module-management/modules/${id}/clone`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function CREATE_MODULE_AND_ADD_INTO_TRAINING_PROGRAM(token, id, body){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}/modules`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function UPDATE_TRAINING_PROGRAM(token, id, body){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ASSIGNMENT_DETAIL(token, id){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function DELETE_MODULE_PROGRAM(token, id, body){
    const res = await fetch(API_URL + `training-program-management/training-programs/${id}/modules`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function DELETE_STUDENTS_FROM_CLASS(token, id, body){
    const res = await fetch(API_URL + `class-management/classes/${id}/students`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_MODULE(token, content){
    const res = await fetch(API_URL + `module-management/modules?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_MODULE_LECTURE_DETAIL(token, id){
    const res = await fetch(API_URL + `class-management/classes/${id}/modules/lectures`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function CREATE_ATTENDANCE(token, id, body){
    const res = await fetch(API_URL + `attendance-management/attendances/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ATTENDANCE_DETAIL(token, id){
    const res = await fetch(API_URL + `attendance-management/attendances/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ATTENDANCE_DETAIL_STUDENT(token, id){
    const res = await fetch(API_URL + `attendance-management/attendances/${id}/student`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function CHECK_ATTENDANCE(token, id, body){
    const res = await fetch(API_URL + `attendance-management/attendances/${id}/attendance-detail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_ATTENDANCE(token, id, content){
    const res = await fetch(API_URL + `attendance-management/attendances/classes/${id}?${content}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ATTENDANCE(token, id){
    const res = await fetch(API_URL + `attendance-management/attendances/${id}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_MODULE_DETAILS(token, id){
    const res = await fetch(API_URL + `module-management/modules/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function EDIT_MODULE(token, id, body){
    const res = await fetch(API_URL + `module-management/modules/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export function DELETE_MODULE(token, body){
    return fetch(API_URL + `module-management/modules/status`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    }).then(res => res.json());
}

export async function ADD_NEW_MODULE(token, id, body){
    const res = await fetch(API_URL + `module-management/modules`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_CLASS_DETAILS_FOR_STUDENT(token){
    const res = await fetch(API_URL + `class-management/classes/me`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_ASSIGNMENT(token, id){
    const res = await fetch(API_URL + `assignment-management/assignments/classes/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function SUBMIT_SUBMISSION(token, id, formData){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/submission`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function UPDATE_SUBMISSION(token, id, formData){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/submission`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function GET_SUBMISSION(token, id){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/submission`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_MODULE_LECTURE_FOR_STUDENT(token){
    const res = await fetch(API_URL + `class-management/classes/modules/lectures`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function ADD_LECTURE(token, formData){
    const res = await fetch(API_URL + `lecture-management/lectures`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function ADD_ASSIGNMENT(token, formData){
    const res = await fetch(API_URL + `assignment-management/assignments`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })  
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_SUBMISSION(token, id){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/submission/list`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_CLASS_FOR_MANAGER(token){
    const res = await fetch(API_URL + `class-management/classes/managed`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_CLASS_NO_TRAINER(token){
    const res = await fetch(API_URL + `class-management/classes/none-trainer`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_TRAINER(token){
    const res = await fetch(API_URL + `user-management/users/trainers`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function ASSIGN_TRAINER_TO_CLASS(token, id, body){
    const res = await fetch(API_URL + `class-management/classes/${id}/trainers`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GRADE_SUBMISSION(token, id, body){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/score`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_SCORE_FILE_TEMPLATE(token, id){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/score-template`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function IMPORT_SCORE(token, id, formData){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}/score-import`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_SCORE_OF_STUDENT(token, id){
    const res = await fetch(API_URL + `student-management/students/${id}/scores`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function UPDATE_ASSIGNMENT(token, id, formData){
    const res = await fetch(API_URL + `assignment-management/assignments/${id}`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function UPDATE_LECTURE(token, id, formData){
    const res = await fetch(API_URL + `lecture-management/lectures/${id}`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function DELETE_LECTURE(token, body){
    const res = await fetch(API_URL + `lecture-management/lectures`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}

export async function GET_NOTIFICATION(token, content){
    const res = await fetch(API_URL + `notification-management/notifications?${content}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function CREATE_NOTIFICATION(token, formData){
    const res = await fetch(API_URL + `notification-management/notifications`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function EDIT_NOTIFICATION(token, id, formData){
    const res = await fetch(API_URL + `notification-management/notifications/${id}`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function DELETE_NOTIFICATION(token, id){
    const res = await fetch(API_URL + `notification-management/notifications/${id}`, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_LIST_SCORE_OF_CLASS(token, id){
    const res = await fetch(API_URL + `class-management/classes/${id}/modules/average`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ALL_SENT_TICKET(token, content){
    const res = await fetch(API_URL + `email-management/email-requests/sent?${content}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ALL_RECIPIENTS_TICKET(token){
    const res = await fetch(API_URL + `email-management/email-requests/students/recipients`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function SEND_NEW_TICKET(token, recipientEmail, formData){
    const res = await fetch(API_URL + `email-management/email-requests?recipientEmail=${recipientEmail}`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token
        },
        body: formData
    })
    return { isSuccess: res.ok, res };
}

export async function GET_TICKET_DETAIL(token, id){
    const res = await fetch(API_URL + `email-management/email-requests/${id}/conversation`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function GET_ALL_RECEIVED_TICKET(token, content){
    const res = await fetch(API_URL + `email-management/email-requests/received?${content}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token
        }
    })
    return { isSuccess: res.ok, res };
}

export async function REPLY_TICKET(token, id, body){
    const res = await fetch(API_URL + `email-management/email-requests/${id}/replies`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
    })
    return { isSuccess: res.ok, res };
}