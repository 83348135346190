import React from "react";
import {
  IconButton,
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Avatar,
  Stack,
  Badge
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function SideBarComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg={useColorModeValue("white.100", "white.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {/* Content */}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      position="absolute"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Stack
        marginTop="40%"
        align="center"
        justify="center"
        bg="facebookBlue" // You can define a custom color for the header
        color="white"
        p="4"
      >
        <Avatar size={"lg"} src={localStorage.getItem("image")} />
        <Text fontSize="lg" fontWeight="bold" color="#000" mt="2">
          {localStorage.getItem("fullname")}
        </Text>
        <Badge variant="solid" colorScheme={localStorage.getItem("role") === "Student" ? "green" : localStorage.getItem("role") === "Admin" ? "orange" : localStorage.getItem("role") === "Trainer" ? "yellow" : "blue"}>
          {localStorage.getItem("role")}
        </Badge>
      </Stack>
      {/* LINK SIDEBAR  */}
      <Stack height="100%" mt="4">
        {/* User */}
        {/* {localStorage.getItem("role") === "SUAdmin" && ( */}
        <Link to="/home">
          <NavItem>Home</NavItem>
        </Link>
        {/* )} */}
        {localStorage.getItem("role") === "SUAdmin" ||
        localStorage.getItem("role") === "Admin" ? (
          <Link to="/student">
            <NavItem>Student</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "SUAdmin" ? (
          <Link to="/class">
            <NavItem>Class</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "Trainer" || localStorage.getItem("role") === "Admin" ? (
          <Link to={`/my-class`}>
            <NavItem>My class</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "SUAdmin" ||
        localStorage.getItem("role") === "Admin" ? (
          <Link to={`/training-program`}>
            <NavItem>Training Program</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "SUAdmin" ||
        localStorage.getItem("role") === "Admin" ? (
          <Link to={`/module`}>
            <NavItem>Module</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "SUAdmin" ||
        localStorage.getItem("role") === "Admin" ? (
          <Link to={`/manager`}>
            <NavItem>Manager</NavItem>
          </Link>
        ) : null}
        {localStorage.getItem("role") === "Student" ? (
          <Link to={`/my-learning`}>
            <NavItem>My learning</NavItem>
          </Link>
        ) : null}
        {/* Staff */}
        <Link to="/ticket">
          <NavItem>Ticket</NavItem>
        </Link>
      </Stack>
    </Box>
  );
};

const NavItem = ({ icon, children, ...rest }) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "rgb(99, 122, 138)",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
};
